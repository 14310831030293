import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApiData, putApiData } from "./../../helpers/axiosHelper";
import { Link, useNavigate } from "react-router-dom";

const ContactsProfile = (props) => {
  //meta title
  document.title = "Profile | Crescent Exchange";
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const profilevalidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: profileData?.firstName || "",
      middleName: profileData?.middleName || "",
      lastName: profileData?.lastName || "",
      email: profileData?.email || "",
      mobile: profileData?.mobile || "",
      companyName: profileData?.companyName || "",
      branchName: profileData?.branchName || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name cannot be empty"),
      lastName: Yup.string().required("Last name cannot be empty"),
      middleNameName: Yup.string(),
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Please enter a Valid Email"),
      mobile: Yup.string()
        .required("Please Enter Your mobile Number")
        .matches(/^\+?\d{8,14}$/, "mobile Number must be 8-14 digits"),
    }),
    onSubmit: async (values) => {
      if (profileData.uid > 0) {
        const combinedValues = { ...values, uid: profileData.uid };
        const hasChanges = Object.keys(values).some(
          (key) => values[key] !== profilevalidation.initialValues[key]
        );
        if (hasChanges) {
          const updatedResponse = await putApiData(
            "api/AgentProfile/UpdateLoginAgent",
            combinedValues
          );
          if (updatedResponse.success === true) {
            toast.success("Profile Details Updated Successfully", {
              position: "top-right",
              autoClose: 3000,
            });
          }
          fetchData();
        } else {
          toast.error("No changes to update", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      } else {
        toast.error("Error Status. Contact Admin", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
  });

  const fetchData = async () => {
    setLoading(true);
    const profileResponse = await getApiData(
      "api/AgentProfile/GetAgentProfileByLoggedUser"
    );

    const profile = await profileResponse.data;

    setProfileData(profile);
    setLoading(false);

    setProfileData(profile);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <ToastContainer closeButton={false} limit={1} />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Profile</h4>
                <form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    profilevalidation.handleSubmit(e);
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <Label for="firstname">First Name</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      className="form-control"
                      id="firstName"
                      onChange={profilevalidation.handleChange}
                      onBlur={profilevalidation.handleBlur}
                      value={profilevalidation.values.firstName || ""}
                      invalid={
                        profilevalidation.touched.firstName &&
                        profilevalidation.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {profilevalidation.touched.firstName &&
                    profilevalidation.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {profilevalidation.errors.firstName}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label for="middlename">Middle Name</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      className="form-control"
                      id="middleName"
                      onChange={profilevalidation.handleChange}
                      onBlur={profilevalidation.handleBlur}
                      value={profilevalidation.values.middleName || ""}
                      invalid={
                        profilevalidation.touched.middleName &&
                        profilevalidation.errors.middleName
                          ? true
                          : false
                      }
                    />
                    {profilevalidation.touched.middleName &&
                    profilevalidation.errors.middleName ? (
                      <FormFeedback type="invalid">
                        {profilevalidation.errors.middleName}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label for="lastname">Last Name</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      className="form-control"
                      id="lastName"
                      onChange={profilevalidation.handleChange}
                      onBlur={profilevalidation.handleBlur}
                      value={profilevalidation.values.lastName || ""}
                      invalid={
                        profilevalidation.touched.lastName &&
                        profilevalidation.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {profilevalidation.touched.lastName &&
                    profilevalidation.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {profilevalidation.errors.lastName}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="email">Communication Email</Label>
                        <RequiredAsterisk />
                        <Input
                          type="text"
                          className="form-control"
                          id="email"
                          onChange={profilevalidation.handleChange}
                          onBlur={profilevalidation.handleBlur}
                          value={profilevalidation.values.email || ""}
                          invalid={
                            profilevalidation.touched.email &&
                            profilevalidation.errors.email
                              ? true
                              : false
                          }
                        />
                        {profilevalidation.touched.email &&
                        profilevalidation.errors.email ? (
                          <FormFeedback type="invalid">
                            {profilevalidation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="mobile">mobile</Label>
                        <RequiredAsterisk />
                        <Input
                          type="text"
                          className="form-control"
                          id="mobile"
                          onChange={profilevalidation.handleChange}
                          onBlur={profilevalidation.handleBlur}
                          value={profilevalidation.values.mobile || ""}
                          invalid={
                            profilevalidation.touched.mobile &&
                            profilevalidation.errors.mobile
                              ? true
                              : false
                          }
                        />
                        {profilevalidation.touched.mobile &&
                        profilevalidation.errors.mobile ? (
                          <FormFeedback type="invalid">
                            {profilevalidation.errors.mobile}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="email">Company</Label>
                        <RequiredAsterisk />
                        <Input
                          type="text"
                          className="form-control"
                          id="email"
                          disabled={true}
                          onChange={profilevalidation.handleChange}
                          onBlur={profilevalidation.handleBlur}
                          value={profilevalidation.values.companyName || ""}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="mobile">Branch</Label>
                        <RequiredAsterisk />
                        <Input
                          type="text"
                          className="form-control"
                          id="mobile"
                          disabled={true}
                          onChange={profilevalidation.handleChange}
                          onBlur={profilevalidation.handleBlur}
                          value={profilevalidation.values.branchName || ""}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-sm-end">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block me-2"
                      onSubmit={(e) => {
                        e.preventDefault();
                        profilevalidation.handleSubmit(e);
                        return false;
                      }}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-primary "
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/agentdashboard");
                      }}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactsProfile;
