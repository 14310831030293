// Authentication related pages
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";

import Landing from "../pages/Authentication/Landing/index";
import Home from "../pages/Authentication/Home";
import FAQs from "../pages/faqs";
import Transaction from "../pages/Dashboard_Agent/index";
import AgentDashboard from "../pages/Dashboard_Agent/index";
import AgentLogin from "../pages/Authentication/AgentLogin";
import EmailVerification from "../pages/Authentication/EmailVerification";
import ResetSuccess from "../pages/Authentication/ResetSuccess";
import ChangePassword from "../pages/Authentication/ChangePassword";

import Profile from "../pages/Agent_Profile/Profile";
import Branch from "../pages/Branch/Branch";
import ManageTransaction from "../pages/Dashboard_Agent/Transaction/ManageTransaction";
import TransactionHistory from "../pages/Dashboard_Agent/Transaction/TransactionHistory";
import Staff from "../pages/Manage_Staff/Staff";
import ManageAssignment from "../pages/Assignments/ManageAssignments";
import ManageBeneficiary from "../pages/Beneficiary/ManageBeneficiary";
import AddTransaction from "../pages/Dashboard_Agent/Transaction/AddTransaction";

const authProtectedRoutes = [
  { path: "/home", component: <Home /> },
  { path: "/faqs", component: <FAQs /> },

  { path: "/agentdashboard", component: <AgentDashboard /> },
  { path: "/transaction", component: <Transaction /> },

  { path: "/profile", component: <Profile /> },
  { path: "/branch", component: <Branch /> },
  { path: "/Transaction-History", component: <TransactionHistory /> },
  { path: "/manage-branch", component: <Branch /> },
  { path: "/manage-staff", component: <Staff /> },
  { path: "/Manage-Transaction", component: <ManageTransaction /> },
  { path: "/Add-Transaction", component: <AddTransaction /> },
  { path: "/Branch-Doc", url: "/Test" },
  { path: "/Manage-Assignment", component: <ManageAssignment /> },
  { path: "/Manage-Beneficiary", component: <ManageBeneficiary /> },
];

const publicRoutes = [
  {
    path: "/",
    exact: true,
    component: <Landing />,
  },
  { path: "/agentlogin", component: <AgentLogin /> },
  { path: "/register", component: <Register /> },

  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },

  {
    path: "/emailverification/:uid",
    component: <EmailVerification />,
  },

  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/reset-success", component: <ResetSuccess /> },
  { path: "/home", component: <Landing /> },

  { path: "/changepassword", component: <ChangePassword /> },
];

export { publicRoutes, authProtectedRoutes };
