import React from "react";
import { Container } from "reactstrap";

import CardUser from "./card-user";
import LatestTransaction from "../Dashboard_Agent/Transaction/LatestTransaction";
import AddCurrency from "./AddCurrency";

const DashboardSaas = (props) => {
  document.title = "Crescent | Transaction";

  return (
    <React.Fragment>
      <div className="page-content" style={{ marginTop: "60px" }}>
        <Container fluid>
          <CardUser />
          <AddCurrency />
          {/* <LatestTransaction /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardSaas;
