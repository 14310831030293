import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Loader from "../../components/Common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltH, faCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Label, Input } from "reactstrap";
import ReactSelect from "react-select";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { SelectStyleForMenu } from "../../common/data/SelectStyleForMenu";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";

const SellForm = ({
  branchID,
  sellingCurrency,
  rate,
  rateType,
  closeModal,
  sellCharge,
  sellRateHistoryID,
}) => {
  const { branchId } = useLatestTransactions();

  const navigate = useNavigate();

  const fromCurrencySelling = sellingCurrency;
  const [fromCurrencyValueSelling, setFromCurrencyValueSelling] = useState("");
  const [
    fromCurrencyValueSellingWithDecimals,
    setFromCurrencyValueSellingWithDecimals,
  ] = useState(0.0);

  const toCurrencySelling = "SGD";
  const [toCurrencyValueSelling, setToCurrencyValueSelling] = useState("");
  const [
    toCurrencyValueSellingWithDecimals,
    setToCurrencyValueSellingWithDecimals,
  ] = useState(0.0);
  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [currencies, setCurrencies] = useState([]);

  const [optime, setOptime] = useState({});
  const currentDate = new Date().toLocaleString();
  const [startDate, setStartDate] = useState(null);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (toCurrencySelling && rateType)
      getBranchByCurrCode(branchID, toCurrencySelling, rateType);
    fetchCurrencies();
  }, [branchID, toCurrencySelling, rateType]);

  const fetchCurrencies = async () => {
    try {
      const response = await getApiData("api/RateMaster/GetAllCurrencies");
      if (response.success) {
        setCurrencies(response.data);
      } else {
        toast.error("Error fetching currencies", { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error fetching currencies", error);
    }
  };

  const handleSubmitSelling = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (errors.length > 0) setIsSubmitting(false);
    // return;
    else {
      const newErrors = validateForm();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        setIsSubmitting(false);
        return;
      }
    }

    const sellingData = {
      fromCurrType: fromCurrencySelling,
      fromCurrValue: fromCurrencyValueSellingWithDecimals,

      toCurrType: toCurrencySelling, //SGD
      toCurrValue: toCurrencyValueSellingWithDecimals,

      transactionType: 1,
      fromBranchId: selectedBranch?.value,
      pickup: startDate ? startDate : "",
      agentBranchID: branchId,
      exchangeRate: rate,
      fromRateHistoryId: sellRateHistoryID,
    };

    try {
      const response = await postApiData(
        "api/Transaction/InsertTransaction",
        sellingData
      );
      if (response.success === true) {
        toast.success("Transaction completed successfully.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        Swal.fire({
          text: response.message + "! Please contact the admin",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#556ee6",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/agentdashboard");
          } else {
            closeModal();
          }
        });
      }
      closeModal();
    } catch (error) {
      console.error("Error during transaction", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const today = new Date();
    if (!selectedBranch) newErrors.branch = "Branch selection is required.";
    if (!fromCurrencyValueSelling) newErrors.amount = "Amount is required.";
    if (fromCurrencyValueSelling < 1) newErrors.amount = "Minimum amount is 1.";
    if (toCurrencyValueSelling < 0)
      newErrors.toCurrencySelling =
        "Converted amount cannot be negative, please provide a higher value";
    if (fromCurrencyValueSelling < 0)
      newErrors.amount = "Amount cannot be negative.";
    if (startDate && startDate <= today)
      newErrors.date = "Please select a future date.";
    return newErrors;
  };

  const getBranchByCurrCode = async () => {
    try {
      setIsLoading(true);
      const apiEndpoint =
        branchID === 0
          ? `api/BranchMaster/GetBranchWithNoRS`
          : `api/BranchMaster/GetBranch?branchId=${branchID}`;

      const response = await getApiData(apiEndpoint);
      setIsLoading(false);

      let filteredBranches;
      if (branchID === 0) {
        filteredBranches = response
          .filter((branch) => branch.active === 1)
          .map((branch) => ({
            value: branch.branchId,
            label: branch.branchName,
            address: `${branch.street}, ${branch.city}, ${branch.postalcode}`,
          }));
      } else {
        filteredBranches = [
          {
            value: response[0].branchId,
            label: response[0].branchName,
            address: `${response[0].street}, ${response[0].city}, ${response[0].postalCode}`,
            rateHistoryId: response[0].rateHistoryId,
          },
        ];
      }

      setBranchOptions(filteredBranches);
      if (filteredBranches.length === 1) {
        setSelectedBranch(filteredBranches[0]);
        getBranchOpTime(filteredBranches[0].value);
        // setSellRateHistoryID(filteredBranches[0].rateHistoryId);
      }
    } catch (error) {
      console.error("Error fetching branches", error);
    }
  };

  const getBranchOpTime = async (branchId) => {
    try {
      const branchResponse = await getApiData(
        `api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`
      );
      const operationalTime = branchResponse.data.operationalTime[0];
      if (operationalTime) {
        setOptime({
          startTime: convertTo12HourFormat(operationalTime.startTime),
          endTime: convertTo12HourFormat(operationalTime.endTime),
        });
      }
    } catch (error) {
      console.error("Error fetching branch operational time", error);
    }
  };

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":");
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };

  const convertFromCurrency = (e) => {
    const amount = parseFloat(e.target.value);

    if (amount <= 0) {
      setErrors((prev) => ({
        ...prev,
        fromCurrencySelling: "Amount cannot be zero or negative.",
      }));
      setIsSubmitting(true);
    } else {
      setErrors((prev) => ({ ...prev, fromCurrencySelling: "" }));
      setIsSubmitting(false);
    }

    setFromCurrencyValueSelling(amount);
    const agentChargesValue =
      parseFloat(sellCharge) > 0 ? parseFloat(sellCharge) : 0;

    if (amount <= agentChargesValue) {
      setErrors((prev) => ({
        ...prev,
        fromCurrencySelling: `Amount must be greater than agent charges (${agentChargesValue.toFixed(
          2
        )}).`,
      }));
      setIsSubmitting(true);
      setToCurrencyValueSelling("");
      setFromCurrencyValueSelling(amount);
      return;
    } else {
      setErrors((prev) => ({ ...prev, fromCurrencySelling: "" }));
      setIsSubmitting(false);
    }

    // Set the valid amount
    setFromCurrencyValueSelling(amount);
    setFromCurrencyValueSellingWithDecimals(amount);

    if (rate) {
      const convertedToCurrency = amount * rate - agentChargesValue;
      setToCurrencyValueSelling(convertedToCurrency.toFixed(2));
      setToCurrencyValueSellingWithDecimals(convertedToCurrency);
    }
  };

  const convertToCurrency = (e) => {
    const amount = parseFloat(e.target.value);

    if (amount <= 0) {
      setErrors((prev) => ({
        ...prev,
        toCurrencySelling: "Amount cannot be zero or negative.",
      }));
      setIsSubmitting(true);
    } else {
      setErrors((prev) => ({ ...prev, toCurrencySelling: "" }));
      setIsSubmitting(false);
    }

    setToCurrencyValueSelling(amount);
    setToCurrencyValueSellingWithDecimals(amount);

    if (rate) {
      const agentChargesValue =
        parseFloat(sellCharge) > 0 ? parseFloat(sellCharge) : 0;
      const convertedFromCurrency =
        parseFloat(amount + agentChargesValue) / rate;
      setFromCurrencyValueSelling(convertedFromCurrency.toFixed(2));
      setFromCurrencyValueSellingWithDecimals(convertedFromCurrency);
    }
    // if (amount === "") setFromCurrencyValueSelling("");
  };

  const getCurrencyFlag = (currencyCode) => {
    const currency = currencies.find((c) => c.currencyCode === currencyCode);
    return currency ? `data:image/png;base64,${currency.currencyFlag}` : "";
  };

  const handleBranchChangeinGlobalCard = (selectedOption) => {
    setSelectedBranch(selectedOption);
    // setAvailableAmount(selectedOption.availableAmount);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Form onSubmit={handleSubmitSelling}>
          <Row>
            <Col md={6}>
              <Label>You Give</Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ced4da",
                  background: "#F8F8F8",
                  padding: "5px",
                }}
              >
                <img
                  src={getCurrencyFlag(fromCurrencySelling)}
                  alt={`${fromCurrencySelling} flag`}
                  style={{ width: "30px", height: "30px", marginRight: "5px" }}
                />
                <Input
                  value={fromCurrencySelling}
                  disabled
                  style={{
                    border: "none",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Label>You Get</Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ced4da",
                  background: "#F8F8F8",
                  padding: "5px",
                }}
              >
                <img
                  src={getCurrencyFlag(toCurrencySelling)}
                  alt={`${toCurrencySelling} flag`}
                  style={{ width: "40px", height: "40px", marginRight: "5px" }}
                />
                <Input
                  value={toCurrencySelling}
                  disabled
                  style={{
                    border: "none",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                />
              </div>
            </Col>
          </Row>

          <div className="mb-2">
            <Label>Branch</Label>
            {branchID === 0 ? (
              <ReactSelect
                styles={SelectStyleForMenu}
                options={branchOptions}
                value={selectedBranch}
                onChange={(option) => {
                  setSelectedBranch(option);
                  getBranchOpTime(option.value);
                }}
                placeholder="Select Branch"
              />
            ) : (
              branchOptions.map((branch) => (
                <div
                  key={branch.value}
                  onClick={() => setSelectedBranch(branch)}
                  style={{
                    padding: "10px",
                    border:
                      selectedBranch?.value === branch.value
                        ? "1px solid #000"
                        : "1px solid #ced4da",
                    cursor: "pointer",
                    marginBottom: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <strong>{branch.label}</strong>
                    <div style={{ fontSize: "0.9em", color: "#888" }}>
                      {branch.address}
                    </div>
                  </div>
                </div>
              ))
            )}
            {errors.branch && (
              <div className="text-danger">{errors.branch}</div>
            )}
          </div>
          <Row className="mb-3">
            <Col md={12}>
              <Label>
                Pick Currency by{" "}
                {optime && optime.startTime && optime.endTime ? (
                  `${optime.startTime} - ${optime.endTime}`
                ) : (
                  <span style={{ fontSize: "0.8em", color: "#888" }}>
                    No Branch Specific Time available
                  </span>
                )}
              </Label>
              <DatePicker
                selected={startDate}
                onChange={setStartDate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="Time"
                className="form-control"
                placeholderText={currentDate}
              />
              {errors.date && <div className="text-danger">{errors.date}</div>}
            </Col>
          </Row>

          <Row>
            <Col lg={5}>
              <div className="mb-3">
                <Label>{fromCurrencySelling} Amount</Label>
                <Input
                  type="number"
                  // step="0.01"
                  className="form-control"
                  placeholder="Enter Amount"
                  onFocus={(e) => {
                    e.currentTarget.onwheel = (event) => event.preventDefault();
                  }}
                  value={fromCurrencyValueSelling}
                  onChange={convertFromCurrency}
                />
                {errors.fromCurrencySelling && (
                  <div className="text-danger" style={{ fontSize: "80%" }}>
                    {errors.fromCurrencySelling}
                  </div>
                )}
              </div>
            </Col>
            {/* hide scrollbar */}
            <style>{`
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: none;
  }
`}</style>
          </Row>
          {sellCharge !== "" && sellCharge > 0 && (
            <>
              <Row className="mb-3 text-end">
                <Col lg={6} className="text-start">
                  <span>Agent Charges</span>
                </Col>
                <Col lg={6}>
                  <span>
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="me-1"
                      style={{ fontSize: "9px", color: "#d3d3d3" }}
                    />
                    {/* {(sellCharge / rate || 0).toFixed(2)} {fromCurrencySelling} */}
                    {sellCharge > 0 ? `${sellCharge} SGD` : "No Charges"}
                  </span>
                </Col>
              </Row>
              <Row className="mb-3 text-end">
                <Col lg={6} className="text-start">
                  <span>Total Converted Amount</span>
                </Col>
                <Col lg={6}>
                  <span>
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="me-1"
                      style={{ fontSize: "9px", color: "#d3d3d3" }}
                    />
                    {fromCurrencyValueSelling > 0
                      ? (fromCurrencyValueSelling * rate).toFixed(2)
                      : 0}{" "}
                    SGD
                  </span>
                </Col>
              </Row>
            </>
          )}

          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>Exchange Rate</span>
            </Col>
            <Col lg={6}>
              <span>
                {/* <FontAwesomeIcon icon={faXmark} className="me-1" /> */}
                {rate}
              </span>
            </Col>
          </Row>
          {/* <Col
              lg={2}
              className="d-flex justify-content-center align-items-center"
            >
              <FontAwesomeIcon icon={faArrowsAltH} size="2x" />
            </Col> */}
          <Row>
            <Col lg={5}>
              <div className="mb-2">
                <Label>{toCurrencySelling} Amount</Label>
                <Input
                  type="number"
                  className="form-control"
                  inputmode="decimal"
                  placeholder="Enter Amount"
                  onFocus={(e) => {
                    e.currentTarget.onwheel = (event) => event.preventDefault();
                  }}
                  value={toCurrencyValueSelling}
                  onChange={convertToCurrency}
                />
                {errors.toCurrencySelling && (
                  <span className="text-danger" style={{ fontSize: "80%" }}>
                    {errors.toCurrencySelling}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting || branchOptions.length === 0}
            >
              {"Submit"}
            </button>
          </div>
          <ToastContainer limit={1} />
        </Form>
      )}
    </>
  );
};

export default SellForm;
