import PropTypes from "prop-types";
import React,{useState} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    FormFeedback,
    Input,
    Label,
    Form,
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

//Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../../images/profile-img.png";
import logo from "../../../images/crescent_logo.png";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { postApiData } from "../../helpers/axiosHelper";

const ForgetPasswordPage = (props) => {
    //meta title
    document.title = "Crescent Exchange | Forget Password";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: "",

        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your UserName"),

        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            const response = await postApiData(
                "api/ForgotPassword/ResetUserForgotPassword",
                JSON.stringify(values)
            );
            if (response.success === true) {
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        navigate("/reset-success");
                    }
                   
                });
                setIsLoading(false);
            } else {
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        },
    });




    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Reset Password</h5>
                                                <p>Enter your registered email to reset</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <ToastContainer closeButton={false} limit={1} />
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >

                                            <div className="mb-3">
                                                <Label className="form-label">UserName</Label>
                                                <RequiredAsterisk />
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter username"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.username || ""}
                                                    invalid={
                                                        validation.touched.username && validation.errors.username
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.username && validation.errors.username ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.username}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            {/* <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <RequiredAsterisk />
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
*/}
                                            <Row className="mb-3">
                                                <Col className="text-end">
                                                    <button
                                                        className="btn btn-primary w-md "
                                                        type="submit"
                                                    >
                                                        Send
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Go back to{" "}
                                    <Link to="/agentlogin" className="font-weight-medium text-primary">
                                        Login
                                    </Link>{" "}
                                </p>
                                <p>© {new Date().getFullYear()} Crescent Exchange</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

ForgetPasswordPage.propTypes = {
    history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
