import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profile from "../../../images/profile-img.png";
import logo from "../../../images/logo.svg";
import { postApiData } from "../../helpers/axiosHelper";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import { GoogleLogin } from "@react-oauth/google";
import { path } from "../../routes/Path";

const AgentLogin = (props) => {
  document.title = "Crescent Exchange | Agent Login";
  const navigate = useNavigate();
  const { setbranchId } = useLatestTransactions();
  const [submitting, setSubmitting] = useState(false);

  const handleSuccess = (credentialResponse) => {
    const authorizationCode = credentialResponse.credential;
    setSubmitting(true);
    postApiData(
      "api/User/LoginWithGoogle",
      JSON.stringify({ idToken: authorizationCode })
    )
      .then((response) => {
        const { data, success } = response || {};
        if (success) {
          console.log(response);

          if (data.token) {
            const logged_user = {
              uid: data.uid,
              email: data.userName,
              token: data.token,
              userType: data.userType,
              expiration: data.expiration,
            };

            localStorage.setItem("authUser", JSON.stringify(logged_user));
            toast.success("Agent Login Successful", {
              position: "top-right",
              autoClose: 1500,
              onClose: () => {
                navigate(path.agentdashboard);
                validation.resetForm();
                setSubmitting(false);
              },
            });
          }
        } else {
          toast.warning(response?.message || "Login Failed", {
            position: "top-right",
            autoClose: 3000,
            onClose: () => {
              validation.resetForm();
              setSubmitting(false);
            },
          });
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: "top-right",
          autoClose: 3000,
          onClose: () => {
            validation.resetForm();
            setSubmitting(false);
          },
        });
      });
  };

  const handleError = (errorResponse) => {
    toast.error("Google login failed", errorResponse);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: Yup.object({
      // username: Yup.string().required("Please Enter Your username"),
      login: Yup.string()
        .trim()
        .required("Please enter your Mobile Number or Email")
        .test(
          "is-email-or-phone",
          "Invalid Mobile Number or Email",
          function (value) {
            const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
            const isPhone = /^\+?[0-9]{8,14}$/.test(value);
            return isEmail || isPhone;
            // .required("Please enter your mobile number or email address")
            // .test(
            //   "validInput",
            //   "Invalid mobile number or email address",
            //   (value) => {
            //     if (value.length === 8) {
            //       // Validate mobile number (8 digits)
            //       return /^[0-9]+$/.test(value);
            //     } else {
            //       // Validate email address
            //       return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
          }
        ),

      password: Yup.string().required("Please Enter Your Password"),
    }),

    onSubmit: async (values) => {
      setSubmitting(true);
      const response = await postApiData(
        "api/Login/Login",
        JSON.stringify(values)
      );
      if (response?.success === true) {
        if (response.data.userStatus === "1") {
          toast.success(
            "Agent not verified, Please complete the registration process",
            {
              position: "top-right",
              autoClose: 1000,
              onClose: () => {
                navigate(`/emailverification/${response.data.uid}`);
              },
            }
          );
          setSubmitting(false);
        } else {
          const logged_user = {
            agentLogin: true,
            username: response?.data?.login,
            token: response?.data.token,
            userType: response?.data?.userType,
            role: response?.data?.roles,
            branchId: response?.data?.branchId,
            agentName: response?.data?.agentName,
            uid: response?.data?.uid,
          };
          localStorage.setItem("authUser", JSON.stringify(logged_user));
          if (response?.data?.userType === "3") {
            const isAgentSuperAdmin = response?.data?.roles === "4";
            toast.success("Agent Login Successful", {
              position: "top-right",
              autoClose: 1000,
              closeButton: false,
              onClose: () => {
                if (!isAgentSuperAdmin) {
                  setbranchId(response?.data?.branchId);
                }
                navigate("/agentdashboard");
              },
            });
            setSubmitting(false);
          }
        }
      } else {
        toast.error(`${response.message}`, {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
          onClose: () => {
            setSubmitting(false);
          },
        });
      }
    },
  });

  // if (response.success === true) {
  //   if (response.data.userStatus === "1") {
  //     toast.success(
  //       "Agent not verified, Please complete the registration process",
  //       {
  //         position: "top-right",
  //         autoClose: 1000,
  //         onClose: () => {
  //           navigate(`/emailverification/${response.data.uid}`);
  //         },
  //       }
  //     );
  //   } else {
  //     values.userType = response.data.userType;
  //     values.token = response.data.token;
  //     const logged_user = {
  //       // login: true,
  //       username: response.data.userName,
  //       token: response.data.token,
  //       userType: response.data.userType,
  //       role: response.data.roles,
  //       branchId: response.data.branchId,
  //       userStatus: response.data.userStatus,
  //     };
  //     localStorage.setItem("authUser", JSON.stringify(logged_user));

  //     /* const isAgentSuperAdmin = response.data.roles === "4";
  //               const isAgentBranchMaster = response.data.roles === "5";
  //               const isAgentUser = response.data.roles === "6";*/

  //     toast.success("Agent Login Successful", {
  //       position: "top-right",
  //       autoClose: 1000,
  //       onClose: () => {
  //         setbranchId(response.data.branchId);
  //         navigate("/agentdashboard");
  //       },
  //     });
  //   }
  //     } else {
  //       toast.error(`${response.message}`, {
  //         position: "top-right",
  //         autoClose: 3000,
  //       });
  //     }
  //   },
  // });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/home" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Agent !</h5>
                        <p>Sign in to continue to Crescent Exchange.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ToastContainer closeButton={false} limit={1} />

                      <div className="mb-3 mt-3">
                        <Label className="form-label">
                          Email\Mobile Number:
                        </Label>
                        <Input
                          name="login"
                          className="form-control"
                          placeholder="Enter Email\Mobile No:"
                          //type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.login || ""}
                          invalid={
                            validation.touched.login && validation.errors.login
                              ? true
                              : false
                          }
                        />
                        {validation.touched.login && validation.errors.login ? (
                          <FormFeedback type="invalid">
                            {validation.errors.login}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link
                          to="/register"
                          className="text-muted"
                          style={{ fontWeight: "1000" }}
                        >
                          Register
                        </Link>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password? <b>Click Here to reset</b>
                        </Link>
                      </div>
                      <hr />

                      <div className="mt-2 text-center">
                        <h5 className="font-size-14 mb-2">Login with</h5>

                        <Row>
                          <Col className="lg-6 sm-6">
                            <GoogleLogin
                              onSuccess={handleSuccess}
                              onError={handleError}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AgentLogin);

AgentLogin.propTypes = {
  history: PropTypes.object,
};
