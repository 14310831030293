import { useState, useEffect } from "react";
import { getApiData } from "../../helpers/axiosHelper";

export const useAuthUser = () => {
  const [userState, setUserState] = useState({
    authUser: null,
    isAgentSuperAdmin: false,
    isAgentBranchAdmin: false,
    isAgentUser: false,
    userBranchId: null,
  });

  useEffect(() => {
    const fetchUser = async () => {
      const user = await JSON.parse(localStorage.getItem("authUser"));
      setUserState({
        authUser: user,
        isAgentSuperAdmin: user?.role === "4",
        isAgentBranchAdmin: user?.role === "5",
        isAgentUser: user?.role === "6",
        userBranchId: user?.branchId,
      });
    };
    fetchUser();
  }, []);

  return userState;
};

export const authUser = await JSON.parse(localStorage.getItem("authUser"));
export const isAgentSuperAdmin = authUser?.role === "4";
export const isAgentBranchAdmin = authUser?.role === "5";
export const isAgentUser = authUser?.role === "6";
export const userBranchId = authUser?.branchId;

export const useBranch = () => {
  const [branch, setBranch] = useState([]);

  const mapBranchOptions = (branches) => {
    if (!branches) return [];
    return branches.map((branch) => ({
      label: branch.branchName,
      value: branch.branchId,
    }));
  };

  const fetchBranch = async () => {
    try {
      const response = await getApiData("api/BranchMaster/GetAllAgentBranch");
      const activeBranches = response.data.filter(
        (branch) => branch.active === 1
      );
      setBranch(mapBranchOptions(activeBranches));
    } catch (error) {
      console.error(error);
    }
  };

  return { branch, fetchBranch, mapBranchOptions };
};

export const useCurrency = () => {
  const [currency, setCurrency] = useState([]);

  const mapCurrencyOptions = (currency) => {
    if (!currency) return [];
    const startingNumber = 1;
    let currentNumber = startingNumber;
    return currency.map((e) => ({
      label: e.currencyCode,
      value: currentNumber++,
    }));
  };

  const getCurrency = async () => {
    try {
      const response = await getApiData("api/RateMaster/GetAllCurrencies");
      setCurrency(mapCurrencyOptions(response.data));
      return response?.data;
    } catch (error) {
      console.error(error);
    }
  };

  return { currency, getCurrency };
};

export const useTransactionBranch = () => {
  const [tranBranch, setTranBranch] = useState([]);
  const mapBranchOptions = (branches) => {
    if (!branches) return [];
    return branches.map((tranBranch) => ({
      label: tranBranch.branchName,
      value: tranBranch.branchId,
    }));
  };
  const fetchTransactionBranch = async () => {
    try {
      const response = await getApiData("api/BranchMaster/GetAllBranch");
      setTranBranch(mapBranchOptions(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  return { tranBranch, fetchTransactionBranch, mapBranchOptions };
};

export const TransactionType = {
  Buy: 2,
  Sell: 1,
  Transfer: 3,
};
