/* eslint-disable no-unreachable */
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Loader from "../../components/Common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Label, Input } from "reactstrap";
import ReactSelect from "react-select";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import { SelectStyleForMenu } from "../../common/data/SelectStyleForMenu";

const BuyForm = ({
  branchID,
  rateType,
  buyCharge,
  rate,
  buyingCurrency,
  stock,
  closeModal,
}) => {
  console.log(rate);
  console.log("Charges  " + buyCharge);
  console.log("Buy Form Branch ID " + branchID);
  const { branchId } = useLatestTransactions();
  const navigate = useNavigate();

  const fromCurrencyBuying = "SGD";
  const [fromCurrencyValueBuying, setFromCurrencyValueBuying] = useState("");
  const [
    fromCurrencyValueBuyingWithDecimals,
    setFromCurrencyValueBuyingWithDecimals,
  ] = useState("");

  const toCurrencyBuying = buyingCurrency;
  const [toCurrencyValueBuying, setToCurrencyValueBuying] = useState("");
  const [
    toCurrencyValueBuyingWithDecimals,
    setToCurrencyValueBuyingWithDecimals,
  ] = useState("");

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [availableAmount, setAvailableAmount] = useState(0);
  const [buyRateHistoryID, setBuyRateHistoryID] = useState(0);
  const [optime, setOptime] = useState({});

  const currentDate = new Date().toLocaleString();
  const [startDate, setStartDate] = useState(null);

  const [errors, setErrors] = useState({});

  const [currencies, setCurrencies] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (toCurrencyBuying && rateType)
      getBranchByCurrCode(branchID, toCurrencyBuying, rateType);
    fetchCurrencies();
  }, [branchID, toCurrencyBuying, rateType]);

  const fetchCurrencies = async () => {
    try {
      const response = await getApiData("api/RateMaster/GetAllCurrencies");
      if (response.success) {
        setCurrencies(response.data);
      } else {
        toast.error("Error fetching currencies", { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error fetching currencies", error);
    }
  };

  const handleSubmitBuying = async (e) => {
    e.preventDefault();
    if (parseFloat(toCurrencyValueBuying) > availableAmount) {
      {
        toast.error("Insufficient stock", {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
        });
        return;
      }
    } else {
      if (isSubmitting) return;
      setIsSubmitting(true);
      const newErrors = validateForm();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        setIsSubmitting(false);
        return;
      }

      const buyingData = {
        fromCurrType: fromCurrencyBuying, //SGD
        fromCurrValue: fromCurrencyValueBuyingWithDecimals,

        toCurrType: toCurrencyBuying,
        toCurrValue: toCurrencyValueBuyingWithDecimals,

        transactionType: 2,
        fromBranchId: selectedBranch?.value,
        pickup: startDate ? startDate : "",
        agentBranchID: branchId,
        exchangeRate: rate,
        toRateHistoryId: buyRateHistoryID,
      };

      try {
        console.log("Buying data " + buyingData);
        const response = await postApiData(
          "api/Transaction/InsertTransaction",
          buyingData
        );
        if (response.success === true) {
          toast.success("Transaction completed successfully.", {
            position: "top-right",
            autoClose: 2000,
          });
        } else {
          Swal.fire({
            text: response.message + "! Please contact the admin",
            icon: "info",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "Ok",
            confirmButtonColor: "#556ee6",
          }).then(async (result) => {
            if (result.isConfirmed) {
              navigate("/agentdashboard");
            } else {
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error("Error during transaction", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const today = new Date();

    // Branch validation
    if (!selectedBranch) newErrors.branch = "Branch selection is required.";

    // From Currency Amount validation
    if (!fromCurrencyValueBuying) {
      newErrors.fromAmount = "Amount is required.";
    } else if (parseFloat(fromCurrencyValueBuying) <= buyCharge) {
      newErrors.fromAmount =
        "Amount cannot be less than the charges, please enter a higher value.";
    } else if (parseFloat(fromCurrencyValueBuying) < 0) {
      newErrors.fromAmount = "Amount cannot be negative.";
    }

    // To Currency Amount validation
    if (!toCurrencyValueBuying) {
      newErrors.toAmount = "Converted amount is required.";
    } else if (parseFloat(toCurrencyValueBuying) < 0) {
      newErrors.toAmount =
        "Converted amount cannot be negative, please enter a higher value of SGD amount";
    }

    // Date validation
    if (startDate && startDate < today) {
      newErrors.date = "Please select a future date.";
    }

    return newErrors;
  };

  const getBranchByCurrCode = async () => {
    try {
      setIsLoading(true);
      const apiEndpoint =
        branchID === 0
          ? `api/RateMaster/GetBranchForCurrency?currencyCode=${toCurrencyBuying}&rateType=${rateType}`
          : `api/RateMaster/GetBranchStockByCurrency?branchId=${branchID}&currencyCode=${toCurrencyBuying}&rateType=${rateType}`;

      const response = await getApiData(apiEndpoint);

      setIsLoading(false);
      //Handling Global card
      if (branchID === 0) {
        const branchIdCount = response.data.reduce((acc, branch) => {
          acc[branch.branchId] = (acc[branch.branchId] || 0) + 1;
          return acc;
        }, {});
        const filteredBranches = response.data
          .filter(
            (branch) =>
              branch.allBranch !== 0 &&
              branchIdCount[branch.branchId] === 1 &&
              branch.active === true
          )
          .map((branch) => ({
            value: branch.branchId,
            label: branch.branchName,
            allBranch: branch.allBranch,
            address: `${branch.street}, ${branch.city}, ${branch.postalcode}`,
            availableAmount: branch.availableAmount,
            rateHistoryId: branch.rateHistoryId,
          }));

        setBranchOptions(filteredBranches);
        if (filteredBranches.length === 1) {
          setSelectedBranch(filteredBranches[0]);
          getBranchOpTime(filteredBranches[0].value);
          setAvailableAmount(filteredBranches[0].availableAmount);
          setBuyRateHistoryID(filteredBranches[0].rateHistoryId);
        }
      }
      //Handling specific branch card
      else {
        const filteredBranches = response.data
          .filter((branch) => branch.allBranch === 0)
          .map((branch) => ({
            value: branch.branchId,
            label: branch.branchName,
            allBranch: branch.allBranch,
            address: `${branch.street}, ${branch.city}, ${branch.postalcode}`,
            availableAmount: branch.availableAmount,
            rateHistoryId: branch.rateHistoryId,
          }));
        setBranchOptions(filteredBranches);
        if (filteredBranches.length === 1) {
          setSelectedBranch(filteredBranches[0]);
          getBranchOpTime(filteredBranches[0].value);
          setAvailableAmount(filteredBranches[0].availableAmount);
          setBuyRateHistoryID(filteredBranches[0].rateHistoryId);
        }
      }
    } catch (error) {
      console.error("Error fetching branches", error);
    }
  };

  const getBranchOpTime = async (branchId) => {
    try {
      const branchResponse = await getApiData(
        `api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`
      );
      const operationalTime = branchResponse.data.operationalTime[0];
      if (operationalTime) {
        setOptime({
          startTime: convertTo12HourFormat(operationalTime.startTime),
          endTime: convertTo12HourFormat(operationalTime.endTime),
        });
      }
    } catch (error) {
      console.error("Error fetching branch operational time", error);
    }
  };

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":");
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };

  const convertFromCurrency = (e) => {
    const amount = parseFloat(e.target.value);

    if (amount < 0 || amount <= buyCharge) {
      setErrors((prev) => ({
        ...prev,
        fromAmount:
          "Amount cannot be negative or less than or equal to the charges.",
      }));
      setIsSubmitting(true);
    } else {
      setErrors((prev) => ({ ...prev, fromAmount: "" }));
      setIsSubmitting(false);
    }
    setFromCurrencyValueBuying(amount);
    setFromCurrencyValueBuyingWithDecimals(amount);

    if (rate) {
      // Consider agentCharges only if greater than 0
      const agentChargesValue =
        parseFloat(buyCharge) > 0 ? parseFloat(buyCharge) : 0;
      const convertedToCurrency = (amount - agentChargesValue) / rate;
      const formattedToCurrencyAmount = convertedToCurrency.toFixed(2);
      setToCurrencyValueBuyingWithDecimals(convertedToCurrency);
      setToCurrencyValueBuying(formattedToCurrencyAmount);

      if (convertedToCurrency <= 0) {
        setErrors((prev) => ({
          ...prev,
          toAmount: "Amount cannot be negative or less than the charges.",
        }));
        setIsSubmitting(true);
      } else {
        setErrors((prev) => ({ ...prev, toAmount: "" }));
        setIsSubmitting(false);
      }
    }
  };

  const convertToCurrency = (e) => {
    const amount = parseFloat(e.target.value);

    if (amount <= 0) {
      setErrors((prev) => ({
        ...prev,
        toAmount: "Amount cannot be negative.",
      }));
      setIsSubmitting(true);
    } else {
      setErrors((prev) => ({ ...prev, toAmount: "" }));
      setIsSubmitting(false);
    }
    if (parseFloat(buyCharge) > 0) buyCharge = buyCharge;
    else buyCharge = 0;

    setToCurrencyValueBuying(amount);
    setToCurrencyValueBuyingWithDecimals(amount);

    if (rate) {
      const convertedFromCurrency = amount * rate + parseFloat(buyCharge);
      const formattedFromCurrencyAmount = convertedFromCurrency.toFixed(2);
      setFromCurrencyValueBuyingWithDecimals(convertedFromCurrency);
      setFromCurrencyValueBuying(formattedFromCurrencyAmount);
    }
  };

  const getCurrencyFlag = (currencyCode) => {
    const currency = currencies.find((c) => c.currencyCode === currencyCode);
    return currency ? `data:image/png;base64,${currency.currencyFlag}` : "";
  };

  const handleBranchChangeinGlobalCard = (selectedOption) => {
    setSelectedBranch(selectedOption);
    setAvailableAmount(selectedOption.availableAmount);
    getBranchOpTime(selectedOption.value);
    // setToRateHistoryID(response);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Form onSubmit={handleSubmitBuying}>
          <Row>
            <Col md={6}>
              <Label>You Give</Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ced4da",
                  background: "#F8F8F8",
                  padding: "5px",
                }}
              >
                <img
                  src={getCurrencyFlag(fromCurrencyBuying)}
                  alt={`${fromCurrencyBuying} flag`}
                  style={{ width: "30px", height: "30px", marginRight: "5px" }}
                />
                <Input
                  value={fromCurrencyBuying}
                  disabled
                  style={{
                    border: "none",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Label>You Get</Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ced4da",
                  background: "#F8F8F8",
                  padding: "5px",
                }}
              >
                <img
                  src={getCurrencyFlag(toCurrencyBuying)}
                  alt={`${toCurrencyBuying} flag`}
                  style={{ width: "40px", height: "40px", marginRight: "5px" }}
                />
                <Input
                  value={toCurrencyBuying}
                  disabled
                  style={{
                    border: "none",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                />
              </div>
            </Col>
          </Row>

          {branchOptions.length === 0 ? (
            <div className="mt-4 text-center">No stock available</div>
          ) : (
            <>
              <div className="mb-3">
                <Label>Branch</Label>
                {branchID === 0 ? (
                  <div>
                    <ReactSelect
                      styles={SelectStyleForMenu}
                      options={branchOptions}
                      value={selectedBranch}
                      onChange={handleBranchChangeinGlobalCard}
                      placeholder="Select Branch"
                    />

                    <div>
                      {selectedBranch && (
                        <div>
                          <span
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginTop: "1.5px",
                            }}
                          >
                            {" "}
                            Available Stock: {availableAmount}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  branchOptions.map((branch) => (
                    <div
                      key={branch.value}
                      onClick={() => setSelectedBranch(branch)}
                      style={{
                        padding: "10px",
                        border:
                          selectedBranch?.value === branch.value
                            ? "2px solid #ced4da"
                            : "1px solid #ced4da",
                        cursor: "pointer",
                        marginBottom: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <strong>{branch.label}</strong>
                        <div style={{ fontSize: "0.9em", color: "#888" }}>
                          {branch.address}
                        </div>
                      </div>
                      <div style={{ fontSize: "0.9em", color: "red" }}>
                        Max: {branch.availableAmount}
                      </div>
                    </div>
                  ))
                )}
                {errors.branch && (
                  <div className="text-danger">{errors.branch}</div>
                )}
              </div>

              <Row className="mb-3">
                <Col md={12}>
                  <Label>
                    Pick Currency by{" "}
                    {optime && optime.startTime && optime.endTime ? (
                      `${optime.startTime} - ${optime.endTime}`
                    ) : (
                      <span style={{ fontSize: "0.8em", color: "#888" }}>
                        No Branch Specific Time available
                      </span>
                    )}
                  </Label>
                  <DatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="Time"
                    className="form-control"
                    placeholderText={currentDate}
                  />
                  {errors.date && (
                    <div className="text-danger">{errors.date}</div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col lg={5}>
                  <div className="mb-3">
                    <Label>{fromCurrencyBuying} Amount</Label>
                    <Input
                      type="number"
                      // step="0.01"
                      className="form-control"
                      placeholder="Enter Amount"
                      value={fromCurrencyValueBuying}
                      onFocus={(e) => {
                        e.currentTarget.onwheel = (event) =>
                          event.preventDefault();
                      }}
                      onChange={convertFromCurrency}
                    />
                    {errors.fromAmount && (
                      <div
                        className="text-danger"
                        style={{ fontSize: "0.8em" }}
                      >
                        {errors.fromAmount}
                      </div>
                    )}
                  </div>
                </Col>
                {/* hide scrollbar */}
                <style>{`
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: none;
  }
`}</style>
              </Row>

              {buyCharge !== "" && buyCharge > 0 && (
                <>
                  <Row className="mb-3 text-end">
                    <Col lg={6} className="text-start">
                      <span>Agent Charges</span>
                    </Col>
                    <Col lg={6}>
                      <span>
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="me-1"
                          style={{ fontSize: "9px", color: "#d3d3d3" }}
                        />
                        {`${buyCharge} SGD`}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3 text-end">
                    <Col lg={6} className="text-start">
                      <span>Total Convertible Amount</span>
                    </Col>
                    <Col lg={6}>
                      <span>
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="me-1"
                          style={{ fontSize: "9px", color: "#d3d3d3" }}
                        />
                        {(fromCurrencyValueBuying - buyCharge).toFixed(2)} SGD
                      </span>
                    </Col>
                  </Row>
                </>
              )}

              <Row className="mb-3 text-end">
                <Col lg={6} className="text-start">
                  <span>Exchange Rate</span>
                </Col>
                <Col lg={6}>
                  <span>{rate}</span>
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                  <div className="mb-2">
                    <Label>{toCurrencyBuying} Amount</Label>
                    <Input
                      type="number"
                      // step="0.01"
                      className="form-control"
                      placeholder="Enter Amount"
                      onFocus={(e) => {
                        e.currentTarget.onwheel = (event) =>
                          event.preventDefault();
                      }}
                      value={toCurrencyValueBuying}
                      onChange={(event) => convertToCurrency(event)}
                    />
                    {errors.toAmount && (
                      <div
                        className="text-danger"
                        style={{ fontSize: "0.8em" }}
                      >
                        {errors.toAmount}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting || branchOptions.length === 0}
                >
                  {"Submit"}
                </button>
              </div>
            </>
          )}
          <ToastContainer limit={1} />
        </Form>
      )}
    </>
  );
};

export default BuyForm;
