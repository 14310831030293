import Swal from "sweetalert2";
import ReactSelect from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Label, Input } from "reactstrap";
import BeneficiaryModal from "../Beneficiary/BeneficiaryModal";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { SelectStyleForMenu } from "../../common/data/SelectStyleForMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";

import {
  faMinus,
  faEquals,
  faXmark,
  faDivide,
} from "@fortawesome/free-solid-svg-icons";

const TransferForm = ({
  branch,
  transferCurrency,
  baseCurrency,
  globalTransferData,
  closeModal,
}) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [currency, setCurrency] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [modal1, setModal1] = useState(false);
  const toggleAddModal = () => setModal1(!modal1);

  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");

  const [fromCurrencyTransfer, setFromCurrencyTransfer] = useState([]);
  const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);
  const [selectedFromCurrencyTransfer, setSelectedFromCurrencyTransfer] =
    useState([]);
  const { branchId } = useLatestTransactions();

  const toCurrencyTransfer = transferCurrency;
  const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [buyRate, setBuyRate] = useState(null);
  const [sellRate, setSellRate] = useState(null);
  // const [transferRate, setTransferRate] = useState(null);
  const [rateHistoryIDForCase1, setRateHistoryIDForCase1] = useState(0);

  const transferData = globalTransferData?.find((x) => {
    const targetCurrency =
      toCurrencyTransfer !== "SGD"
        ? toCurrencyTransfer
        : selectedFromCurrencyTransfer?.value || fromCurrencyTransfer;

    return x.currencyCode === targetCurrency;

    // map ratehistory of transfer currency
  });

  const getDocument = async (companyBenId) => {
    try {
      const docResponse = await getApiData(
        `api/AgentBeneficiary/GetBeneficiaryDocByCompanyBenId?CompanyBenId=${companyBenId}`
      ).catch(() => {});
      const document = await docResponse;
      const documents = (document && document.data) || [];
      setDocumentData(documents);
    } catch (error) {
      console.error("Error fetching document data:", error);
    }
  };

  const transferRate = transferData ? parseFloat(transferData.rate) : 0;

  const calculateFee = (
    fee,
    fromCurrency,
    toCurrency,
    transferRate,
    buyRate
  ) => {
    if (fromCurrency === "SGD") {
      return fee;
    } else if (toCurrency === "SGD") {
      return fee;
    } else {
      return fee * buyRate;
    }
  };

  let wireFee = parseFloat(transferData ? transferData.wireTransferFee : 0);
  let commissionFee = parseFloat(
    transferData ? transferData.commissionCharges : 0
  );
  let agentCharges = parseFloat(transferData ? transferData.agentCharges : 0);

  const convertedWireFee = calculateFee(
    wireFee,
    selectedFromCurrencyTransfer?.value,
    toCurrencyTransfer,
    transferRate,
    buyRate
  );
  const convertedCommissionFee = calculateFee(
    commissionFee,
    selectedFromCurrencyTransfer?.value,
    toCurrencyTransfer,
    transferRate,
    buyRate
  );
  const convertedAgentCharges = calculateFee(
    agentCharges,
    selectedFromCurrencyTransfer?.value,
    toCurrencyTransfer,
    transferRate,
    buyRate
  );

  const findRateHisId = (transferCurrency, fromCurrencyTransfer) => {
    if (fromCurrencyTransfer === "SGD") {
      const transferDataforcase1 = globalTransferData?.find(
        (data) => data.currencyCode === transferCurrency
      );
      return transferDataforcase1?.rateHistoryId;
    }

    return null;
  };

  console.log({ transferData, transferRate, wireFee, commissionFee });

  const getAvaCurrency = async () => {
    try {
      const avaCurrency = await getApiData(
        `api/RateMaster/GetBranchForCurrencyAll?rateType=3`
      );

      const distinctCurrencies = Array.from(
        new Set(avaCurrency.data.map((currency) => currency.currencyCode))
      ).map((currencyCode) =>
        avaCurrency.data.find(
          (currency) => currency.currencyCode === currencyCode
        )
      );

      const formattedCurrency = distinctCurrencies
        .map((currency) => ({
          label: currency.currencyName,
          value: currency.currencyCode,
          currencyCode: currency.currencyCode,
          rateHistoryID: currency.rateHistoryId,
        }))
        .filter(
          (currency) => currency.label && currency.value !== transferCurrency
        );

      setFromCurrencyTransfer(formattedCurrency);
    } catch (error) {
      console.error("Error fetching currencies", error);
    }
  };

  const fetchTrancfer = async (fromCurr, toCurr) => {
    setIsLoading(true);
    const resp = await getApiData(
      `api/RateMaster/GetTransactionRate?branchId=${branch}&fromCurr=${fromCurr}&toCurr=${toCurr}`
    );
    if (
      resp.success === true &&
      resp.data.buyRate !== null &&
      resp.data.sellRate !== null
    ) {
      setBuyRate(parseFloat(resp.data.buyRate));
      setSellRate(parseFloat(resp.data.sellRate));
    } else {
      setBuyRate(null);
      setSellRate(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      selectedFromCurrencyTransfer?.value !== "SGD" &&
      toCurrencyTransfer !== "SGD" &&
      selectedFromCurrencyTransfer?.value !== undefined &&
      toCurrencyTransfer !== undefined
    ) {
      fetchTrancfer(selectedFromCurrencyTransfer?.value, toCurrencyTransfer);
    } else {
      console.log("not");
    }
    setFromCurrencyValueTransfer(0);
    setToCurrencyValueTransfer(0);
  }, [selectedFromCurrencyTransfer, toCurrencyTransfer]);

  const fetchCurrencies = async () => {
    try {
      const response = await getApiData("api/RateMaster/GetAllCurrencies");
      if (response.success) {
        const mappedResponse = response.data.map((item, index) => ({
          index: index + 1,

          currencyId: item.currencyId,
          currencyName: item.currencyName,
          currencyCode: item.currencyCode,
        }));
        setCurrency(mappedResponse);
        setCurrencies(response.data);
      } else {
        toast.error("Failed to fetch currencies", { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error fetching currencies", error);
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const getAvaBeneficiary = async () => {
    try {
      setIsLoading(true);
      const response = await getApiData(
        `api/AgentBeneficiary/GetAllByAgentBeneficiary`
      );
      setIsLoading(false);

      const filteredResponse = response.data.filter(
        (item) => item.status === 2 && item.active === 1
      );
      const mappedResponse = filteredResponse.map((item) => ({
        label: item.beneficiaryName,
        value: item.companyBenId,
        currencyName: item.currencyName.split("(")[1]?.replace(")", "") || "",
      }));
      const transCurrency = mappedResponse.filter(
        (cc) => cc.currencyName === transferCurrency
      );
      setBeneficiary(transCurrency);
    } catch (error) {
      console.log("Error fetching beneficiaries", error);
    }
  };

  useEffect(() => {
    getAvaCurrency();
    getAvaBeneficiary();
  }, []);

  useEffect(() => {
    if (baseCurrency && fromCurrencyTransfer.length > 0) {
      const initialCurrency = fromCurrencyTransfer.find(
        (currency) => currency.value === baseCurrency
      );
      if (initialCurrency) {
        setSelectedFromCurrencyTransfer(initialCurrency);
      }
    }
  }, [baseCurrency, fromCurrencyTransfer]);

  const convertFromCurrency = (e) => {
    const inputValue = e.target.value;
    if (inputValue < 0) {
      setErrors((prev) => ({
        ...prev,
        fromCurrency: "Amount cannot be negative.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, toAmount: "" }));
    }
    if (inputValue === "") {
      setFromCurrencyValueTransfer("");
      setToCurrencyValueTransfer("");
      setErrors({
        fromCurrency: "",
        toCurrency: "",
      });
      return;
    }

    // if (inputValue.length > 10) return;

    const amount = parseFloat(inputValue) || 0;
    const totalFee =
      convertedWireFee + convertedCommissionFee + convertedAgentCharges || 0;

    setFromCurrencyValueTransfer(inputValue);

    if (amount <= 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fromCurrency: `${selectedFromCurrencyTransfer?.value} cannot be 0 or less than 0.`,
        toCurrency: "",
      }));
      setToCurrencyValueTransfer(0);
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fromCurrency: "",
      }));
    }

    if (selectedFromCurrencyTransfer?.value === "SGD") {
      if (transferRate > 0) {
        const convertedValue = (amount - totalFee) / transferRate;
        console.log({ amount, totalFee, transferRate });
        setToCurrencyValueTransfer(convertedValue);
      }
    } else if (toCurrencyTransfer === "SGD") {
      if (transferRate > 0) {
        const convertedValue = (amount - totalFee) * transferRate;
        console.log({ amount, totalFee, transferRate });
        setToCurrencyValueTransfer(convertedValue);
      }
    } else {
      if (buyRate !== null && sellRate !== null) {
        const subAmount = amount - totalFee;
        const convertedSGD = subAmount * buyRate;
        const convertedToCurrency = convertedSGD / sellRate;
        console.log({
          amount,
          totalFee,
          subAmount,
          buyRate,
          sellRate,
          convertedSGD,
          convertedToCurrency,
        });
        setToCurrencyValueTransfer(convertedToCurrency);
      } else {
        console.log("error 0 rate");
      }
    }
  };

  const convertToCurrency = (e) => {
    const inputValue = e.target.value;
    if (inputValue < 0) {
      setErrors((prev) => ({
        ...prev,
        toCurrency: "Amount cannot be negative.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, toAmount: "" }));
    }
    if (inputValue === "") {
      setFromCurrencyValueTransfer("");
      setToCurrencyValueTransfer("");
      setErrors({
        fromCurrency: "",
        toCurrency: "",
      });
      return;
    }

    if (inputValue.length > 10) return;

    const amount = parseFloat(inputValue) || 0;
    const totalFee =
      convertedWireFee + convertedCommissionFee + convertedAgentCharges || 0;

    setToCurrencyValueTransfer(inputValue);

    if (amount <= 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fromCurrency: "",
        toCurrency: `${toCurrencyTransfer} cannot be 0 or less than 0.`,
      }));
      setFromCurrencyValueTransfer(0);
      return;
    }

    if (selectedFromCurrencyTransfer?.value === "SGD") {
      if (transferRate > 0) {
        const convertedValue = amount * transferRate + totalFee;
        setFromCurrencyValueTransfer(convertedValue.toFixed(2));
      }
    } else if (toCurrencyTransfer === "SGD") {
      if (transferRate > 0) {
        const convertedValue = amount / transferRate + totalFee;
        setFromCurrencyValueTransfer(convertedValue.toFixed(2));
      }
    } else {
      if (buyRate !== null && sellRate !== null) {
        const convertedSGD = amount * sellRate;
        const convertedFromCurrency = convertedSGD / buyRate + totalFee;
        setFromCurrencyValueTransfer(convertedFromCurrency.toFixed(2));
      } else {
        console.log("error 0 rate");
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const today = new Date();

    // Branch validation
    // if (!selectedBranch) newErrors.branch = "Branch selection is required.";

    // From Currency Amount validation
    if (!fromCurrencyValueTransfer) {
      newErrors.fromCurrency = "Amount is required.";
    } else if (parseFloat(fromCurrencyValueTransfer) < 1) {
      newErrors.fromCurrency = "Minimum amount is 1.";
    } else if (parseFloat(fromCurrencyValueTransfer) < 0) {
      newErrors.fromCurrency = "Amount cannot be negative.";
    }

    // To Currency Amount validation
    if (!toCurrencyValueTransfer) {
      newErrors.toCurrency = "Converted amount is required.";
    } else if (parseFloat(toCurrencyValueTransfer) < 0) {
      newErrors.toCurrency =
        "Converted amount cannot be negative, please enter a higher value of SGD amount";
    }

    return newErrors;
  };
  const getCurrencyFlag = (currencyCode) => {
    if (!currencyCode) return "";
    const currency = currencies.find(
      (c) => c.currencyCode && c.currencyCode.trim() === currencyCode.trim()
    );
    return currency ? `data:image/png;base64,${currency.currencyFlag}` : "";
  };

  const handleSubmitTransfer = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitting(false);
      return;
    }

    const TransferData = {
      toCompanyBenId: selectedBeneficiary?.value,

      fromCurrType: selectedFromCurrencyTransfer?.value,
      fromCurrValue: fromCurrencyValueTransfer,

      toCurrType: toCurrencyTransfer,
      toCurrValue: toCurrencyValueTransfer,

      exchangeRate: transferRate,

      fromBranchId: branch,
      transactionType: 3,
      agentBranchID: branchId,
      toRateHistoryId: findRateHisId(
        toCurrencyTransfer,
        selectedFromCurrencyTransfer?.value
      ),
    };

    try {
      // console.log(TransferData);
      // return;
      const response = await postApiData(
        "api/Transaction/InsertTransaction",
        TransferData
      );
      if (response.success === true) {
        toast.success("Transaction request has been completed ", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        Swal.fire({
          text: response.message + "! Click Ok to go to profile ",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#556ee6",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/profile");
          } else {
            closeModal();
          }
        });
      }
      closeModal();
    } catch (error) {
      console.error("Error during transaction", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleYouGiveCurrencyChange = async (selectedOption) => {
    try {
      setIsLoading(true);
      setSelectedFromCurrencyTransfer(selectedOption);
      const response = await getApiData(
        `api/RateMaster/GetTransactionRate?fromCurr=${selectedOption}`
      );

      setIsLoading(false);
    } catch (error) {
      console.error("Error during transaction", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const filteredBeneficiaries = beneficiary.filter((b) =>
    b.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (typedBeneficiaryName) {
    filteredBeneficiaries.push({
      label: `Create "${typedBeneficiaryName}" Beneficiary`,
      value: "create-new",
    });
  }

  if (filteredBeneficiaries.length === 0) {
    filteredBeneficiaries.push({
      label: `Create New Beneficiary for ${toCurrencyTransfer} currency`,
      value: "create-new",
    });
  }

  const openADDBeneficiary = () => {
    toggleAddModal();
    setSearchTerm("");
  };

  return (
    <>
      <BeneficiaryModal
        editMode={3}
        isOpen={modal1}
        currencies={currency}
        toggle={toggleAddModal}
        createBenFor={toCurrencyTransfer}
        getAvaBeneficiary={getAvaBeneficiary}
        getDocument={getDocument}
        documentData={documentData}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Form onSubmit={handleSubmitTransfer}>
          <ToastContainer />
          <Row>
            <Col md={12}>
              <div className="mb-2">
                <Label>Select Beneficiary</Label>
                <ReactSelect
                  styles={SelectStyleForMenu}
                  style={{ border: "1px solid #000" }}
                  value={selectedBeneficiary}
                  onInputChange={(inputValue) => {
                    setSearchTerm(inputValue);
                    setTypedBeneficiaryName(inputValue);
                  }}
                  onChange={(selectedOption) => {
                    if (selectedOption?.value === "create-new") {
                      openADDBeneficiary();
                    } else {
                      setSelectedBeneficiary(selectedOption);
                    }
                  }}
                  options={filteredBeneficiaries}
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Label>You Give</Label>
              <ReactSelect
                styles={SelectStyleForMenu}
                value={selectedFromCurrencyTransfer}
                formatOptionLabel={({ value, label }) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={getCurrencyFlag(value)}
                      alt={`${value} flag`}
                      style={{ width: "20px", marginRight: "5px" }}
                    />
                    <span>{label}</span>
                  </div>
                )}
                onChange={handleYouGiveCurrencyChange}
                options={fromCurrencyTransfer}
              />
            </Col>
            <Col md={6}>
              <Label>
                {selectedFromCurrencyTransfer?.currencyCode} Amount{" "}
              </Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                value={fromCurrencyValueTransfer}
                onChange={convertFromCurrency}
                // onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
              />
              {errors.fromCurrency && (
                <div className="text-danger">{errors.fromCurrency}</div>
              )}
            </Col>
          </Row>
          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>Agent Charges</span>
            </Col>
            <Col lg={6}>
              <span>
                {/* <FontAwesomeIcon icon={faMinus} />  */}
                {convertedAgentCharges.toFixed(2)}{" "}
                {selectedFromCurrencyTransfer?.value}
              </span>
            </Col>
          </Row>
          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>Wire Transfer Fee</span>
            </Col>
            <Col lg={6}>
              <span>
                {/* <FontAwesomeIcon icon={faMinus} />  */}
                {convertedWireFee.toFixed(2)}{" "}
                {selectedFromCurrencyTransfer?.value}
              </span>
            </Col>
          </Row>

          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>Commission Fee</span>
            </Col>
            <Col lg={6}>
              <span>
                {/* <FontAwesomeIcon icon={faMinus} />{" "} */}
                {convertedCommissionFee.toFixed(2)}{" "}
                {selectedFromCurrencyTransfer?.value}
              </span>
            </Col>
          </Row>

          <hr />

          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>
                Total Convertible {selectedFromCurrencyTransfer?.value} Amount
              </span>
            </Col>
            <Col lg={6}>
              <span>
                {/* <FontAwesomeIcon icon={faEquals} />{" "} */}
                {parseFloat(fromCurrencyValueTransfer) !== 0
                  ? parseFloat(fromCurrencyValueTransfer) -
                    (convertedWireFee +
                      convertedCommissionFee +
                      convertedAgentCharges || 0)
                  : 0}{" "}
                {selectedFromCurrencyTransfer?.value}
              </span>
            </Col>
          </Row>

          {(selectedFromCurrencyTransfer?.value === "SGD" ||
            toCurrencyTransfer === "SGD") && (
            <Row className="mb-3 text-end">
              <Col lg={6} className="text-start">
                <span>Transfer Rate</span>
              </Col>
              <Col lg={6}>
                <span>
                  {/* {toCurrencyTransfer !== "SGD" ? (
                    <FontAwesomeIcon icon={faDivide} className="me-1" />
                  ) : (
                    <FontAwesomeIcon icon={faXmark} className="me-1" />
                  )} */}
                  {transferRate}
                </span>
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <Col md={6}>
              <Label>Beneficiary Receives</Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#F8F8F8",
                  padding: "2px",
                }}
              >
                <img
                  src={getCurrencyFlag(toCurrencyTransfer)}
                  alt={`${toCurrencyTransfer} flag`}
                />
                <Input
                  value={toCurrencyTransfer}
                  disabled
                  style={{ border: "none" }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Label>{toCurrencyTransfer} Amount </Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                value={toCurrencyValueTransfer}
                onChange={convertToCurrency}
                onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
              />
              {errors.toCurrency && (
                <div className="text-danger" style={{ fontSize: "0.8em" }}>
                  {errors.toCurrency}
                </div>
              )}
            </Col>
          </Row>

          <div className="text-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Processing..." : "Submit"}
            </button>
          </div>
        </Form>
      )}
    </>
  );
};

export default TransferForm;
