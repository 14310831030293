import React, { useEffect, createContext, useContext, useState } from "react";
import { authUser, isAgentSuperAdmin, useCurrency, userBranchId } from "./commonFunction";
import { path } from "../../routes/Path";
import { useLocation } from "react-router-dom";

const initialBranchId = isAgentSuperAdmin ? null : userBranchId;

const LatestTransactionsContext = createContext();

export const useLatestTransactions = () =>
  useContext(LatestTransactionsContext);

export const LatestTransactionsProvider = ({ children }) => {
  const {currency, getCurrency} = useCurrency()
  const [currencyList, setCurrencyList] = useState(currency)
  const [branchId, setbranchId] = useState(initialBranchId);
  const { pathname } = useLocation();

  const getCurrencyList = async () => {
    const resp = await getCurrency();
    setCurrencyList(resp);
  };

  useEffect(()=> {
    if(!currency.length && path !== path.login && path !== path.default){
      getCurrencyList()
    }
  },[pathname])

  return (
    <LatestTransactionsContext.Provider
      value={{
        branchId,
        setbranchId,
        currencyList
      }}
    >
      {children}
    </LatestTransactionsContext.Provider>
  );
};
