export const statusLabels = {
    1: { label: "Pending", color: "warning" },
    2: { label: "Waiting For Requestor", color: "primary" },
    3: { label: "Waiting For Approver", color: "primary" },
    4: { label: "Approved", color: "success" },
    5: { label: "Declined", color: "danger" },
    6: { label: "User Declined", color: "danger" },
    7: { label: "Cancelled", color: "danger" },
    8: { label: "User Acknowledged", color: "info" },
    9: { label: "User Cancelled", color: "info" },
    10: { label: "Waiting For Payment", color: "info" },
    11: { label: "Payment Received", color: "info" },
    12: { label: "Waiting For Collection", color: "info" },
    13: { label: "Cash Collected", color: "info" },
  };
  
  export const statusOptions = [
    { label: "Pending", value: 1},
    { label: "Waiting For Requestor", value: 2 },
    { label: "Waiting For Approver", value: 3 },
    { label: "Approved", value: 4 },
    { label: "Declined", value: 5 },
    { label: "User Declined", value: 6},
    { label: "Cancelled", value: 7 },
    { label: "User Acknowledged", value: 8 },
    { label: "User Cancelled", value: 9},
    { label: "Waiting For Payment", value: 10},
    { label: "Payment Received", value: 11},
    { label: "Waiting For Collection",value: 12},
    { label: "Cash Collected", value: 13},
  ]
    
    const mapStatus = (status) => {
      return statusLabels[status] || { label: "Unknown", color: "secondary" };
    };
    
    export { mapStatus };