import React from "react";
import { Modal, Button } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../../images/logo-dark.png";
import Footer from "../../../components/SuperAdminLayout/Footer";
const ReceiptModal = ({ show, handleClose, receiptData }) => {
  const loadLogoAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      };
      img.onerror = (err) => reject(err);
    });
  };

  const generatePDF = async () => {
    const {
      transactionID,
      transactionDate,
      agentName,
      currencyType,
      currencyValue,
      toCurrType,
      toCurrValue,
      agentBranch,
      convRate,
    } = receiptData;
    // const exchangeRate = (toCurrValue / currencyValue).toFixed(2);
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("Transaction Receipt", 20, 20);

    try {
      const logoBase64 = await loadLogoAsBase64(logo);
      doc.addImage(logoBase64, "PNG", 150, 10, 45, 10);
    } catch (error) {
      console.error("Error loading logo:", error);
    }

    doc.setFontSize(12);
    doc.text(`Transaction ID: ${transactionID}`, 20, 30);
    doc.text(`Transaction Date: ${transactionDate}`, 20, 40);
    doc.text(`Agent Name: ${agentName}`, 20, 50);
    doc.text(`Branch Name: ${agentBranch}`, 20, 60);

    doc.autoTable({
      startY: 80,
      head: [
        [
          "From Currency",
          "Amount",
          "Exchange Rate",
          "To Currency",
          "Amount",
          "Payout Currency",
        ],
      ],
      body: [
        [
          currencyType,
          currencyValue,
          convRate,
          toCurrType,
          toCurrValue,
          `SGD : ${toCurrValue}` || "N/A",
        ],
      ],
      theme: "striped",
      margin: { horizontal: 20 },
      styles: { fontSize: 10 },
    });

    doc.text(
      "Thank you for using Crescent Exchange",
      20,
      doc.lastAutoTable.finalY + 50
    );
    doc.text(
      "2024 Crescent Exchange. All Rights Reserved.",
      20,
      doc.lastAutoTable.finalY + 60
    );

    doc.save("receipt.pdf");
  };

  const handlePrint = () => {
    const modalContent = document.getElementById("modal-print-content");

    // Clone the modal content
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write(`
        <html>
            <head>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        padding: 20px;
                    }
                    .table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .table th, .table td {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }
                    .text-center {
                        text-align: center;
                    }
                    .d-flex {
                        display: flex;
                    }
                    .justify-content-between {
                        justify-content: space-between;
                    }
                </style>
            </head>
            <body>
                ${modalContent.innerHTML}
            </body>
        </html>
    `);

    // Wait for the content to load and then print
    printWindow.document.close();
    printWindow.focus();

    printWindow.onload = function () {
      printWindow.print();
      printWindow.close();
    };
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100"></Modal.Title>
        </Modal.Header>
        <Modal.Body id="modal-print-content">
          <div className="text-center mb-4">
            <img
              src={logo}
              alt="Logo"
              className="img-fluid"
              style={{ maxHeight: "60px", maxWidth: "200px" }}
            />
          </div>
          <h3 className="text-center">Transaction Receipt</h3>
          <p>
            <strong>Transaction ID:</strong> {receiptData.transactionID}
          </p>
          <p>
            <strong>Transaction Date:</strong> {receiptData.transactionDate}
          </p>
          <p>
            <strong>Branch Name:</strong> {receiptData.agentBranch}
          </p>
          <p>
            <strong>Agent Name:</strong> {receiptData.agentName}
          </p>
          <hr />
          <h4 className="text-center">Currency Exchanged</h4>

          <div className="table-responsive">
            <table className="table table-bordered mt-3">
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#f8f9fa" }}>From Currency</th>
                  <th style={{ backgroundColor: "#f8f9fa" }}>Amount</th>
                  <th style={{ backgroundColor: "#f8f9fa" }}>Exchange Rate</th>
                  <th style={{ backgroundColor: "#f8f9fa" }}>To Currency</th>
                  <th style={{ backgroundColor: "#f8f9fa" }}>Amount</th>
                  <th style={{ backgroundColor: "#f8f9fa" }}>
                    Payout Currency
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{receiptData.currencyType}</td>
                  <td>{receiptData.currencyValue}</td>
                  <td>{receiptData.convRate}</td>
                  <td>{receiptData.toCurrType}</td>
                  <td>{receiptData.toCurrValue}</td>
                  <td>
                    {receiptData.toCurrType} :{" "}
                    {receiptData.toCurrValue || "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <p className="text-center mt-5">
            Thank you for using Crescent Exchange
          </p>
          <div className="mt-5 align-content-center">
            <Footer />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={generatePDF}>
            <faDownload /> PDF
          </Button>
          <Button variant="primary" onClick={handlePrint}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>

      <div id="printable-content" style={{ display: "none" }}>
        <div className="text-center mb-4">
          <img
            src={logo}
            alt="Logo"
            className="img-fluid"
            style={{ maxHeight: "60px", maxWidth: "200px" }}
          />
        </div>
        <h3 className="text-center">Transaction Receipt</h3>
        <p>
          <strong>Transaction ID:</strong> {receiptData.transactionID}
        </p>
        <p>
          <strong>Transaction Date:</strong> {receiptData.transactionDate}
        </p>
        <p>
          <strong>Branch Name:</strong> {receiptData.agentBranch}
        </p>
        <p>
          <strong>Customer Name:</strong> {receiptData.customerName}
        </p>
        <hr />
        <h4 className="text-center">Currency Exchanged</h4>

        <div className="table-responsive">
          <table className="table table-bordered mt-3">
            <thead>
              <tr>
                <th style={{ backgroundColor: "#f8f9fa" }}>From Currency</th>
                <th style={{ backgroundColor: "#f8f9fa" }}>Amount</th>
                <th style={{ backgroundColor: "#f8f9fa" }}>Exchange Rate</th>
                <th style={{ backgroundColor: "#f8f9fa" }}>To Currency</th>
                <th style={{ backgroundColor: "#f8f9fa" }}>Amount</th>
                <th style={{ backgroundColor: "#f8f9fa" }}>Payout Currency</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{receiptData.currencyType}</td>
                <td>{receiptData.currencyValue}</td>
                <td>
                  {(
                    receiptData.toCurrValue / receiptData.currencyValue
                  ).toFixed(2)}
                </td>
                <td>{receiptData.toCurrType}</td>
                <td>{receiptData.toCurrValue}</td>
                <td>SGD : {receiptData.toCurrValue || "N/A"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <p className="text-center mt-5">
          Thank you for using Crescent Exchange
        </p>
        <div className="mt-5">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ReceiptModal;
