import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import profileImg from "../../../images/profile-img.png";
import logoImg from "../../../images/crescent_logo.png";
import withRouter from "../../components/Common/withRouter";
import { postApiData } from "../../helpers/axiosHelper";

const EmailVerification = (props) => {
  //meta title
  document.title = "Crescent Exchange | Verification";

  const { uid } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendAgain = async () => {
    setIsLoading(true);
    const response = await postApiData(`api/Email/SendOtpAgain?uid=${uid}`);
    if (response === "OTP re-sent successfully") {
      toast.success("Otp Sent Again, Please check your e-mail", {
        position: "top-right",
        autoClose: 2000,
      });
      setIsLoading(false);
    } else {
      toast.error("OTP sending failed. Contact Admin", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      emailcode: "",
    },
    validationSchema: Yup.object({
      emailcode: Yup.string().required("Please Enter the verification code"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const response = await postApiData(
        "api/Email/VerifyOtp",
        JSON.stringify({ enteredOtp: values.emailcode, uid: uid })
      );
      if (response === "OTP verified successfully") {
        toast.success("OTP verification done, Please login to proceed", {
          position: "top-right",
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/agentlogin");
        }, 3000);
      } else if (response === "Your OTP has been expired") {
        toast.error("Invalid OTP. Please enter the correct OTP.", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/home" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Email Verification</h5>
                        <p>Verify your email by OTP</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">
                          Enter Code <span className="text-danger">*</span>
                        </Label>
                        <Input
                          maxLength={6}
                          id="emailcode"
                          name="emailcode"
                          className="form-control"
                          placeholder="Enter Code"
                          type="input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          disabled={isLoading}
                          value={validation.values.emailcode || ""}
                          invalid={
                            validation.touched.emailcode &&
                            validation.errors.emailcode
                              ? true
                              : false
                          }
                        />
                        {validation.touched.emailcode &&
                        validation.errors.emailcode ? (
                          <FormFeedback type="invalid">
                            {validation.errors.emailcode}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Verify
                        </button>
                        <button
                          onClick={handleSendAgain}
                          className="btn btn-primary btn-block "
                          type="button"
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Send Again
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EmailVerification);
