const TransactionID = (cell) => {
  return cell.value ? cell.value : "";
};

const AgentName = (cell) => {
  return cell.value ? cell.value : "";
};

const CompanyName = (cell) => {
  return cell.value ? cell.value : "";
};

const AgentBranch = (cell) => {
  return cell.value ? cell.value : "";
};

const Branch = (cell) => {
  return cell.value ? cell.value : "";
};

const CurrencyType = (cell) => {
  return cell.value ? cell.value : "";
};

const CurrencyValue = (cell) => {
  return cell.value ? cell.value : "";
};

const Date = (cell) => {
  return cell.value ? cell.value : "";
};

const TransactionDate = (cell) => {
  return cell.value ? cell.value : "";
};

const Status = (cell) => {
  return cell.value ? cell.value : "";
};

const TransactionCurrency = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  TransactionID,
  Date,
  Status,
  Branch,
  AgentBranch,
  AgentName,
  CurrencyType,
  CurrencyValue,
  TransactionDate,
  CompanyName,
  TransactionCurrency,
};
