const BranchId = (cell) => {
  return cell.value ? cell.value : "";
};

const BranchName = (cell) => {
  return cell.value ? cell.value : "";
};

const MainBranch = (cell) => {
  return cell.value ? cell.value : "";
};

const BranchAddress = (cell) => {
  return cell.value ? cell.value : "";
};

const Actions = (cell) => {
  return cell.value ? cell.value : "";
};

export { BranchId, BranchName, MainBranch, BranchAddress, Actions };
