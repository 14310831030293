import React from "react";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Table
} from "reactstrap";


const Testimonial = props => {

  //meta title
  document.title = "Crescent Exchange | Home";

  return (
    <React.Fragment>
      <section className="section bg-white" id="testimonial">
        <Container fluid={false}>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Testimonials</div>
                <h4>Testimonials / Case Studies</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Share Your Experience</CardTitle>

                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your Name"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                      <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Enter Your Email ID"
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Testimonial</Label>
                      <textarea
                          id="formrow-testimonial-Input"
                          className="form-control"
                          rows="2"
                          placeholder="Enter Your Testimonial"
                        />
                    </div>

                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
        {/* container-fluid */}
      </section>
    </React.Fragment>
  );
};

export default Testimonial;
