import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import classname from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import withRouter from "./../Common/withRouter";

import {
  faArrowTrendUp,
  faBuilding,
  faFlagCheckered,
  faPeopleGroup,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = ({ leftMenu, t }) => {
  const [dropdownStates, setDropdownStates] = useState({
    dashboard: false,
    staff: false,
    transaction: false,
    branch: false,
  });

  const toggleDropdown = (dropdown) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const role = authUser?.role;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown-menu")) {
        setDropdownStates({
          dashboard: false,
          staff: false,
          transaction: false,
          branch: false,
        });
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/agentdashboard">
                    <i className="bx bx-home-circle me-1"></i>
                    {t("Dashboard")}
                  </Link>
                </li>

                {role === "4" || role === "5" ? (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/branch"
                    >
                      <FontAwesomeIcon icon={faBuilding} className="me-1" />
                      {t("Manage Branch")}
                    </Link>
                  </li>
                ) : null}

                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    onClick={() => toggleDropdown("transaction")}
                  >
                    <i className="bx bx-wallet me-1"></i>
                    {t("Transaction")}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: dropdownStates.transaction,
                    })}
                  >
                    <Link to="/Add-Transaction" className="dropdown-item">
                      {t("Add Transaction")}
                    </Link>
                    <Link to="/Manage-Transaction" className="dropdown-item">
                      {t("Manage Transaction")}
                    </Link>
                    <Link to="/Transaction-History" className="dropdown-item">
                      {t("Transaction History")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/Manage-Beneficiary"
                  >
                    <FontAwesomeIcon icon={faPerson} className="me-1" />
                    {t("Beneficiary")}
                  </Link>
                </li>

                {role === "4" || role === "5" ? (
                  <li className="nav-item dropdown">
                    <Link
                      to="#"
                      className="nav-link dropdown-toggle"
                      onClick={() => toggleDropdown("staff")}
                    >
                      <FontAwesomeIcon icon={faPeopleGroup} className="me-1" />
                      {t("Staff")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: dropdownStates.staff,
                      })}
                    >
                      <Link to="/manage-staff" className="dropdown-item">
                        {t("Manage Staff")}
                      </Link>
                    </div>
                  </li>
                ) : null}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/Manage-Assignment"
                  >
                    <FontAwesomeIcon icon={faFlagCheckered} className="me-1" />
                    {t("My Assignments")}
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Navbar))
);
