import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { useFormik } from "formik";
import * as Yup from "yup";
import TableContainer from "../../components/Common/TableContainer";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Common/Loader";
import DeleteModal from "../../components/Common/DeleteModal";
import {
  faCheck,
  faEye,
  faPenToSquare,
  faTrash,
  faUserCheck,
  faQuestionCircle,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectStyle from "../../common/data/SelectStyle";
import {
  deleteApiData,
  getApiData,
  postApiData,
  putApiData,
} from "./../../helpers/axiosHelper";
import { roleList } from "./../../common/data/roles";
import {
  isAgentSuperAdmin,
  useBranch,
  userBranchId,
} from "../../common/data/commonFunction";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import Help from "../../components/Common/Help";

const Staff = () => {
  document.title = "Staff | Crescent Exchange";

  const [isLoading, setIsLoading] = useState(false);
  const [modal_editStaff, setmodal_editStaff] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [staffDetails, setStaffDetails] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAgentUid, setDeleteAgentUid] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [unameDisable, setUnameDisable] = useState(true);
  const { branchId } = useLatestTransactions();
  const { branch, fetchBranch } = useBranch();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [agentUID, setAgentUID] = useState("");

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  function tog_editStaff() {
    setmodal_editStaff(!modal_editStaff);
  }

  useEffect(() => {
    fetchBranch();
  }, []);
  console.log(branch);

  useEffect(() => {
    if (branchId !== null && branchId !== undefined) {
      getStaff();
    }
  }, [branchId]);

  const staffValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      // userName: staffDetails?.userName,
      email: staffDetails?.email || "",
      password: "",
      password_confirmation: "",
      firstname: staffDetails?.firstName || "",
      middlename: staffDetails?.middleName || "",
      lastname: staffDetails?.lastName || "",
      mobile: staffDetails?.mobile || "",
      roleId:
        roleList.find((option) => option.value === staffDetails?.roleId) ||
        null,
      branchId: !isAgentSuperAdmin
        ? { label: "branchId", value: userBranchId }
        : branch?.find(
            (option) =>
              parseInt(option.value) === parseInt(staffDetails?.branchId)
          ) || null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Username should be a Email")
        .required("Please Enter Your Username"),
      password: Yup.string().matches(
        /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
        "Password must be at least 8 characters long and contain at least one digit, one uppercase letter, and one special character"
      ),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
      firstname: Yup.string().required("Please Enter Your First name"),
      lastname: Yup.string().required("Please Enter Your Last name"),
      mobile: Yup.string()
        .required("Please Enter Your mobile Number")
        .matches(/^\+?\d{8,14}$/, "mobile Number must be 8-14 digits"),
      roleId: Yup.object().shape({
        label: Yup.string().required("Please Select a Role"),
        value: Yup.string().required("Please Select a Role"),
      }),
      branchId: Yup.object().shape({
        label: Yup.string().required("Please Select a Branch"),
        value: Yup.string().required("Please Select a Branch"),
      }),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const roleValue = values.roleId && values.roleId.value;
        const branchIdValue = values.branchId && values.branchId.value;
        if (staffDetails?.agentUid) {
          const hasChanges = Object.keys(values).some(
            (key) => values[key] !== staffValidation.initialValues[key]
          );
          if (hasChanges) {
            const combinedValues = {
              ...values,
              agentId: staffDetails?.agentUid,
              roleId: roleValue,
              branchId: branchIdValue,
            };
            const response = await putApiData(
              "api/AgentProfile/UpdateAgent",
              JSON.stringify(combinedValues)
            );
            if (response.success === true) {
              toast.success("Staff Successfully Updated", {
                position: "top-right",
                autoClose: 2000,
              });
              getStaff();
              tog_editStaff();
            } else {
              toast.error("Error , Contact Admin", {
                position: "top-right",
                autoClose: 3000,
              });
            }
            tog_editStaff();
          } else {
            toast.error("No Changes to Update", {
              position: "top-right",
              autoClose: 3000,
            });
          }
        } else {
          const combinedValues = {
            ...values,
            roleId: roleValue,
            username: values.email,
            branchId: branchIdValue,
          };
          const response = await postApiData(
            "api/AgentProfile/CreateAgent",
            JSON.stringify(combinedValues)
          );
          if (response) {
            if (response.success === true) {
              toast.success("Staff Successfully Created", {
                position: "top-right",
                autoClose: 2000,
              });
              resetForm();
              tog_editStaff();
              getStaff();
            } else if (response.success === false) {
              toast.error(`${response.message}`, {
                position: "top-right",
                autoClose: 3000,
              });
            } else {
              toast.error("Error , Contact Admin", {
                position: "top-right",
                autoClose: 3000,
              });
            }
          }
        }
      } catch (errors) {
        console.error(errors);
      }
    },
  });

  const UserStatus = {
    0: "Unknown",
    1: "Verification",
    2: "Document Pending",
    3: "Approval Pending",
    4: "Approved",
    5: "Deactivated",
  };

  const getStaff = async () => {
    try {
      setIsLoading(true);
      const response = await getApiData(
        `api/AgentProfile/GetAllAgentByLoggedInUser?branchId=${branchId}`
      );
      setIsLoading(false);

      const mappedResponse = response.data.map((item, key) => ({
        index: key + 1,
        agentUid: item.agentUid,
        userName: item.email,
        email: item.email,
        password: item.password,
        firstName: item.firstName,
        middleName: item.middleName,
        lastName: item.lastName,
        name: `${item.firstName} ${item.lastName}`,
        mobile: item.mobile,
        role: getRoleLabel(item.roleId),
        roleId: `${item.roleId}`,
        active: item.active,
        userStatus: UserStatus[item.userStatus],
        branchId: `${item.branchId}` || null,
        branchName: item.branchName,
        uid: item.uid,
      }));
      setStaffData(mappedResponse);
      console.log("Mapped Response " + staffData);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "userName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Role",
        accessor: "role",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Status",
        accessor: "active",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.row.original.userStatus === "Approved"
                  ? "success"
                  : "danger" && cellProps.value === "Verification"
                  ? "warning"
                  : "danger" && cellProps.value === "Document Pending"
                  ? "primary"
                  : "danger" && cellProps.value === "Approval Pending"
                  ? "danger"
                  : "danger")
              }
            >
              {cellProps.row.original.userStatus}
            </Badge>
          );
        },
      },
      {
        Header: "Branch",
        accessor: "branchName",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },

      // {
      //   Header: "Active",
      //   accessor: "status",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: (cellProps) => {
      //     return (
      //       <Badge
      //         className={
      //           "font-size-11 badge-soft-" +
      //           (cellProps.row.original.active === 1 ? "success" : "danger")
      //         }
      //       >
      //         {cellProps.row.original.active === 1 ? "Active" : "Deactivated"}
      //       </Badge>
      //     );
      //   },
      // },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => {
          const authUser = JSON.parse(localStorage.getItem("authUser"));
          const uid = authUser.uid;
          return (
            <>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                style={{ marginRight: "5px", marginBottom: "5px" }}
                onClick={() => {
                  setViewMode(true);
                  setUnameDisable(true);
                  tog_editStaff();
                  setStaffDetails(cellProps.row.original || null);
                }}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
              {cellProps.row.original.active === 1 &&
                cellProps.row.original.userStatus === "Approved" && (
                  <Button
                    type="button"
                    color="success"
                    className="btn-sm btn-rounded"
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                    onClick={() => {
                      setViewMode(false);
                      setUnameDisable(true);
                      tog_editStaff();

                      setStaffDetails(cellProps.row.original || null);
                      console.log("Staff details in edit mode " + staffDetails);
                    }}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </Button>
                )}

              {cellProps.row.original.active === 1 &&
                cellProps.row.original.uid !== uid && (
                  <Button
                    type="button"
                    color="danger"
                    className="btn-sm btn-rounded"
                    title="Deactivate"
                    onClick={() => {
                      changeAgentStatus(
                        cellProps.row.original.agentUid,
                        false,
                        "deactivate"
                      );
                    }}
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                  >
                    <FontAwesomeIcon icon={faX} />
                  </Button>
                )}
              {cellProps.row.original.active === 0 &&
                cellProps.row.original.uid !== uid && (
                  <Button
                    type="button"
                    color="success"
                    className="btn-sm btn-rounded"
                    title="Activate"
                    onClick={() => {
                      changeAgentStatus(
                        cellProps.row.original.agentUid,
                        true,
                        "activate"
                      );
                    }}
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                )}
              {(cellProps.row.original.userStatus === "Document Pending" ||
                cellProps.row.original.userStatus === "Approval Pending" ||
                cellProps.row.original.roleId === 6) && (
                <Button
                  type="button"
                  color="success"
                  className="btn-sm btn-rounded"
                  title="Approve"
                  onClick={(e) => {
                    changeAgentStatus(
                      cellProps.row.original.agentUid,
                      true,
                      "approve"
                    );
                  }}
                  style={{
                    marginRight: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <FontAwesomeIcon icon={faUserCheck} />
                </Button>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const getRoleLabel = (roleId) => {
    switch (roleId) {
      case 4:
        return "Agent Super Admin";
      case 5:
        return "Agent Branch Admin";
      case 6:
        return "Agent User";
      case null:
        return "No Role Assigned";
      default:
        return "Unknown Role";
    }
  };

  const softDeleteStaffData = async () => {
    try {
      const response = await deleteApiData(
        `api/AgentProfile/DelteAgent?AgentId=${deleteAgentUid}`
      );
      if (response.success === true) {
        toast.success("Staff Deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
        });
        setDeleteModal(false);
        getStaff();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeAgentStatus = async (agentUid, flag, action) => {
    try {
      // console.log("Agent ID to approve " + approveAgentUid);
      const response = await putApiData(
        `api/AgentProfile/ApproveOrReject?AgentID=${agentUid}&Approved=${flag}`
      );
      if (response.success === true) {
        if (action === "approve") {
          toast.success("Agent verified and approved!", {
            position: "top-right",
            autoClose: 2000,
          });
          getStaff();
        } else if (action === "deactivate") {
          toast.success("Agent deactivated successfully!", {
            position: "top-right",
            autoClose: 2000,
          });
          getStaff();
        } else {
          {
            toast.success("Agent activated successfully!", {
              position: "top-right",
              autoClose: 2000,
            });
            getStaff();
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const branchAdminRoleList = roleList.filter(
    (role) => role.value === "6" || role.value === "5"
  );
  const roleListOptions = isAgentSuperAdmin ? roleList : branchAdminRoleList;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ToastContainer closeButton={false} limit={1} />
          <Modal
            isOpen={modal_editStaff}
            toggle={() => {
              tog_editStaff();
            }}
            name="Edit Staff"
            size="lg"
            onClosed={() => {
              staffValidation.resetForm();
            }}
          >
            <div className="modal-header d-flex justify-content-between align-items-center">
              <h5 className="modal-title" id="myModalLabel">
                Staff Details
              </h5>
              {/* <Help to={"Branch_Help.html"} /> */}
            </div>
            <button
              type="button"
              onClick={() => setmodal_editStaff(!modal_editStaff)}
              className="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div className="modal-body">
              <Form
                className="needs-validation"
                onSubmit={(e) => {
                  e.preventDefault();
                  staffValidation.handleSubmit(e);
                  return false;
                }}
              >
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="email">Username</Label>
                      <RequiredAsterisk />
                      <Input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Username"
                        disabled={unameDisable}
                        onChange={staffValidation.handleChange}
                        onBlur={staffValidation.handleBlur}
                        value={staffValidation.values.email}
                        invalid={
                          staffValidation.touched.email &&
                          staffValidation.errors.email
                            ? true
                            : false
                        }
                      />
                      {staffValidation.touched.email &&
                      staffValidation.errors.email ? (
                        <FormFeedback type="invalid">
                          {staffValidation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  {viewMode === false && (
                    <>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="password">Password</Label>
                          <RequiredAsterisk />
                          <Input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Enter Password"
                            disabled={viewMode}
                            value={staffValidation.values.password}
                            onChange={staffValidation.handleChange}
                            invalid={
                              staffValidation.errors.password ? true : false
                            }
                          />
                          {staffValidation.errors.password ? (
                            <FormFeedback type="invalid">
                              {staffValidation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">Confirm Password</Label>
                          <RequiredAsterisk />
                          <Input
                            id="password_confirmation"
                            name="password_confirmation"
                            className="form-control"
                            placeholder="Confirm Your Password"
                            type="password"
                            disabled={viewMode}
                            value={staffValidation.values.password_confirmation}
                            onChange={staffValidation.handleChange}
                            invalid={
                              staffValidation.errors.password_confirmation
                                ? true
                                : false
                            }
                          />
                          {staffValidation.errors.password_confirmation ? (
                            <FormFeedback type="invalid">
                              {staffValidation.errors.password_confirmation}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </>
                  )}

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="firstname">First Name</Label>
                      <RequiredAsterisk />
                      <Input
                        type="text"
                        className="form-control"
                        id="firstname"
                        placeholder="Enter First Name"
                        disabled={viewMode}
                        onChange={staffValidation.handleChange}
                        onBlur={staffValidation.handleBlur}
                        value={staffValidation.values.firstname || ""}
                        invalid={
                          staffValidation.touched.firstname &&
                          staffValidation.errors.firstname
                            ? true
                            : false
                        }
                      />
                      {staffValidation.touched.firstname &&
                      staffValidation.errors.firstname ? (
                        <FormFeedback type="invalid">
                          {staffValidation.errors.firstname}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <Label htmlFor="middlename">Middle Name</Label>
                    <Input
                      name="middlename"
                      placeholder="Enter Middle Name"
                      id="middlename"
                      disabled={viewMode}
                      value={staffValidation.values.middlename}
                      onChange={staffValidation.handleChange}
                    />
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="lastname">Last Name</Label>
                      <RequiredAsterisk />
                      <Input
                        type="text"
                        className="form-control"
                        id="lastname"
                        placeholder="Enter Last Name"
                        disabled={viewMode}
                        onChange={staffValidation.handleChange}
                        onBlur={staffValidation.handleBlur}
                        value={staffValidation.values.lastname || ""}
                        invalid={
                          staffValidation.touched.lastname &&
                          staffValidation.errors.lastname
                            ? true
                            : false
                        }
                      />
                      {staffValidation.touched.lastname &&
                      staffValidation.errors.lastname ? (
                        <FormFeedback type="invalid">
                          {staffValidation.errors.lastname}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="role">Role</Label>
                      <RequiredAsterisk />
                      <Select
                        id="roleId"
                        name="role"
                        isDisabled={viewMode}
                        styles={SelectStyle}
                        value={staffValidation.values.roleId}
                        onBlur={() =>
                          staffValidation.setFieldTouched("roleId", true)
                        }
                        onChange={(selectedOption) => {
                          staffValidation.setFieldValue(
                            "roleId",
                            selectedOption
                          );
                        }}
                        options={roleListOptions}
                        invalid={
                          staffValidation.touched.roleId &&
                          staffValidation.errors.roleId
                            ? true
                            : false
                        }
                      />
                      {staffValidation.errors.roleId &&
                      staffValidation.touched.roleId ? (
                        <span
                          className="text-danger"
                          style={{ "font-size": "80%" }}
                        >
                          {staffValidation.errors.roleId}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="mobile">Mobile</Label>
                      <RequiredAsterisk />
                      <Input
                        type="text"
                        className="form-control"
                        id="mobile"
                        placeholder="Enter Mobile Number"
                        disabled={viewMode}
                        onChange={staffValidation.handleChange}
                        onBlur={staffValidation.handleBlur}
                        value={staffValidation.values.mobile || ""}
                        invalid={
                          staffValidation.touched.mobile &&
                          staffValidation.errors.mobile
                            ? true
                            : false
                        }
                      />
                      {staffValidation.touched.mobile &&
                      staffValidation.errors.mobile ? (
                        <FormFeedback type="invalid">
                          {staffValidation.errors.mobile}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  {isAgentSuperAdmin ? (
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="branch">Branch</Label>
                        <RequiredAsterisk />
                        <Select
                          id="branchId"
                          name="branch"
                          isDisabled={viewMode}
                          styles={SelectStyle}
                          value={staffValidation.values.branchId}
                          onBlur={() =>
                            staffValidation.setFieldTouched("branchId", true)
                          }
                          onChange={(selectedOption) => {
                            staffValidation.setFieldValue(
                              "branchId",
                              selectedOption
                            );
                          }}
                          options={branch}
                          invalid={
                            staffValidation.touched.branchId &&
                            staffValidation.errors.branchId
                              ? true
                              : false
                          }
                        />
                        {staffValidation.errors.branchId &&
                        staffValidation.touched.branchId ? (
                          <span
                            className="text-danger"
                            style={{ "font-size": "80%" }}
                          >
                            {staffValidation.errors.branchId}
                          </span>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary "
                onClick={() => tog_editStaff()}
                hidden={!viewMode}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary "
                onClick={staffValidation.handleSubmit}
                hidden={viewMode}
                disabled={staffValidation.isSubmitting}
              >
                Save Changes
              </button>
            </div>
          </Modal>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={softDeleteStaffData}
            onCloseClick={() => setDeleteModal(!deleteModal)}
          />
          <Card>
            <CardBody>
              <Col>
                <h4 className="card-title mb-4 d-flex justify-content-between">
                  Staff Details
                  <Help to={"Branch_Help.html"} />
                </h4>
              </Col>
              <div className="text-sm-end mb-2">
                <button
                  type="button"
                  onClick={() => {
                    setViewMode(false);
                    setUnameDisable(false);
                    setStaffDetails(null);
                    tog_editStaff();
                  }}
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Add New Staff
                </button>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <TableContainer
                    columns={columns}
                    data={staffData}
                    isGlobalFilter={true}
                    customPageSize={10}
                  />
                  {staffData.length < 1 ? (
                    <div>
                      <p>No Records to Show</p>
                    </div>
                  ) : null}
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Staff;
