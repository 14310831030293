import React from 'react'
import '../../../scss/cex.scss'
import { Spinner } from 'reactstrap'

const Loader = () => {
  return (
    <div className="background-overlay">
        <Spinner className="ms-2" color="primary" />
    </div>
  )
}

export default Loader