import React, { useState } from "react";
import "../../../scss/cex.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";

import { Link } from "react-router-dom";

const Help = (props) => {
  const { to } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const url = `http://localhost:7000/Agent/${to}`;

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const OpenHTML = () => {
    console.log("Help clicked");
    window.open(url, "_blank");
  };

  return (
    <div>
      <Link to={to}>
        <FontAwesomeIcon
          icon={faQuestionCircle}
          id="helpIcon"
          style={{ cursor: "pointer", color: "#74788d" }}
          onClick={(e) => {
            e.preventDefault();
            OpenHTML();
          }}
        />
      </Link>
      <Tooltip
        placement="left"
        isOpen={tooltipOpen}
        target="helpIcon"
        toggle={toggleTooltip}
        style={{ backgroundColor: "#eff2f7", color: "#000" }}
      >
        Help
      </Tooltip>
    </div>
  );
};

export default Help;
