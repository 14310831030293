import React, { createContext, useState } from "react";
export const BaseCurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currencyData, setCurrencyData] = useState();
  const [selectedContextCurrency, setSelectedContextCurrency] = useState();

  return (
    <BaseCurrencyContext.Provider
      value={{
        currencyData,
        setCurrencyData,
        selectedContextCurrency,
        setSelectedContextCurrency,
      }}
    >
      {children}
    </BaseCurrencyContext.Provider>
  );
};
