import React, { useEffect, useState, useContext } from "react";
import ReactSelect from "react-select";
import { Form, Row, Col, Label, Input, Card, Button } from "reactstrap";
import { toast } from "react-toastify";
import { BaseCurrencyContext } from "../../common/data/context";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import Loader from "../../components/Common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faXmark,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import { SelectStyleForMenu } from "../../common/data/SelectStyleForMenu";
import BeneficiaryModal from "../Beneficiary/BeneficiaryModal";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";

const DashboardTransfer = ({ reload }) => {
  const [branch, setBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [transferCurrency, setTransferCurrency] = useState([]);
  const [selectedFromCurrencyTransfer, setSelectedFromCurrencyTransfer] =
    useState(null);
  const [selectedToCurrencyTransfer, setSelectedToCurrencyTransfer] =
    useState(null);
  const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] =
    useState("");
  const [convertedAmountTransfer, setConvertedAmountTransfer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modal1, setModal1] = useState(false);
  const { currencyData } = useContext(BaseCurrencyContext);
  const [currency, setCurrency] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [totalSGD, setTotalSGD] = useState(0);
  const [currencyIcons, setCurrencyIcons] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [wireTransferFee, setWireTransferFee] = useState(0);
  const [commissionCharges, setCommissionCharges] = useState(0);
  const [branchConversionRate, setBranchConversionRate] = useState(1);
  const [wireTransferFeeValue, setWireTransferFeeValue] = useState(0);
  const [commissionFeeValue, setCommissionFeeValue] = useState(0);
  const [convertedSGD, setConvertedSGD] = useState("");
  const [isAlternateCalculation, setIsAlternateCalculation] = useState(false);
  const [buyRate, setBuyRate] = useState(0);
  const [nationalData, setNationalData] = useState([]);
  const [validationErrorFromCurrency, setValidationErrorFromCurrency] =
    useState("");
  const [validationErrorToCurrency, setValidationErrorToCurrency] =
    useState("");
  const [validationErrorBeneficiary, setValidationErrorBeneficiary] =
    useState("");
  const [validationErrorCurrency, setValidationErrorCurrency] = useState("");
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const [sendBeneficiaryDetails, setSendBeneficiaryDetails] = useState(null);
  const [custBenId, setCustBenId] = useState("");
  const handleHelpClick = () => {
    window.open(
      "https://sitapi.crescentexchange.com/help/customer/DashboardTransfer.pdf"
    );
  };
  const { branchId } = useLatestTransactions();
  const isActive = {
    0: "inActive",
    1: "active",
  };

  const status = {
    0: "draft",
    1: "inComplete",
    2: "complete",
  };

  const accType = {
    1: "personal",
    2: "bussiness",
  };

  useEffect(() => {
    const fetchCurrencies = async () => {
      const response = await getApiData("api/RateMaster/GetGlobalTransferRate");
      if (response.success) {
        const formattedCurrencies = response.data.map((item) => ({
          label: item.currencyName,
          value: item.currencyCode,
          wireTransferFee: item.wireTransferFee, // Add this
          commissionCharges: item.commissionCharges, // Add this
        }));
        setTransferCurrency(formattedCurrencies);

        if (!selectedFromCurrencyTransfer) {
          const defaultCurrency = formattedCurrencies.find(
            (currency) => currency.value === "SGD"
          );
          if (defaultCurrency) {
            setSelectedFromCurrencyTransfer(defaultCurrency);
          }
        }
        //const defaultCurrency = formattedCurrencies.find((currency) => currency.value === "SGD");
        //if (defaultCurrency) {
        //    setSelectedFromCurrencyTransfer(defaultCurrency);
        //}

        setWireTransferFee(response.data[0]?.wireTransferFee || 0);
        setCommissionCharges(response.data[0]?.commissionCharges || 0);
      }
    };

    fetchCurrencies();
  }, []);

  const getTransactionRate = async (branchId, fromCurr, toCurr) => {
    try {
      const response = await getApiData(
        `api/RateMaster/GetTransactionRate?branchId=${branchId}&fromCurr=${fromCurr}&toCurr=${toCurr}`
      );
      if (response.success && response.data) {
        const { buyRate, sellRate } = response.data;

        if (parseFloat(buyRate) > 0 && parseFloat(sellRate) > 0) {
          setBranchConversionRate(parseFloat(sellRate));
          setBuyRate(parseFloat(buyRate));
          setIsAlternateCalculation(true);
        } else {
          setBranchConversionRate(0);
          setIsAlternateCalculation(false);
          toast.error("No transaction rate available", { autoClose: 3000 });
        }
      } else {
        setBranchConversionRate(0);
        setIsAlternateCalculation(false);
        toast.error("Failed to fetch transaction rate", { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error fetching transaction rate:", error);
      setBranchConversionRate(0);
      setIsAlternateCalculation(false);
      toast.error("Error fetching transaction rate", { autoClose: 3000 });
    }
  };

  const handleBranchSelection = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
    setIsAlternateCalculation(false);
    setConvertedSGD("");
    setTotalSGD("");
    setConvertedAmountTransfer("");

    if (
      selectedBranch &&
      selectedFromCurrencyTransfer &&
      selectedToCurrencyTransfer &&
      selectedFromCurrencyTransfer.value !== "SGD" &&
      selectedToCurrencyTransfer.value !== "SGD"
    ) {
      const fromCurr = selectedFromCurrencyTransfer.value;
      const toCurr = selectedToCurrencyTransfer.value;

      getTransactionRate(selectedBranch.value, fromCurr, toCurr);
    }
  };

  useEffect(() => {
    if (selectedBranch && buyRate > 0) {
      // Calculate fees when branch or buyRate changes
      const updatedWireTransferFee = parseFloat(wireTransferFee || 0) * buyRate;
      const updatedCommissionFee = parseFloat(commissionCharges || 0) * buyRate;

      setWireTransferFeeValue(updatedWireTransferFee);
      setCommissionFeeValue(updatedCommissionFee);
    }
  }, [selectedBranch, buyRate]); // Dependencies to watch

  const fetchBeneficiaries = async () => {
    const response = await getApiData(
      "api/AgentBeneficiary/GetAllByAgentBeneficiary"
    );
    const filteredResponse = response.data.filter(
      (item) => item.status === 2 && item.active === 1
    );
    const mappedBeneficiaries = filteredResponse.map((item) => ({
      label: item.beneficiaryName,
      value: item.custBenId,
      currencyName: item.currencyName.split("(")[1]?.replace(")", "") || "",
    }));
    setBeneficiary(mappedBeneficiaries);
  };

  useEffect(() => {
    getAllNationlity();
    fetchBeneficiaries();
    //ViewBeneficiaryDetails();
  }, []);

  useEffect(() => {
    if (currencyData?.value && transferCurrency.length > 0) {
      const defaultCurrency = transferCurrency.find(
        (currency) => currency.value === currencyData.value
      );
      if (defaultCurrency) {
        setSelectedToCurrencyTransfer(defaultCurrency);
      }
    }
  }, [currencyData, transferCurrency]);

  const resetForm = () => {
    setSelectedBeneficiary(null);
    setSelectedFromCurrencyTransfer(null);

    setFromCurrencyValueTransfer("");
    setConvertedAmountTransfer("");
    setConvertedSGD("");
    setSelectedBranch("");
    setTotalSGD("");
    setWireTransferFee(0);
    setCommissionCharges(0);
    setCommissionFeeValue("");
    setWireTransferFeeValue("");
    setValidationErrorFromCurrency("");
    setValidationErrorToCurrency("");
    setIsAlternateCalculation(false);
  };

  const handleSubmitTransfer = async (e) => {
    e.preventDefault();
    if (
      !selectedBeneficiary ||
      !selectedFromCurrencyTransfer ||
      !selectedToCurrencyTransfer ||
      !fromCurrencyValueTransfer
    ) {
      toast.error("Please complete all fields.", { autoClose: 3000 });
      return;
    }

    const transferData = {
      toCustBenId: selectedBeneficiary.value,
      fromCurrType: selectedFromCurrencyTransfer.value,
      toCurrType: selectedToCurrencyTransfer.value,
      fromCurrValue: fromCurrencyValueTransfer,
      toCurrValue: convertedAmountTransfer,
      transactionType: 3,
      fromBranchId: selectedBranch.value,
      agentBranchID: branchId,
    };

    try {
      setIsLoading(true);
      const response = await postApiData(
        "api/Transaction/InsertTransaction",
        transferData
      );
      if (response.success) {
        toast.success("Transaction request completed", { autoClose: 3000 });
        resetForm();
        reload();
      } else {
        toast.error(response.message, { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Transaction Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBranchByCurrCode = async (currencyCode, rateType) => {
    try {
      const avaBranch = await getApiData(
        `api/RateMaster/GetBranchForCurrency?currencyCode=${currencyCode}&rateType=${rateType}`
      );
      const uniqueBranches = Array.from(
        new Map(
          avaBranch.data.map((branch) => [branch.branchName, branch])
        ).values()
      );
      const formattedBranch = uniqueBranches.map((branch) => ({
        label: branch.branchName,
        value: branch.branchId,
        address: `${branch.street}, ${branch.city}, ${branch.postalcode}`,
        availableAmount: rateType === 2 ? undefined : branch.availableAmount,
        conversionValue: branch.value,
      }));

      setBranch(formattedBranch);

      if (formattedBranch.length > 0) {
        setBranchConversionRate(formattedBranch[0]?.conversionValue || 1);
      }
    } catch (error) {
      console.error("Error fetching branches", error);
    }
  };

  useEffect(() => {
    if (selectedFromCurrencyTransfer) {
      getBranchByCurrCode(selectedFromCurrencyTransfer, 3);
    }
    if (selectedToCurrencyTransfer) {
      getBranchByCurrCode(selectedToCurrencyTransfer.value, 3);
    }
  }, [selectedFromCurrencyTransfer, selectedToCurrencyTransfer]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await getApiData("api/RateMaster/GetAllCurrencies");
        if (response.success) {
          const mappedResponse = response.data.map((item, index) => ({
            index: index + 1,
            currencyId: item.currencyId,
            currencyName: item.currencyName,
            currencyCode: item.currencyCode,
          }));
          setCurrency(mappedResponse);

          const icons = {};
          response.data.forEach((currency) => {
            icons[
              currency.currencyCode.trim()
            ] = `data:image/png;base64,${currency.currencyFlag}`;
          });
          setCurrencyIcons(icons);

          setCurrencies(response.data);
        } else {
          console.error("Error fetching currencies");
        }
      } catch (error) {
        console.error("Failed to fetch currencies:", error);
      }
    };

    fetchCurrencies();
  }, []);
  const toggleAddModal = () => setModal1(!modal1);
  const getDocument = async (custBenId) => {
    const docResponse = await getApiData(
      `api/Customerbeneficiary/GetBeneficiaryDocByCustBenId?CustBenId=${custBenId}`
    );
    const document = await docResponse.data;
    setDocumentData(document);
  };
  const handleAmountChangeTransfer = (e) => {
    const value = e.target.value;
    const amount = parseFloat(value);

    if (!isNaN(amount) && value !== "") {
      if (amount < 10) {
        setValidationErrorFromCurrency("Amount must be at least 10.");
      } else {
        setValidationErrorFromCurrency("");
      }

      setFromCurrencyValueTransfer(value);
      const wireTransferFeeValues = parseFloat(wireTransferFee || 0) * buyRate;
      setWireTransferFeeValue(wireTransferFeeValues);

      const commissionFeeValues = parseFloat(commissionCharges || 0) * buyRate;
      setCommissionFeeValue(commissionFeeValues);
      const feesTotal = wireTransferFeeValues + commissionFeeValues;

      const totalCharges =
        parseFloat(wireTransferFee || 0) + parseFloat(commissionCharges || 0);

      if (isAlternateCalculation) {
        const convertedSGDValue = Math.max(
          amount / buyRate - feesTotal,
          0
        ).toFixed(2);
        setConvertedSGD(convertedSGDValue);

        const convertedAmount = Math.max(
          convertedSGDValue * branchConversionRate,
          0
        ).toFixed(2);
        setConvertedAmountTransfer(convertedAmount);
      } else {
        const totalSGD = Math.max(amount - totalCharges, 0).toFixed(2);
        setTotalSGD(totalSGD);

        const convertedAmount = Math.max(
          totalSGD * branchConversionRate,
          0
        ).toFixed(2);
        setConvertedAmountTransfer(convertedAmount);
      }
    } else {
      setValidationErrorFromCurrency("");
      setFromCurrencyValueTransfer(value);
      setConvertedAmountTransfer("");
      setTotalSGD("");
      setConvertedSGD("");
    }
  };
  const handleConvertedAmountChange = (e) => {
    const value = e.target.value;
    const convertedAmount = parseFloat(value);

    if (!isNaN(convertedAmount) && value !== "") {
      if (convertedAmount < 10) {
        setValidationErrorToCurrency("Converted amount must be at least 10.");
        setConvertedAmountTransfer(null);
        return;
      }

      setValidationErrorToCurrency("");
      setConvertedAmountTransfer(value);

      const totalCharges =
        parseFloat(wireTransferFee || 0) + parseFloat(commissionCharges || 0);
      const wireTransferFeeValues = parseFloat(wireTransferFee || 0) * buyRate;
      setWireTransferFeeValue(wireTransferFeeValue);

      const commissionFeeValue = parseFloat(commissionCharges || 0) * buyRate;

      const feesTotal = wireTransferFeeValues + commissionFeeValue;

      if (isAlternateCalculation) {
        const convertedSGD = Math.max(
          convertedAmount / branchConversionRate,
          0
        ).toFixed(2);
        setConvertedSGD(convertedSGD);

        const reverseAmount = Math.max(
          (parseFloat(convertedSGD) + feesTotal) * buyRate,
          0
        ).toFixed(2);
        setFromCurrencyValueTransfer(reverseAmount);
      } else {
        const reverseAmount = Math.max(
          convertedAmount / branchConversionRate + totalCharges,
          0
        );
        setFromCurrencyValueTransfer(reverseAmount.toFixed(2));
      }
    } else {
      setValidationErrorToCurrency("");
      setConvertedAmountTransfer("");
      setFromCurrencyValueTransfer("");
      setConvertedSGD("");
    }
  };

  const handleCurrencyChange = (selectedCurrency) => {
    if (selectedCurrency?.value === selectedToCurrencyTransfer?.value) {
      setValidationErrorCurrency(
        "You cannot select the same currency for both fields."
      );
    } else {
      setValidationErrorCurrency("");
    }

    setSelectedToCurrencyTransfer(selectedCurrency);

    //const defaultBeneficiary = beneficiary.find(
    //    (item) => item.currencyName === selectedCurrency.value
    //);
    setSelectedBeneficiary(null);

    const selectedCurrencyData = transferCurrency.find(
      (currency) => currency.value === selectedCurrency.value
    );

    if (selectedCurrencyData) {
      setWireTransferFee(selectedCurrencyData.wireTransferFee || 0);
      setCommissionCharges(selectedCurrencyData.commissionCharges || 0);
    }
  };

  useEffect(() => {
    if (currencyData?.value && transferCurrency.length > 0) {
      const defaultCurrency = transferCurrency.find(
        (currency) => currency.value === currencyData.value
      );
      if (defaultCurrency) {
        handleCurrencyChange(defaultCurrency);
      }
    }
  }, [currencyData, transferCurrency]);

  const handleCreateNewBeneficiary = (selectedOption) => {
    if (selectedOption === null) {
      setSelectedBeneficiary(null);
      return;
    }
    if (selectedOption.value === "create-new") {
      toggleAddModal();
    } else {
      setSelectedBeneficiary(selectedOption);
      setValidationErrorBeneficiary("");
    }
  };

  const getBeneficiaryOptions = () => {
    if (
      selectedToCurrencyTransfer &&
      !beneficiary.some(
        (item) => item.currencyName === selectedToCurrencyTransfer.value
      )
    ) {
      return [
        {
          value: "create-new",
          label: `Create New Beneficiary for ${selectedToCurrencyTransfer.value} currency`,
          isNew: true,
        },
      ];
    }
    return beneficiary
      .filter((item) => item.currencyName === selectedToCurrencyTransfer?.value)
      .map((item) => ({
        value: item.value,
        label: item.label,
      }));
  };

  const beneficiaryOptions = getBeneficiaryOptions();

  const showValidationError =
    beneficiaryOptions.length === 1 && beneficiaryOptions[0].isNew;

  const handleFromCurrencyChange = (selectedCurrency) => {
    if (selectedCurrency?.value === selectedToCurrencyTransfer?.value) {
      setValidationErrorCurrency(
        "You cannot select the same currency for both fields."
      );
    } else {
      setValidationErrorCurrency("");
    }

    setSelectedFromCurrencyTransfer(selectedCurrency);
    setSelectedBranch("");
  };

  const getAllNationlity = async () => {
    try {
      setIsLoading(true);
      const nationalResponse = await getApiData(
        "api/Address/GetAllNationality"
      );
      const national = await nationalResponse;

      const mappedNationalities = national.map((nation) => ({
        value: nation.id.toString(),
        label: nation.nation,
        country: nation.country,
      }));

      setNationalData(mappedNationalities);
      setIsLoading(false);
    } catch (error) {
      toast.error("Error occurred while fetching data.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const fetchAllBeneficiary = async () => {
    try {
      setIsLoading(true);
      const response = await getApiData(
        "api/CustomerBeneficiary/GetAllBeneficiaryByCustomer"
      );

      const mappedResponse = response.data.map((item, index) => ({
        index: index + 1,

        custBenId: item.custBenId,
        customerId: item.customerId,

        beneficiaryName: item.beneficiaryName,
        email: item.email,
        mobile: item.mobile,
        dob: item.dob,
        currencyId: item.currencyId,
        currencyName: item.currencyName,
        nationalId: item.nationalId,

        bankName: item.bankName,
        isMyAccount: item.isMyAccount,
        accountType: accType[item.accountType],
        accountNumber: item.accountNumber,
        swift: item.swift,
        other: item.other,
        bankAddress: item.bankAddress,

        addressId: item.addressId,
        street: item.street,
        city: item.city,
        state: item.state,
        country: item.country,
        postalCode: item.postalCode,
        nationality: item.nationality,

        status: status[item.status],
        active: isActive[item.active],
      }));
      setBeneficiaryData(mappedResponse);
      setIsLoading(false);
    } catch (error) {
      console.log("Error occuried.", error);
    }
  };

  //const ViewBeneficiaryDetails = async (cellProps) => {
  //    const { custBenId } = cellProps;
  //    setCustBenId(custBenId);

  //    try {
  //        const benResponse = await getApiData(`api/Customerbeneficiary/GetBeneficiaryById?BeneficiaryId=${custBenId}`);
  //        const processedData = {
  //            ...benResponse.data,
  //            dob: benResponse.data.dob.split("T")[0],
  //        };
  //        setSendBeneficiaryDetails(processedData);

  //        getDocument(custBenId);

  //    } catch (error) {
  //        toast.error(error.message, {
  //            position: "top-right",
  //            autoClose: 3000,
  //        });
  //    }

  //    toggleAddModal();
  //};

  return (
    <>
      <BeneficiaryModal
        currencies={currency}
        isOpen={modal1}
        toggle={toggleAddModal}
        getDocument={getDocument}
        editMode={3}
        fetchAllBeneficiary={fetchAllBeneficiary}
        nationalData={nationalData}
        //createBenFor={selectedToCurrencyTransfer.value}
        //createBenFor={beneficiaryData[0]?.currencyName}
        documentData={documentData}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Card className="shadow-sm p-4">
          <div className="ms-3 d-flex justify-content-end  ">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              onClick={handleHelpClick}
              color="#556ee6"
              id="helpIcon"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            />
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="helpIcon"
              toggle={() => setTooltipOpen(!tooltipOpen)}
            >
              Help
            </Tooltip>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <Form onSubmit={handleSubmitTransfer}>
              <div className="mb-3">
                <Label>Select Beneficiary</Label>
                <ReactSelect
                  value={selectedBeneficiary}
                  onChange={handleCreateNewBeneficiary}
                  options={beneficiaryOptions}
                  placeholder="Select Beneficiary"
                  isClearable
                />

                {showValidationError && (
                  <div className="text-danger mt-2">
                    No beneficiary available. Please create a new one.
                  </div>
                )}
              </div>

              <Row>
                <Col md={6}>
                  <Label>You Send</Label>
                  <ReactSelect
                    value={selectedFromCurrencyTransfer}
                    onChange={handleFromCurrencyChange}
                    formatOptionLabel={({ value, label }) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={currencyIcons[value]}
                          alt={`${value} flag`}
                          style={{ width: "20px", marginRight: "5px" }}
                        />
                        {label}
                      </div>
                    )}
                    options={transferCurrency}
                  />
                  {validationErrorCurrency && (
                    <div className="text-danger mt-2">
                      {validationErrorCurrency}
                    </div>
                  )}
                </Col>
                <Col md={6}>
                  <Label>Beneficiary Receive</Label>
                  <ReactSelect
                    value={selectedToCurrencyTransfer}
                    onChange={handleCurrencyChange}
                    formatOptionLabel={({ value, label }) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={currencyIcons[value]}
                          alt={`${value} flag`}
                          style={{ width: "20px", marginRight: "5px" }}
                        />
                        {label}
                      </div>
                    )}
                    options={transferCurrency}
                  />
                </Col>
              </Row>
              <div className="mb-3">
                <Label>Select Branch</Label>
                <ReactSelect
                  styles={SelectStyleForMenu}
                  value={selectedBranch}
                  onChange={handleBranchSelection}
                  options={branch}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              </div>

              {branchConversionRate > 0 ? (
                <>
                  <Row className="mt-3">
                    <Col md={6}>
                      <Label>
                        Enter {selectedFromCurrencyTransfer?.value || "Amount"}{" "}
                        Amount
                      </Label>
                      <Input
                        type="text"
                        value={fromCurrencyValueTransfer}
                        onChange={handleAmountChangeTransfer}
                        disabled={!selectedBranch}
                      />
                      {validationErrorFromCurrency && (
                        <div className="text-danger mt-2">
                          {validationErrorFromCurrency}
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3 text-end mt-2">
                    <Col lg={6} className="text-start">
                      <span>Wire Transfer Fee</span>
                    </Col>
                    <Col lg={6}>
                      <FontAwesomeIcon icon={faMinus} className="me-1" />
                      <span>
                        {wireTransferFeeValue || wireTransferFee}{" "}
                        {selectedFromCurrencyTransfer?.value}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3 text-end">
                    <Col lg={6} className="text-start">
                      <span>Commission Fee</span>
                    </Col>
                    <Col lg={6}>
                      <FontAwesomeIcon icon={faMinus} className="me-1" />
                      <span>
                        {commissionFeeValue || commissionCharges}{" "}
                        {selectedFromCurrencyTransfer?.value}{" "}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-3 text-end mt-2">
                    <Col lg={6} className="text-start">
                      <span>
                        {isAlternateCalculation
                          ? `Total ${selectedFromCurrencyTransfer?.value} Amount`
                          : "Total SGD"}
                      </span>
                    </Col>
                    <Col lg={6}>
                      <strong>
                        {isAlternateCalculation
                          ? `${convertedSGD || 0} ${
                              selectedFromCurrencyTransfer?.value
                            }  `
                          : `${totalSGD || 0} ${
                              selectedFromCurrencyTransfer?.value
                            }  `}
                      </strong>
                    </Col>
                  </Row>

                  <Row className="mb-3 text-end">
                    <Col lg={6} className="text-start">
                      <span>Transfer Rate</span>
                    </Col>
                    <Col lg={6}>
                      <span>
                        <FontAwesomeIcon icon={faXmark} className="me-1" />
                        {branchConversionRate}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3 text-end">
                    <Col lg={6} className="text-start">
                      <Label>
                        Enter {selectedToCurrencyTransfer?.value || "Amount"}{" "}
                        Amount
                      </Label>
                    </Col>
                    <Col lg={6}>
                      <Input
                        type="text"
                        value={convertedAmountTransfer}
                        onChange={handleConvertedAmountChange}
                        disabled={!selectedBranch}
                      />
                    </Col>
                    {validationErrorToCurrency && (
                      <div className="text-danger mt-2">
                        {validationErrorToCurrency}
                      </div>
                    )}
                  </Row>

                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      type="button"
                      color="danger"
                      onClick={() => setFromCurrencyValueTransfer("")}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </div>
                </>
              ) : (
                <h5 className="text-center">No Transfer rate available</h5>
              )}
            </Form>
          )}
        </Card>
      )}
    </>
  );
};

export default DashboardTransfer;
