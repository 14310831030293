import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Images
import client1 from "./../../../../../images/clients/1.png";
import client2 from "./../../../../../images/clients/2.png";
import client3 from "./../../../../../images/clients/3.png";
import client4 from "./../../../../../images/clients/4.png";
import client5 from "./../../../../../images/clients/5.png";
import client6 from "./../../../../../images/clients/6.png";

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper";
import "../../../../../../node_modules/swiper/swiper.scss";

const AboutUs = () => {

  return (
    <React.Fragment>
      <section className="section pt-4 bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h4>Who is Crescent Exchange?</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="12">
              <div className="text-muted mx-3">
                <p>
                  Crescent Exchange Pte. Ltd. was founded in 2019 as a money-changing company, providing convenient and reliable 
                  currency exchange services. Over the years, we have expanded our services to include remittance and online exchange services, 
                  catering to the growing needs of our customers.
                </p>
                <p className="mb-4">
                  At Crescent Exchange, we strive to provide secure, efficient, and cost-effective solutions for individuals and businesses alike. 
                  With our user-friendly platform, customers can easily exchange currencies, transfer funds internationally, and stay updated with 
                  the latest exchange rates.
                </p>

                <p className="mb-4">
                  Our team of dedicated professionals are committed to delivering exceptional service and maintaining the highest standards of 
                  integrity and security. We prioritize the trust and satisfaction of our customers, ensuring a seamless and transparent 
                  experience every step of the way.
                </p>

                <p className="mb-4">
                  Join Crescent Exchange today and experience the convenience and reliability of our comprehensive financial services.
                </p>

              </div>
            </Col>

          </Row>

        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
