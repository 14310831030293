import React from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Toast
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link, useNavigate } from "react-router-dom";

// import images
import profileImg from "../../../images/profile-img.png";
import logoImg from "../../../images/crescent_logo.png";
import withRouter from "../../components/Common/withRouter";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { postApiData } from "../../helpers/axiosHelper";

const ChangePassword = (props) => {
  //meta title
  document.title = "Crescent Exchange | ChangePassword";

  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required("Please Enter Your current Password")
        .matches(
          /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
          "Password must be at least 8 characters long and contain at least one digit, one uppercase letter, and one special character"
        ),

      newPassword: Yup.string()
        .required("Please Enter Your new Password")
        .matches(
          /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
          "Password must be at least 8 characters long and contain at least one digit, one uppercase letter, and one special character"
        ),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),

    onSubmit: async (values) => {
      //  delete values.password_confirmation

      const response = await postApiData(
        "api/ChangePassword/ChangeAgentPassword",
        JSON.stringify(values)
      );
      if (response.success === true) {
        toast.success("Password Change Successful", {
          position: "top-right",
          autoClose: 3000,

          onClose: () => {
            // Clear session storage items
            localStorage.clear();
            navigate(`/agentlogin`);
          },
        });
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/home" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Change Password</h5>
                        <p>Get your Crescent Exchange account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div id="changePage" style={{ display: "block" }}>
                        <div className="mb-3">
                          <Label className="form-label">Current Password</Label>
                          <RequiredAsterisk />
                          <Input
                            name="oldPassword"
                            type="password"
                            placeholder="Enter Current Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.oldPassword || ""}
                            invalid={
                              validation.touched.oldPassword &&
                              validation.errors.oldPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.oldPassword &&
                          validation.errors.oldPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.oldPassword}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <RequiredAsterisk />
                          <Input
                            name="newPassword"
                            type="password"
                            placeholder="Enter New Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.newPassword || ""}
                            invalid={
                              validation.touched.newPassword &&
                              validation.errors.newPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.newPassword &&
                          validation.errors.newPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.newPassword}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-2">
                          <Label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password{" "}
                          </Label>
                          <span className="text-danger">*</span>
                          <Input
                            name="password_confirmation"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.password_confirmation || ""
                            }
                            invalid={
                              validation.touched.password_confirmation &&
                              validation.errors.password_confirmation
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password_confirmation &&
                          validation.errors.password_confirmation ? (
                            <FormFeedback type="invalid">
                              <div>
                                {validation.errors.password_confirmation}
                              </div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ChangePassword);
