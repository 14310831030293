import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  NavItem,
  TabPane,
  CardBody,
  TabContent,
  Table,
  FormFeedback,
  NavLink,
} from "reactstrap";
import * as Yup from "yup";
import classnames from "classnames";
import ReactSelect from "react-select";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faDownload,
  faEye,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { toast } from "react-toastify";
import { SelectStyleForMenu } from "../../common/data/SelectStyleForMenu";
import {
  deleteApiData,
  getApiData,
  postApiData,
  putApiData,
} from "../../helpers/axiosHelper";
import { useLocation } from "react-router-dom";

const BeneficiaryModal = (props) => {
  const {
    isOpen,
    toggle,
    editMode,
    documentData,
    getDocument,
    fetchAllBeneficiary,
    sendBeneficiaryDetails,
    sendBeneficiaryAddress,
    createBenFor,
    currencies,
    getAvaBeneficiary,
  } = props;

  const [saving, setSaving] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [beneficiaryData, setBeneficiaryData] = useState(null);
  const [docFormat, setDocFormat] = useState("");
  const [fileData, setFileData] = useState(null);
  const [viewDocName, setViewDocName] = useState("");
  const [docIsValid, setDocIsValid] = useState(true);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [submitting, setSubmitting] = useState(false);
  const [viewDocument, setViewDocument] = useState("");
  const [nationalData, setNationalData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [completedTabs, setCompletedTabs] = useState([]);
  const [isAddingDoc, setIsAddingDoc] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState({
    beneficiary: false,
    bank: false,
    address: false,
  });
  const toggleViewModal = () => setModal1(!modal1);

  const { pathname } = useLocation();

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }
  const handleClose = () => {
    toggle();

    setactiveTab(1);
    setCompletedTabs([]);
    /*Noorul changes*/
    beneficiaryValidation.resetForm();
    bankValidation.resetForm();
    addressValidation.resetForm();
    documentValidation.resetForm();
  };

  const mapNationality = (nationality) => {
    if (!nationality) return [];
    return nationality.map((e) => ({
      label: e.nation,
      value: e.id,
    }));
  };

  function handleTabChange(e) {
    e.preventDefault();
    if (activeTab === 1) {
      //validate the branch details and toggle only if valid
      if (beneficiaryValidation.isValid) {
        toggleTab(activeTab + 1);
      } else {
        beneficiaryValidation.setTouched({
          benificaryName: true,
          nationalId: true,
          currencyId: true,
          status: true,
          mobile: true,
          email: true,
          // dob: true,
        });
      }
    } else if (activeTab === 2) {
      setSubmitting(true);
      beneficiaryValidation.handleSubmit();
    }
  }
  const handleSuccessfulSubmit = (tab, response) => {
    setCompletedTabs((prev) => [...new Set([...prev, tab])]);

    // Disable the form fields for the current tab on success
    setIsFormDisabled((prev) => ({
      ...prev,
      [tab === 1 ? "beneficiary" : tab === 2 ? "bank" : "address"]: true,
    }));
  };

  const toggleEnableTab = (tab) => {
    console.log(`Attempting to enable Tab ${tab}`);

    const isAccessible =
      tab === 1 ||
      editMode === 1 ||
      editMode === 2 ||
      completedTabs.includes(tab - 1);

    if (isAccessible) {
      toggleTab(tab);
    } else {
      console.warn(`Tab ${tab} is not accessible.`);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const currencyResponse = await getApiData(
        "api/RateMaster/GetAllCurrencies"
      );
      const currency = await currencyResponse.data;
      setCurrencyData(currency);

      const nationalResponse = await getApiData(
        "api/Address/GetAllNationality"
      );
      const national = await nationalResponse;
      setNationalData(mapNationality(national));

      setLoading(false);
    } catch (error) {
      toast.error("Error occurred while fetching data.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const accType = [
    { label: "personal", value: 1 },
    { label: "bussiness", value: 2 },
  ];

  const currencyIdToSend = createBenFor
    ? currencies
        .map((currency) => ({
          value: currency.currencyId,
          label: currency.currencyName,
          currencyCode: currency.currencyCode,
        }))
        .find(
          (option) =>
            option.currencyCode === createBenFor ||
            option.value === sendBeneficiaryDetails?.currencyId
        )?.value
    : sendBeneficiaryDetails?.currencyId;

  const ben = () => {
    if (pathname === "/agentdashboard") {
      return getAvaBeneficiary();
    }
    return fetchAllBeneficiary();
  };

  const beneficiaryValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      // ...(editMode === 2 && {
      //   companyBenId: sendBeneficiaryDetails?.companyBenId || null,
      //   companyId: sendBeneficiaryDetails?.companyId || null,
      // }),
      beneficiaryName: sendBeneficiaryDetails?.beneficiaryName || "",
      nationalId: sendBeneficiaryDetails?.nationalId || "",
      currencyId: currencyIdToSend || sendBeneficiaryDetails?.currencyId || "",
      // status: sendBeneficiaryDetails?.status || "",
      mobile: sendBeneficiaryDetails?.mobile || "",
      email: sendBeneficiaryDetails?.email || "",
      // dob: sendBeneficiaryDetails?.dob || "",
    },

    validationSchema: isDraft
      ? Yup.object({
          beneficiaryName: Yup.string().required(
            "Please enter the Beneficiary Name"
          ),
        })
      : Yup.object({
          beneficiaryName: Yup.string().required(
            "Please enter the Beneficiary Name"
          ),
          // dob: Yup.date().required("Please select the Date of Birth"),
          currencyId: Yup.string().required("Please select the Currency"),
          nationalId: Yup.string().required(
            "Please enter beneficiary National ID"
          ),
          email: Yup.string()
            .email("Invalid Email address.")
            .required("Please Enter Your Email"),
          mobile: Yup.string()
            .required("Please Enter Your Mobile Number")
            .matches(/^\+?\d{8,14}$/, "phone Number must be 8-14 digits"),
        }),

    onSubmit: async (values) => {
      setSaving(true);
      try {
        if (editMode === 3) {
          const apiEndpoint = isDraft
            ? "api/AgentBeneficiary/DraftBeneficiaryDetails"
            : "api/AgentBeneficiary/CreateBeneficiaryDetails";
          const response = await postApiData(apiEndpoint, values);

          if (response.success === true) {
            toast.success(response.message + " successfully.", {
              position: "top-right",
              autoClose: 3000,
            });
            setSaving(false);
            handleSuccessfulSubmit(1);
            //fetchAllBeneficiary();
            ben();
            toggleTab(activeTab + 1);
            setBeneficiaryData(response?.data);
          } else {
            toast.error("Error occurred while creating Beneficiary.", {
              position: "top-right",
              autoClose: 3000,
            });
            setSaving(false);
            toggle();
          }
        } else {
          const hasChanges = Object.keys(values).some(
            (key) => values[key] !== beneficiaryValidation.initialValues[key]
          );
          if (hasChanges) {
            const response = await putApiData(
              `api/AgentBeneficiary/UpdateBeneficiary?CompanyBenId=${sendBeneficiaryDetails?.companyBenId}`,
              values
            );
            if (response.success === true) {
              toast.success("Beneficiary detail's Updated successfully.", {
                position: "top-right",
                autoClose: 3000,
              });
              setSaving(false);
              ben();
              //fetchAllBeneficiary();
              toggleTab(activeTab + 1);
            } else {
              toast.error(
                "Error occurred while updating Beneficiary detail's.",
                {
                  position: "top-right",
                  autoClose: 300,
                }
              );
              setSaving(false);
              toggle();
            }
          } else {
            toast.info("No Changes to update.", {
              position: "top-right",
              autoClose: 3000,
            });
            setSaving(false);
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Error occurred while saving data.", {
          position: "top-right",
          autoClose: 3000,
        });
        setSaving(false);
        toggle();
      }
    },
  });

  const bankValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      // companyBenId: sendBeneficiaryDetails?.companyBenId,
      // companyId: sendBeneficiaryDetails?.companyId,
      swift: sendBeneficiaryDetails?.swift || "",
      other: sendBeneficiaryDetails?.other || "",
      accountNumber: sendBeneficiaryDetails?.accountNumber || "",
      bankAddress: sendBeneficiaryDetails?.bankAddress || "",
      bankName: sendBeneficiaryDetails?.bankName || "",
      accountType: sendBeneficiaryDetails?.accountType || "",
      // status: sendBeneficiaryDetails?.status || "",
    },

    validationSchema: Yup.object({
      bankName: Yup.string().required("Please enter the Bank Name"),
      swift: Yup.string().required("Please enter the SWIFT Code"),
      accountType: Yup.string().required("Please select the Account Type"),
      accountNumber: Yup.string().required("Please enter the Account Number"),
      bankAddress: Yup.string().required(
        "Please provide the beneficiary's bank address"
      ),
    }),

    onSubmit: async (values) => {
      setSaving(true);
      const custBenId =
        sendBeneficiaryDetails?.companyBenId || beneficiaryData?.companyBenId;

      try {
        const hasChanges = Object.keys(values).some(
          (key) => values[key] !== bankValidation.initialValues[key]
        );
        if (hasChanges) {
          const response = await putApiData(
            `api/AgentBeneficiary/UpdateBeneficiaryBank?CompanyBenId=${custBenId}`,
            values
          );
          if (response.success === true) {
            toast.success("Beneficary bank details updated successfully.", {
              position: "top-right",
              autoClose: 3000,
            });
            setSaving(false);
            //fetchAllBeneficiary();
            ben();
            handleSuccessfulSubmit(2);

            toggleTab(activeTab + 1);
          } else {
            toast.error("Error occurred while adding bank details.", {
              position: "top-right",
              autoClose: 3000,
            });
            setSaving(false);
            toggle();
          }
        } else {
          toast.info("No Changes to update.", {
            position: "top-right",
            autoClose: 3000,
          });
          setSaving(false);
        }
      } catch (error) {
        toast.error("Error occurred while saving data.", {
          position: "top-right",
          autoClose: 3000,
        });
        setSaving(false);
        toggle();
      }
    },
  });

  const addressValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      // addressId: sendBeneficiaryAddress?.addressId || "",
      street: sendBeneficiaryAddress?.street || "",
      city: sendBeneficiaryAddress?.city || "",
      state: sendBeneficiaryAddress?.state || "",
      country: sendBeneficiaryAddress?.country || "",
      postalcode: sendBeneficiaryAddress?.postalCode || "",
      nationality:
        nationalData?.find(
          (nation) =>
            nation.value === parseInt(sendBeneficiaryAddress?.nationality)
        ) || null,
    },

    validationSchema: Yup.object({
      street: Yup.string()
        .max(50, "Max character's reached.")
        .min(3, "Atleast 3 letter's required.")
        .required("Please Enter Your Street"),
      city: Yup.string()
        .max(50, "Max character's reached.")
        .min(1, "Atleast 2 letter's required.")
        .matches(/^[a-zA-Z\s]*$/, "Please enter only letters in the city field")
        .required("Please Enter Your City"),
      state: Yup.string()
        .max(50, "Max character's reached.")
        .min(2, "Atleast 2 letter's required.")
        .matches(
          /^[a-zA-Z\s]*$/,
          "Please enter only letters in the state field"
        )
        .required("Please Enter Your State"),
      country: Yup.string()
        .max(50, "Max character's reached.")
        .min(3, "Atleast 3 letter's required.")
        .matches(
          /^[a-zA-Z\s]*$/,
          "Please enter only letters in the state field"
        )
        .required("Please Enter Your Country"),
      postalcode: Yup.string()
        .max(10, "Postal code cannot more than 10 characters.")
        .min(4, "Postal code cannot be lower than 4 characters.")
        .required("Please enter your PIN code."),
      nationality: Yup.object()
        .shape({
          label: Yup.string().required("Please Select Nationality"),
          value: Yup.string().required("Please Select Nationality"),
        })
        .required("Please Select you're nationality."),
    }),

    onSubmit: async (values) => {
      setSaving(true);
      const nationalityValue = values.nationality && values.nationality.value;

      const combinedValues = {
        ...values,
        addressId: sendBeneficiaryAddress?.addressId,
        nationality: nationalityValue,
      };

      if (sendBeneficiaryAddress?.addressId) {
        const hasChanges = Object.keys(values).some(
          (key) => values[key] !== addressValidation.initialValues[key]
        );
        if (hasChanges) {
          handleAddressUpdate(combinedValues);
          //fetchAllBeneficiary();
          ben();
        } else {
          toast.info("No Changes to update.", {
            position: "top-right",
            autoClose: 3000,
          });
          setSaving(false);
        }
      } else {
        handleAddressCreate({ ...values, nationality: nationalityValue });
      }
    },
  });

  const handleAddressCreate = async (values) => {
    const companyBenId =
      sendBeneficiaryDetails?.companyBenId || beneficiaryData?.companyBenId;

    if (!companyBenId) {
      toast.error("Beneficiary not found. Cannot add address.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const response = await postApiData(
      `api/AgentBeneficiary/CreateBeneficiaryAddress?BeneficiaryId=${companyBenId}`,
      JSON.stringify(values)
    );

    if (response.success === true) {
      toast.success("Address Added Successfully", {
        position: "top-right",
        autoClose: 3000,
      });
      setSaving(false);
      handleSuccessfulSubmit(3);
      ben();
      toggleTab(activeTab + 1);
    } else {
      toast.error("Error Adding address. Contact Admin", {
        position: "top-right",
        autoClose: 3000,
      });
      setSaving(false);
      addressValidation.setValues(addressValidation.initialValues);
    }
  };

  const handleAddressUpdate = async (values) => {
    const updatedResponse = await putApiData(
      `api/Address/UpdateAddress?AddressID=${sendBeneficiaryAddress?.addressId}`,
      values
    );
    if (updatedResponse.success === true) {
      toast.success("Address Updated Successfully", {
        position: "top-right",
        autoClose: 3000,
        onClose: () => {
          setSaving(false);
        },
      });
      toggleTab(activeTab + 1);
      ben();
    } else {
      toast.error("Error saving Address info.", {
        position: "top-right",
        autoClose: 3000,
        onClose: () => {
          setSaving(false);
        },
      });
      addressValidation.setValues(addressValidation.initialValues);
    }
  };

  const documentValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      documentType: "",
      documentName: "",
      document: null,
      contentType: null,
    },

    validationSchema: Yup.object({
      documentType: Yup.string().required("Please Select the Document Type"),
      documentName: Yup.string().required("Please Enter the Document Name"),
      document: Yup.string().required("Please Upload the Document"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setIsAddingDoc(true);
      const companyBenId =
        sendBeneficiaryDetails?.companyBenId || beneficiaryData?.companyBenId;

      if (!companyBenId) {
        toast.error("Beneficiary isn't availabel. Cannot add Document.", {
          position: "top-right",
          autoClose: 3000,
        });
        resetForm();
        tog_standard();
        setIsAddingDoc(false);
        return;
      }

      //if (documentData.length <= 5) {
      if (companyBenId) {
        if (docIsValid) {
          const combinedValues = {
            ...values,
            document: fileData,
            documentFormat: docFormat,
          };
          const response = await postApiData(
            `api/AgentBeneficiary/AddBeneficiaryDocument?CompanyBenId=${companyBenId}`,
            combinedValues
          );
          if (response.success === true) {
            toast.success("Document Added Successfully", {
              position: "top-right",
              autoClose: 3000,
            });
            resetForm();
            tog_standard();
            getDocument(companyBenId);
            ben();
            // fetchAllBeneficiary();
            setIsAddingDoc(false);
          } else {
            toast.error("Error Uploading Doc. Contact Admin", {
              position: "top-right",
              autoClose: 3000,
            });
            setIsAddingDoc(false);
          }
        } else {
          toast.warning("File Size Should be less than 5MB", {
            position: "top-right",
            autoClose: 3000,
          });
          setIsAddingDoc(false);
        }
      } else {
        toast.info("Max Doc space has been reached.", {
          position: "top-right",
          autoClose: 3000,
        });
        setIsAddingDoc(false);
      }
      resetForm();
      tog_standard();
    },
  });

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function handleDocTypeChanged(e) {
    documentValidation.handleChange(e);
  }

  function getDocumentType(obj) {
    if (obj === "I") {
      return "Identity Proof";
    } else if (obj === "A") {
      return "Address Proof";
    } else if (obj === "O") {
      return "Others";
    } else {
      return "";
    }
  }

  function handleFileChange(e) {
    setDocIsValid(true);
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > 5000 * 1024) {
      toast.error("File Size Should be less than 5MB", {
        position: "top-right",
        autoClose: 3000,
      });
      documentValidation.resetForm();
      tog_standard();
      setDocIsValid(false);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const type = reader.result.split(";")[0];
      const docType = type.split("/")[1];
      let base64String = "";
      const indexOfComma = reader.result.indexOf(",");
      if (indexOfComma !== -1) {
        base64String = reader.result.substring(indexOfComma + 1);
      }
      setDocFormat(docType);
      setFileData(base64String);
    };
    reader.readAsDataURL(file);
  }

  const getFileType = (contentType) => {
    switch (contentType) {
      case "png":
        return "image/png";
      case "jpg":
        return "image/jpg";
      case "jpeg":
        return "image/jpeg";
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      default:
        return "application/octet-stream";
    }
  };

  const viewDoc = (index) => {
    const data = documentData[index].document;
    const name = documentData[index].documentName;
    const docFormat = documentData[index].documentFormat;

    setViewDocument(data);
    setViewDocName(name);

    const imageTypes = ["png", "jpg", "jpeg"];

    if (imageTypes.includes(docFormat)) {
      toggleViewModal();
    } else {
      handleDownloadFile(index);
    }
  };

  const handleDownloadFile = (index) => {
    const data = documentData[index];
    const byteChars = atob(data.document);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: getFileType(data.documentFormat),
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${data.documentName}.${
      data.contentType !== null ? data.documentFormat : "png"
    }`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const deleteDoc = async (index) => {
    const docId = documentData[index].docId;
    const response = await deleteApiData(
      `api/AgentBeneficiary/DeleteBenDoc?CompanyBenId=${sendBeneficiaryDetails?.companyBenId}&BenDocId=${docId}`
    );
    if (response.success === true) {
      toast.success("Document Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
      });
      getDocument(sendBeneficiaryDetails?.companyBenId);
    } else {
      toast.error("Error Deleting Document. Contact Admin", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#e9ecef" : "#fff",
      color: state.isSelected ? "#000" : "#333",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  const isDis = () => {
    if (editMode === 1 && beneficiaryData === null) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      disabled={saving}
      centered={true}
      onClosed={() => {
        setactiveTab(1);
        beneficiaryValidation.resetForm();
        bankValidation.resetForm();
        addressValidation.resetForm();
        documentValidation.resetForm();
      }}
      autoClose={true}
      size="lg"
    >
      <ModalHeader toggle={toggle}>
        {editMode === 1
          ? "View Beneficiary"
          : editMode === 2
          ? "Edit Beneficiary"
          : "Add Beneficiary"}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg="12">
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    style={{ cursor: "pointer" }}
                    className={classnames(
                      {
                        "bg-primary-subtle": activeTab === 1,
                        "bg-light": activeTab !== 1,
                      },
                      "p-1 me-1"
                    )}
                    onClick={() => toggleEnableTab(1)}
                  >
                    <span className="number">1.</span> Basic Detail's
                  </NavItem>

                  <NavItem
                    style={{
                      cursor:
                        editMode === 1 ||
                        editMode === 2 ||
                        completedTabs.includes(1)
                          ? "pointer"
                          : "not-allowed",
                    }}
                    className={classnames(
                      {
                        "bg-primary-subtle": activeTab === 2,
                        "bg-light": activeTab !== 2,
                      },
                      "p-1 me-1"
                    )}
                    onClick={() =>
                      (editMode === 1 ||
                        editMode === 2 ||
                        completedTabs.includes(1)) &&
                      toggleEnableTab(2)
                    }
                  >
                    <span className="number">2.</span> Bank Detail's
                  </NavItem>

                  <NavItem
                    style={{
                      cursor:
                        editMode === 1 ||
                        editMode === 2 ||
                        completedTabs.includes(2)
                          ? "pointer"
                          : "not-allowed",
                    }}
                    className={classnames(
                      {
                        "bg-primary-subtle": activeTab === 3,
                        "bg-light": activeTab !== 3,
                      },
                      "p-1 me-1"
                    )}
                    onClick={() =>
                      (editMode === 1 ||
                        editMode === 2 ||
                        completedTabs.includes(2)) &&
                      toggleEnableTab(3)
                    }
                  >
                    <span className="number">3.</span> Address
                  </NavItem>

                  <NavItem
                    style={{
                      cursor:
                        editMode === 1 ||
                        editMode === 2 ||
                        completedTabs.includes(3)
                          ? "pointer"
                          : "not-allowed",
                    }}
                    className={classnames(
                      {
                        "bg-primary-subtle": activeTab === 4,
                        "bg-light": activeTab !== 4,
                      },
                      "p-1 me-1"
                    )}
                    onClick={() =>
                      (editMode === 1 ||
                        editMode === 2 ||
                        completedTabs.includes(3)) &&
                      toggleEnableTab(4)
                    }
                  >
                    <span className="number">4.</span> Document List
                  </NavItem>
                </ul>
              </div>

              <div className="content clearfix">
                <TabContent activeTab={activeTab} className="body">
                  <TabPane tabId={1}>
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        beneficiaryValidation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col lg="6">
                          <Label for="beneficiaryName">Beneficiary Name</Label>
                          <RequiredAsterisk />
                          <Input
                            type="text"
                            id="beneficiaryName"
                            className="form-control"
                            placeholder="Beneficiary Name"
                            disabled={
                              editMode === 1
                                ? true
                                : editMode === 2
                                ? false
                                : isFormDisabled.beneficiary
                            }
                            onChange={beneficiaryValidation.handleChange}
                            onBlur={beneficiaryValidation.handleBlur}
                            value={
                              beneficiaryValidation.values.beneficiaryName || ""
                            }
                            invalid={
                              beneficiaryValidation.touched.beneficiaryName &&
                              beneficiaryValidation.errors.beneficiaryName
                                ? true
                                : false
                            }
                          />
                          {beneficiaryValidation.touched.beneficiaryName &&
                          beneficiaryValidation.errors.beneficiaryName ? (
                            <FormFeedback type="invalid">
                              {beneficiaryValidation.errors.beneficiaryName}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        {/* <Col lg="6">
                          <FormGroup>
                            <Label for="dob">Date of Birth</Label>
                            <RequiredAsterisk />
                            <Input
                              id="dob"
                              type="date"
                              className="form-control"
                              onChange={beneficiaryValidation.handleChange}
                              onBlur={beneficiaryValidation.handleBlur}
                              disabled={editMode === 1}
                              value={beneficiaryValidation.values.dob || ""}
                              invalid={
                                beneficiaryValidation.touched.dob &&
                                beneficiaryValidation.errors.dob
                                  ? true
                                  : false
                              }
                            />
                            {beneficiaryValidation.touched.dob &&
                            beneficiaryValidation.errors.dob ? (
                              <FormFeedback type="invalid">
                                {beneficiaryValidation.errors.dob}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col> */}
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="country">Contact Email</Label>
                            <RequiredAsterisk />
                            <Input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="user@example.com"
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.beneficiary
                              }
                              onChange={beneficiaryValidation.handleChange}
                              onBlur={beneficiaryValidation.handleBlur}
                              value={beneficiaryValidation.values.email || ""}
                              invalid={
                                beneficiaryValidation.touched.email &&
                                beneficiaryValidation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {beneficiaryValidation.touched.email &&
                            beneficiaryValidation.errors.email ? (
                              <FormFeedback type="invalid">
                                {beneficiaryValidation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="postalcode">Mobile</Label>
                            <RequiredAsterisk />
                            <Input
                              type="text"
                              className="form-control"
                              id="mobile"
                              placeholder="Enter Mobile"
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.beneficiary
                              }
                              onChange={beneficiaryValidation.handleChange}
                              onBlur={beneficiaryValidation.handleBlur}
                              value={beneficiaryValidation.values.mobile || ""}
                              invalid={
                                beneficiaryValidation.touched.mobile &&
                                beneficiaryValidation.errors.mobile
                                  ? true
                                  : false
                              }
                            />
                            {beneficiaryValidation.touched.mobile &&
                            beneficiaryValidation.errors.mobile ? (
                              <FormFeedback type="invalid">
                                {beneficiaryValidation.errors.mobile}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="nationalId">National ID</Label>
                            <RequiredAsterisk />
                            <Input
                              type="text"
                              id="nationalId"
                              className="form-control"
                              placeholder="National Id Number"
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.beneficiary
                              }
                              onChange={beneficiaryValidation.handleChange}
                              onBlur={beneficiaryValidation.handleBlur}
                              value={
                                beneficiaryValidation.values.nationalId || ""
                              }
                              invalid={
                                beneficiaryValidation.touched.nationalId &&
                                beneficiaryValidation.errors.nationalId
                                  ? true
                                  : false
                              }
                            />
                            {beneficiaryValidation.touched.nationalId &&
                            beneficiaryValidation.errors.nationalId ? (
                              <FormFeedback type="invalid">
                                {beneficiaryValidation.errors.nationalId}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <FormGroup>
                              <Label for="currencyId">Currency</Label>
                              <RequiredAsterisk />

                              <ReactSelect
                                id="currencyId"
                                placeholder="Select Currency"
                                styles={SelectStyleForMenu}
                                isDisabled={
                                  createBenFor || editMode === 1
                                    ? true
                                    : editMode === 2
                                    ? false
                                    : isFormDisabled.beneficiary
                                }
                                options={currencies?.map((currency) => ({
                                  value: currency.currencyId,
                                  label: currency.currencyName,
                                  currencyCode: currency.currencyCode,
                                }))}
                                value={
                                  createBenFor
                                    ? currencies
                                        ?.map((currency) => ({
                                          value: currency.currencyId,
                                          label: currency.currencyName,
                                          currencyCode: currency.currencyCode,
                                        }))
                                        .find(
                                          (option) =>
                                            option.currencyCode === createBenFor
                                        ) || null
                                    : currencies
                                        ?.map((currency) => ({
                                          value: currency.currencyId,
                                          label: currency.currencyName,
                                        }))
                                        .find(
                                          (option) =>
                                            option.value ===
                                            beneficiaryValidation.values
                                              .currencyId
                                        ) || null
                                }
                                onBlur={beneficiaryValidation.handleBlur}
                                onChange={(selectedOption) =>
                                  beneficiaryValidation.setFieldValue(
                                    "currencyId",
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                                className={
                                  beneficiaryValidation.touched.currencyId &&
                                  beneficiaryValidation.errors.currencyId
                                    ? "is-invalid"
                                    : ""
                                }
                              />
                            </FormGroup>

                            {beneficiaryValidation.touched.currencyId &&
                            beneficiaryValidation.errors.currencyId ? (
                              <FormFeedback type="invalid">
                                {beneficiaryValidation.errors.currencyId}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>

                  <TabPane tabId={2} className="mb-1">
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        bankValidation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row className="mt-0 mb-0">
                        <Col md={6}>
                          <FormGroup>
                            <Label for="accountType">Account Type</Label>
                            <Input
                              id="accountType"
                              name="accountType"
                              type="select"
                              value={bankValidation.values.accountType}
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.bank
                              }
                              onChange={bankValidation.handleChange}
                              onBlur={bankValidation.handleBlur}
                              invalid={
                                !!bankValidation.errors.accountType &&
                                bankValidation.touched.accountType
                              }
                            >
                              <option hidden={true} value="">
                                Select Account Type
                              </option>
                              {accType.map((index) => (
                                <option key={index.value} value={index.value}>
                                  {index.label}
                                </option>
                              ))}
                            </Input>
                            {bankValidation.errors.accountType &&
                              bankValidation.touched.accountType && (
                                <FormFeedback>
                                  {bankValidation.errors.accountType}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup>
                            <Label for="bankName">Bank Name</Label>
                            <Input
                              id="bankName"
                              name="bankName"
                              placeholder="Name of the Bank"
                              type="text"
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.bank
                              }
                              {...bankValidation.getFieldProps("bankName")}
                              invalid={
                                !!bankValidation.errors.bankName &&
                                bankValidation.touched.bankName
                              }
                            />
                            <FormFeedback>
                              {bankValidation.errors.bankName}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="accountNumber">Account Number</Label>
                            <Input
                              id="accountNumber"
                              name="accountNumber"
                              placeholder="Bank Account Number"
                              type="text"
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.bank
                              }
                              {...bankValidation.getFieldProps("accountNumber")}
                              invalid={
                                !!bankValidation.errors.accountNumber &&
                                bankValidation.touched.accountNumber
                              }
                            />
                            <FormFeedback>
                              {bankValidation.errors.accountNumber}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6} className="mb-0">
                          <FormGroup>
                            <Label for="swift">SWIFT Code</Label>
                            <Input
                              id="swift"
                              placeholder="Swift Number"
                              type="text"
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.bank
                              }
                              {...bankValidation.getFieldProps("swift")}
                              invalid={
                                !!bankValidation.errors.swift &&
                                bankValidation.touched.swift
                              }
                            />
                            <FormFeedback>
                              {bankValidation.errors.swift}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="other">Other Detail's</Label>
                            <Input
                              id="other"
                              type="text"
                              placeholder="Additional Info"
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.bank
                              }
                              {...bankValidation.getFieldProps("other")}
                              invalid={
                                !!bankValidation.errors.other &&
                                bankValidation.touched.other
                              }
                            />
                            <FormFeedback>
                              {bankValidation.errors.other}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <FormGroup>
                          <Label for="bankAddress">Bank Address</Label>
                          <textarea
                            rows="1"
                            id="bankAddress"
                            className={`form-control ${
                              bankValidation.touched.bankAddress &&
                              bankValidation.errors.bankAddress
                                ? "is-invalid"
                                : ""
                            }`}
                            disabled={
                              editMode === 1
                                ? true
                                : editMode === 2
                                ? false
                                : isFormDisabled.bank
                            }
                            placeholder="Write your Bank Address here..."
                            {...bankValidation.getFieldProps("bankAddress")}
                          />
                          {bankValidation.touched.bankAddress &&
                            bankValidation.errors.bankAddress && (
                              <FormFeedback type="invalid">
                                {bankValidation.errors.bankAddress}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Row>
                    </Form>
                  </TabPane>

                  <TabPane tabId={3}>
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => {
                        e.preventDefault();
                        addressValidation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="street">Street</Label>
                            <RequiredAsterisk />
                            <Input
                              type="text"
                              className="form-control"
                              id="street"
                              placeholder="Enter street"
                              onChange={addressValidation.handleChange}
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.address
                              }
                              onBlur={addressValidation.handleBlur}
                              value={addressValidation.values.street || ""}
                              invalid={
                                addressValidation.touched.street &&
                                addressValidation.errors.street
                                  ? true
                                  : false
                              }
                            />
                            {addressValidation.touched.street &&
                            addressValidation.errors.street ? (
                              <FormFeedback type="invalid">
                                {addressValidation.errors.street}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="city">City</Label>
                            <RequiredAsterisk />
                            <Input
                              type="text"
                              className="form-control"
                              id="city"
                              placeholder="Enter City"
                              onChange={addressValidation.handleChange}
                              onBlur={addressValidation.handleBlur}
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.address
                              }
                              value={addressValidation.values.city || ""}
                              invalid={
                                addressValidation.touched.city &&
                                addressValidation.errors.city
                                  ? true
                                  : false
                              }
                            />
                            {addressValidation.touched.city &&
                            addressValidation.errors.city ? (
                              <FormFeedback type="invalid">
                                {addressValidation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="state">State</Label>
                            <RequiredAsterisk />
                            <Input
                              type="text"
                              className="form-control"
                              id="state"
                              placeholder="Enter State"
                              onChange={addressValidation.handleChange}
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.address
                              }
                              onBlur={addressValidation.handleBlur}
                              value={addressValidation.values.state || ""}
                              invalid={
                                addressValidation.touched.state &&
                                addressValidation.errors.state
                                  ? true
                                  : false
                              }
                            />
                            {addressValidation.touched.state &&
                            addressValidation.errors.state ? (
                              <FormFeedback type="invalid">
                                {addressValidation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="country">Country</Label>
                            <RequiredAsterisk />
                            <Input
                              type="text"
                              className="form-control"
                              id="country"
                              placeholder="Enter Country"
                              onChange={addressValidation.handleChange}
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.address
                              }
                              onBlur={addressValidation.handleBlur}
                              value={addressValidation.values.country || ""}
                              invalid={
                                addressValidation.touched.country &&
                                addressValidation.errors.country
                                  ? true
                                  : false
                              }
                            />
                            {addressValidation.touched.country &&
                            addressValidation.errors.country ? (
                              <FormFeedback type="invalid">
                                {addressValidation.errors.country}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="postalcode">Postal Code</Label>
                            <RequiredAsterisk />
                            <Input
                              type="text"
                              className="form-control"
                              id="postalcode"
                              placeholder="Enter Postal Code"
                              onChange={addressValidation.handleChange}
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.address
                              }
                              onBlur={addressValidation.handleBlur}
                              value={addressValidation.values.postalcode || ""}
                              invalid={
                                addressValidation.touched.postalcode &&
                                addressValidation.errors.postalcode
                                  ? true
                                  : false
                              }
                            />
                            {addressValidation.touched.postalcode &&
                            addressValidation.errors.postalcode ? (
                              <FormFeedback type="invalid">
                                {addressValidation.errors.postalcode}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="mb-3">
                            <Label for="nationality">Nationality</Label>
                            <RequiredAsterisk />
                            <ReactSelect
                              name="nationality"
                              placeholder="Select Nationality"
                              type="text"
                              id="nationality"
                              styles={customStyles}
                              options={nationalData}
                              disabled={
                                editMode === 1
                                  ? true
                                  : editMode === 2
                                  ? false
                                  : isFormDisabled.address
                              }
                              value={
                                addressValidation.values.nationality || null
                              }
                              onChange={(selectedOption) =>
                                addressValidation.setFieldValue(
                                  "nationality",
                                  selectedOption
                                )
                              }
                              onBlur={addressValidation.handleBlur}
                              invalid={
                                addressValidation.touched.nationality &&
                                addressValidation.errors.nationality
                                  ? true
                                  : false
                              }
                            />
                            {addressValidation.errors.nationality &&
                            addressValidation.touched.nationality ? (
                              <span
                                className="text-danger"
                                style={{ "font-size": "80%" }}
                              >
                                {addressValidation.errors.nationality}
                              </span>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>

                  <TabPane tabId={4}>
                    <Col lg="12">
                      <div
                        className="table-responsive mb-2 mt-0"
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                      >
                        {documentData?.length > 0 ? (
                          <Table className="table table-striped table-bordered mt-0 mb-0">
                            <thead className="table-light mt-0 mb-0">
                              <tr>
                                <th>No</th>
                                <th>Document Type</th>
                                <th>Document Name</th>
                                <th>View Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              {documentData?.map((arr, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{getDocumentType(arr.documentType)}</td>
                                  <td>{arr.documentName}</td>
                                  <td>
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-sm btn-rounded me-2"
                                      onClick={() => viewDoc(index)}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </Button>
                                    {editMode === 2 && (
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="btn-sm btn-rounded"
                                        onClick={() => deleteDoc(index)}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <div
                            className="text-muted d-flex justify-content-center align-items-center"
                            style={{ minHeight: "100px" }}
                          >
                            <h3>No document to show</h3>
                          </div>
                        )}
                      </div>
                      {(editMode === 2 || editMode === 3) && (
                        <Button
                          className="btn btn-success"
                          data-toggle="modal"
                          data-target="#myModal"
                          disabled={documentData?.length >= 5}
                          onClick={() => {
                            tog_standard();
                          }}
                        >
                          {documentData?.length >= 5
                            ? "Max Limit"
                            : "+ Add Document"}
                        </Button>
                      )}
                    </Col>
                    {/* View Document Modal */}
                    <Modal
                      size="lg"
                      isOpen={modal1}
                      toggle={toggleViewModal}
                      className="modal-dialog-centered"
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="myModalLabel">
                          {viewDocName}
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setModal1(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <Row>
                        <Col>
                          <Card>
                            <CardBody style={{ padding: "0" }}>
                              <img
                                src={`data:image/png;base64,${viewDocument}`}
                                alt="Document"
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  maxHeight: "50vh",
                                }}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <ModalFooter>
                        <Button
                          type="button"
                          color="success"
                          className="btn-sm btn-rounded mb-2 me-1"
                          onClick={() =>
                            handleDownloadFile(
                              documentData?.findIndex(
                                (doc) => doc.documentName === viewDocName
                              )
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faDownload} /> Download
                        </Button>
                      </ModalFooter>
                    </Modal>

                    {/* Add Document Modal */}
                    <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Manage Documents
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            documentValidation.resetForm();
                            setmodal_standard(false);
                            return false;
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <Row className="mb-4">
                                <Col sm={6}>
                                  <div className="mt-3">
                                    <Label for="documentType">
                                      Document Type
                                    </Label>
                                    <div className="col-md-12">
                                      <Input
                                        className="form-select"
                                        type="select"
                                        id="documentType"
                                        name="documentType"
                                        onChange={handleDocTypeChanged}
                                        onBlur={documentValidation.handleBlur}
                                        value={
                                          documentValidation.values.documentType
                                        }
                                        invalid={
                                          documentValidation.touched
                                            .documentType &&
                                          documentValidation.errors.documentType
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="" disabled hidden>
                                          Choose Type
                                        </option>
                                        <option value="I">ID Proof</option>
                                        <option value="A">Address Proof</option>
                                        <option value="O">Others</option>
                                      </Input>
                                      {documentValidation.touched
                                        .documentType &&
                                      documentValidation.errors.documentType ? (
                                        <FormFeedback type="invalid">
                                          {
                                            documentValidation.errors
                                              .documentType
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <div className="mt-3">
                                    <Label for="documentName">
                                      Document Name
                                    </Label>
                                    <div className="col-md-12">
                                      <Input
                                        type="text"
                                        placeholder="Document Name"
                                        id="documentName"
                                        name="documentName"
                                        onChange={
                                          documentValidation.handleChange
                                        }
                                        onBlur={documentValidation.handleBlur}
                                        value={
                                          documentValidation.values.documentName
                                        }
                                        invalid={
                                          documentValidation.touched
                                            .documentName &&
                                          documentValidation.errors.documentName
                                            ? true
                                            : false
                                        }
                                      />
                                      {documentValidation.touched
                                        .documentName &&
                                      documentValidation.errors.documentName ? (
                                        <FormFeedback type="invalid">
                                          {
                                            documentValidation.errors
                                              .documentName
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12}>
                                  <div className="mt-1">
                                    <Label for="document">
                                      Select Document
                                    </Label>
                                    <div className="col-md-12">
                                      <Input
                                        type="file"
                                        id="document"
                                        aria-label="Upload"
                                        accept=".png, .jpg, .jpeg, .pdf"
                                        aria-describedby="inputGroupFileAddon04"
                                        onChange={(e) => {
                                          handleFileChange(e);
                                          documentValidation.handleChange(e);
                                        }}
                                        onBlur={documentValidation.handleBlur}
                                        invalid={
                                          documentValidation.touched.document &&
                                          documentValidation.errors.document
                                            ? true
                                            : false
                                        }
                                      />
                                      {documentValidation.touched.document &&
                                      documentValidation.errors.document ? (
                                        <FormFeedback type="invalid">
                                          {documentValidation.errors.document}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <div className="mt-0 mb-4 me-3 text-end">
                        <Button
                          className="btn btn-primary"
                          color="primary"
                          disabled={isAddingDoc}
                          onClick={documentValidation.handleSubmit}
                        >
                          {isAddingDoc ? "Submiting Document..." : "Submit"}
                        </Button>
                      </div>
                    </Modal>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="mt-0">
        {editMode === 3 && activeTab === 1 && (
          <div>
            <Button
              color="secondary"
              className="me-1"
              // disabled={saving}
              onClick={() => {
                setIsDraft(true);
                // beneficiaryValidation.setFieldValue("status", 0);
                beneficiaryValidation.handleSubmit();
              }}
            >
              Draft
            </Button>
          </div>
        )}

        <div>
          <Button
            color="secondary"
            className="me-1"
            disabled={
              activeTab === 1 || (saving && !(editMode === 1 || editMode === 2))
            }
            onClick={() => {
              console.log("Previous Tab");
              toggleTab(activeTab - 1);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="fa-lg" />
          </Button>

          <Button
            color="secondary"
            className="me-1"
            disabled={
              activeTab === 4 ||
              ((!completedTabs.includes(activeTab) || saving) &&
                !(editMode === 1 || editMode === 2))
            }
            onClick={() => {
              toggleTab(activeTab + 1);
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} className="fa-lg" />
          </Button>

          {editMode === 3 && activeTab === 1 && (
            <Button
              color="primary"
              disabled={saving || completedTabs.includes(activeTab) || saving}
              onClick={() => {
                beneficiaryValidation.setFieldValue("status", 1);
                beneficiaryValidation.handleSubmit();
              }}
            >
              {saving ? "Saving. . . ." : "Save"}
            </Button>
          )}

          {editMode === 3 && activeTab === 2 && (
            <Button
              color="primary"
              disabled={saving || completedTabs.includes(activeTab)}
              onClick={() => {
                bankValidation.setFieldValue("status", 2);
                bankValidation.handleSubmit();
              }}
            >
              {saving ? "Saving. . . ." : "Save"}
            </Button>
          )}

          {editMode === 3 && activeTab === 3 && (
            <Button
              color="primary"
              disabled={saving || completedTabs.includes(activeTab)}
              onClick={addressValidation.handleSubmit}
            >
              {saving ? "Saving. . . ." : "Save"}
            </Button>
          )}

          {editMode === 2 && activeTab === 1 && (
            <Button
              color="primary"
              disabled={saving}
              onClick={beneficiaryValidation.handleSubmit}
            >
              {saving ? "Updating. . . ." : "Update Beneficiary"}
            </Button>
          )}

          {editMode === 2 && activeTab === 2 && (
            <Button
              color="primary"
              disabled={saving}
              onClick={() => {
                // bankValidation.setFieldValue("status", 2);
                bankValidation.handleSubmit();
              }}
            >
              {saving ? "Updating. . . ." : "Update Bank"}
            </Button>
          )}

          {editMode === 2 && activeTab === 3 && (
            <Button
              color="primary"
              disabled={saving}
              onClick={addressValidation.handleSubmit}
            >
              {saving ? "Updating. . . ." : "Update Address"}
            </Button>
          )}

          {activeTab === 4 && (
            <Button color="danger" className="ms-2" onClick={handleClose}>
              Close
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

BeneficiaryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  sendBeneficiaryDetails: PropTypes.object,
};

export default BeneficiaryModal;
