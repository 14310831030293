import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { withTranslation } from "react-i18next";
import withRouter from "../Common/withRouter";
import { ToastContainer } from "react-toastify";
import SelectStyle from "../../common/data/SelectStyle";
import ReactSelect from "react-select";
import { BaseCurrencyContext } from "../../common/data/context";

import { getApiData } from "../../helpers/axiosHelper";

const SelectCurrency = () => {
  const [currencyIcons, setCurrencyIcons] = useState({});
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const { setCurrencyData, setSelectedContextCurrency } =
    useContext(BaseCurrencyContext);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const selectRef = useRef(null); // Reference to the ReactSelect container

  // This will store the tooltip position dynamically
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = () => {
    setIsHovered(true);

    if (selectRef.current) {
      // Get the bounding rectangle of the ReactSelect component
      const rect = selectRef.current.getBoundingClientRect();
      // Adjust the tooltip's position to appear just above the select box
      setTooltipPosition({
        top: rect.top - 40, // Show 40px above the select input
        left: rect.left + rect.width / 2, // Center horizontally above the select
      });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    const fetchCurrenciesForTransfer = async () => {
      try {
        const [currenciesResponse, branchResponse] = await Promise.all([
          getApiData("api/RateMaster/GetAllCurrencies"),
          getApiData("api/RateMaster/GetContextCurrencies"),
        ]);

        const icons = currenciesResponse.data.reduce((acc, currency) => {
          acc[
            currency.currencyCode.trim()
          ] = `data:image/png;base64,${currency.currencyFlag}`;
          return acc;
        }, {});

        const uniqueOptions = Array.from(
          new Map(
            branchResponse.data.map((currency) => [
              currency.currencyCode.trim(),
              {
                value: currency.currencyCode.trim(),
                label: currency.currencyName,
              },
            ])
          ).values()
        );

        setCurrencyIcons(icons);
        setCurrencyOptions(uniqueOptions);

        const sgdOption = uniqueOptions.find((opt) => opt.value === "SGD");
        setSelectedCurrency(sgdOption || uniqueOptions[0]);
        setSelectedContextCurrency(sgdOption || uniqueOptions[0]);
        setCurrencyData(sgdOption || uniqueOptions[0]);
      } catch (error) {
        console.error("Error fetching currencies", error);
      }
    };

    fetchCurrenciesForTransfer();
  }, [setCurrencyData]);

  const handleCurrencySelect = (option) => {
    if (option.value !== "SGD") {
      Swal.fire({
        text:
          "You are attemping to change Currency, all the conversions will now be based on " +
          `${option.value}` +
          " currency and you will be able to do the Transfer Operation only. Do you wish to proceed?",
        title: "warning",
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#556ee6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setSelectedCurrency(option);
          setSelectedContextCurrency(option);
          setCurrencyData(option);
        } else {
          return;
        }
      });
    } else {
      setSelectedCurrency(option);
      setSelectedContextCurrency(option);
      setCurrencyData(option);
    }
  };
  return (
    <>
      <ToastContainer />
      <div
        className="d-flex"
        style={{
          position: "relative", // Ensures the select input is the reference point for positioning
          display: "inline-block",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={selectRef} // Attach the reference to the ReactSelect container
      >
        {/* Custom Tooltip */}
        {isHovered && (
          <div
            style={{
              position: "absolute",
              top: tooltipPosition.top, // Dynamic position for the tooltip
              left: tooltipPosition.left,
              transform: "translateX(-50%)", // Center the tooltip horizontally
              backgroundColor: "#333",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "4px",
              fontSize: "12px",
              whiteSpace: "nowrap",
              zIndex: 9999, // Ensure it appears above the dropdown
              maxWidth: "200px", // Prevent the tooltip from being too wide
              textAlign: "center", // Center text inside the tooltip
            }}
          >
            Select your base currency here
          </div>
        )}

        {/* React Select */}
        <ReactSelect
          value={selectedCurrency}
          options={currencyOptions}
          onChange={handleCurrencySelect}
          styles={{
            ...SelectStyle,
            control: (provided) => ({
              ...provided,
              width: "220px", // Custom width for your select component
              position: "relative", // Ensure it's positioned relative to its parent
            }),
          }}
          formatOptionLabel={({ value, label }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={currencyIcons[value]}
                alt={label}
                style={{ width: "20px", marginRight: "8px" }}
              />
              {value}
            </div>
          )}
        />
      </div>
    </>
  );
};
SelectCurrency.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SelectCurrency));
