export const getCurrencyFlag = (allCurrencies, code) => {
  const flag = allCurrencies?.find(
    (x) => x.currencyCode === code
  )?.currencyFlag;
  return flag;
};

export const displayFlag = (flag) => {
  const flagUrl = `data:image/png;base64,${flag}`;

  return (
    <img
      src={flagUrl}
      alt=""
      onError={(e) => (e.target.src = "")}
      style={{ width: "20px" }}
      className="mx-1"
    />
  );
};

export const baseCurrency = "SGD";
export const displayDigits = 2;
export const roundOff = (value) => {
  if (!value) return;
  return parseFloat(value).toFixed(displayDigits);
};
