import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Collapse,
  Label,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { postApiData } from "../../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import { mapStatus } from "../../../common/data/statusLabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { formatDateToUserTimeZone } from "../../../common/date";

const ViewLatestTransactionModel = (props) => {
  const {
    isOpen,
    toggle,
    optime,
    branchData,
    commentData,
    sendTransactionDetails,
    reload,
    showAction,
    isBranchActive,
    beneficiaryDetails,
    chargesDetails,
  } = props;

  const [col_1, setCol_1] = useState(true);
  const [col_2, setCol_2] = useState(false);
  const [col_3, setCol_3] = useState(false);
  const [col_4, setCol_4] = useState(false);

  const [commentText, setCommentText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [isCommentOpen, setIsCommentOpen] = useState(false);

  const UserTypes = {
    1: "System User",
    2: "Customer",
    3: "Agent",
  };
  const BenAccountType = {
    1: "Personal",
    2: "Business",
  };

  const openCommentPopup = (status) => {
    setSelectedStatus(status);
    setIsCommentOpen(true);
  };

  const closeCommentPopup = () => {
    setIsCommentOpen(false);
    setCommentText("");
  };

  const handleCommentSubmit = async () => {
    const response = await postApiData("api/Transaction/AcknowledgeOrReject", {
      transactionId: sendTransactionDetails?.transactionId,
      comment: commentText,
      status: selectedStatus,
    });
    if (response.success === true) {
      toast.success("Comment submitted successfully.", {
        position: "top-right",
        autoClose: 3000,
      });
      toggle();
      closeCommentPopup();
      reload();
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 3000,
      });
      toggle();
      closeCommentPopup();
    }
  };
  useEffect(() => {
    if (sendTransactionDetails?.status === 2) {
      setCol_3(true);
      setCol_1(false); // Open the collapse if transaction status is 2
    }
  }, [sendTransactionDetails?.status]);

  const calculateDateDifference = () => {
    const approvedDate = new Date(sendTransactionDetails?.approvedDate);
    const currentDate = new Date();

    // Extract the date part only
    const approvedDateOnly = approvedDate.toISOString().split("T")[0];
    const currentDateOnly = currentDate.toISOString().split("T")[0];

    // Compare the dates
    const diffInDays = Math.floor(
      (currentDate - approvedDate) / (1000 * 60 * 60 * 24)
    );

    return approvedDateOnly === currentDateOnly ? 0 : diffInDays;
  };

  return (
    <>
      {/*Main Modal*/}
      <Modal isOpen={isOpen} toggle={toggle} centered={true} autoClose={true}>
        <ModalHeader toggle={toggle}>
          {sendTransactionDetails?.transactionType === 2
            ? "(BUY)"
            : sendTransactionDetails?.transactionType === 1
            ? "(SELL)"
            : sendTransactionDetails?.transactionType === 3
            ? "(TRANSFER)"
            : ""}{" "}
          Transaction ID - {sendTransactionDetails?.transactionId}
        </ModalHeader>

        <ModalBody style={{ maxHeight: "600px" }}>
          <div className="mb-2">
            <Button
              className="w-100 bg-white border-0 shadow-none  text-dark fs-6 text-start d-flex justify-content-between align-items-center"
              onClick={() => {
                setCol_1(!col_1);
                setCol_2(false);
                setCol_3(false);
                setCol_4(false);
              }}
            >
              <span>Currency</span>
              <i className="fa fa-chevron-down" />
            </Button>

            <Collapse isOpen={col_1}>
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Table className="table-bordered table-nowrap border border-2 mt-2">
                  <tbody>
                    {sendTransactionDetails &&
                      sendTransactionDetails !== null && (
                        <>
                          {/*console.log(sendTransactionDetails)*/}
                          <tr>
                            <th scope="row">From currency :</th>
                            <td>
                              {sendTransactionDetails?.fromCurrType} -{" "}
                              {parseFloat(
                                sendTransactionDetails?.fromCurrValue
                              ).toFixed(2)}
                            </td>
                          </tr>
                          {chargesDetails?.agentCharges > 0 && (
                            <tr>
                              <th scope="row">Agent Charges :</th>
                              <td>
                                {chargesDetails?.agentCharges > 0
                                  ? `${chargesDetails.agentCharges} SGD`
                                  : "No Charges"}
                              </td>
                            </tr>
                          )}
                          {chargesDetails?.wireTransferFee > 0 && (
                            <tr>
                              <th scope="row">WireTransfer Fee :</th>
                              <td>
                                {chargesDetails?.wireTransferFee > 0
                                  ? `${chargesDetails.wireTransferFee} SGD`
                                  : "No Charges"}
                              </td>
                            </tr>
                          )}
                          {chargesDetails?.commissionCharges > 0 && (
                            <tr>
                              <th scope="row">Commission Charges :</th>
                              <td>
                                {chargesDetails?.commissionCharges > 0
                                  ? `${chargesDetails.commissionCharges} SGD`
                                  : "No Charges"}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">To currency :</th>
                            <td>
                              {sendTransactionDetails?.toCurrType} -{" "}
                              {parseFloat(
                                sendTransactionDetails?.toCurrValue
                              ).toFixed(2)}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Conversion rate :</th>
                            <td>
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                <div>
                                  {sendTransactionDetails?.transactionType ===
                                  2 ? (
                                    <span>
                                      1 {sendTransactionDetails?.toCurrType} ={" "}
                                      {sendTransactionDetails?.exchangeRate}{" "}
                                      {sendTransactionDetails?.fromCurrType}
                                    </span>
                                  ) : sendTransactionDetails?.transactionType ===
                                    1 ? (
                                    <span>
                                      1 {sendTransactionDetails?.fromCurrType} ={" "}
                                      {sendTransactionDetails?.exchangeRate}{" "}
                                      {sendTransactionDetails?.toCurrType}
                                    </span>
                                  ) : (
                                    <span>
                                      {sendTransactionDetails?.exchangeRate}
                                    </span>
                                  )}
                                </div>
                              </Label>
                            </td>
                          </tr>
                        </>
                      )}
                  </tbody>
                </Table>
              </div>
            </Collapse>
          </div>

          <div className="mb-2">
            <Button
              className="w-100 bg-white border-0 shadow-none  text-dark fs-6 text-start d-flex justify-content-between align-items-center"
              onClick={() => {
                setCol_2(!col_2);
                setCol_1(false);
                setCol_3(false);
                setCol_4(false);
              }}
            >
              Branch <i className="fa fa-chevron-down " />
            </Button>

            <Collapse isOpen={col_2}>
              <div style={{ maxHeight: "225px", overflowY: "auto" }}>
                <Table
                  className="table-bordered table-nowrap border border-2 mt-2"
                  style={{ maxWidth: "200px" }}
                >
                  <tbody>
                    {branchData && branchData !== null && (
                      <tr>
                        <th>Branch :</th>
                        <td>{branchData.branchName}</td>
                      </tr>
                    )}
                  </tbody>

                  <tbody>
                    {sendTransactionDetails && (
                      <tr>
                        <th scope="row">Booked Date :</th>
                        <td>
                          {sendTransactionDetails?.transactionDate
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("-")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <Label>Working Hours:</Label>

                <Table
                  className="table-bordered table-nowrap border border-2"
                  style={{ maxWidth: "200px" }}
                >
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {optime && optime.length > 0 ? (
                      optime.map((operationalTime, index) => (
                        <tr key={index}>
                          <td>{operationalTime.day}</td>
                          <td>{operationalTime.startTime}</td>
                          <td>{operationalTime.endTime}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No operational time available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <Label>Branch Address:</Label>

                <Table
                  className="table-bordered table-nowrap border border-2"
                  style={{ maxWidth: "200px" }}
                >
                  <tbody>
                    {branchData && branchData !== null ? (
                      <>
                        <tr>
                          <th>Street :</th>
                          <td>{branchData.street}</td>
                        </tr>
                        <tr>
                          <th>City :</th>
                          <td>{branchData.city}</td>
                        </tr>
                        <tr>
                          <th>State :</th>
                          <td>{branchData.state}</td>
                        </tr>
                        <tr>
                          <th>Country :</th>
                          <td>{branchData.country}</td>
                        </tr>
                        <tr>
                          <th>Postal Code :</th>
                          <td>{branchData.postalCode}</td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <th className="text-center">No Address Added.</th>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Collapse>
          </div>
          {sendTransactionDetails?.transactionType === 3 && (
            <div className="mb-2">
              <Button
                className="w-100 bg-white border-0 shadow-none  text-dark fs-6 text-start d-flex justify-content-between align-items-center"
                onClick={() => {
                  setCol_4(!col_4);
                  setCol_2(false);
                  setCol_1(false);
                  setCol_3(false);
                }}
              >
                Beneficiary <i className="fa fa-chevron-down " />
              </Button>

              <Collapse isOpen={col_4}>
                <div style={{ maxHeight: "225px", overflowY: "auto" }}>
                  <Table className="table-bordered table-nowrap border border-2 mt-2">
                    <tbody>
                      {beneficiaryDetails && beneficiaryDetails !== null && (
                        <>
                          {/*console.log(sendTransactionDetails)*/}
                          <tr>
                            <th scope="row">Name :</th>
                            <td>{beneficiaryDetails[0].beneficiaryName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Currency :</th>
                            <td>{beneficiaryDetails[0].currencyName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Account Type</th>
                            <td>
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                {
                                  BenAccountType[
                                    beneficiaryDetails[0].accountType
                                  ]
                                }
                              </Label>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Account Number</th>
                            <td>
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                {beneficiaryDetails[0].accountNumber}
                              </Label>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Bank Name</th>
                            <td>
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                {beneficiaryDetails[0].bankName}
                              </Label>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
          )}

          <div className="mb-2">
            <Button
              className="w-100 bg-white border-0 shadow-none text-dark fs-6 text-start d-flex justify-content-between align-items-center"
              onClick={() => {
                setCol_3(!col_3);
                setCol_1(false);
                setCol_2(false);
                setCol_4(false);
              }}
            >
              Comment <i className="fa fa-chevron-down" />
            </Button>

            <Collapse isOpen={col_3}>
              <div>
                <div
                  className="mt-1"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  {commentData?.length > 0 ? (
                    commentData?.map((comment, index) => (
                      <div key={index}>
                        <div>
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faUser} className="me-2" />
                              <span>{UserTypes[comment?.userType]}</span>
                            </div>
                            <span
                              className="me-2"
                              style={{ fontSize: "0.6rem" }}
                            >
                              {" "}
                              {formatDateToUserTimeZone(comment?.createdOn)}
                              {/* {formatDate(comment.createdOn)} */}
                              {/* {comment.createdOn.split("T")} */}
                            </span>
                          </div>
                          <p>
                            Status:{" "}
                            <Badge
                              className={
                                "font-size-11 badge-soft-" +
                                mapStatus(comment?.transactionStatus).color
                              }
                            >
                              {mapStatus(comment?.transactionStatus).label}
                            </Badge>
                          </p>
                        </div>
                        <div>
                          <h6>
                            Comment: <b>{comment?.comments}</b>
                          </h6>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div
                      className="text-muted d-flex justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <h4>No comments to show</h4>
                    </div>
                  )}
                </div>
                {showAction === true &&
                  isBranchActive === true &&
                  sendTransactionDetails?.status < 13 && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-primary text-start mt-2"
                      onClick={() =>
                        openCommentPopup(sendTransactionDetails.status)
                      }
                    >
                      Comment
                    </Button>
                  )}

                {isBranchActive === false && (
                  <div className="text-muted d-flex justify-content-center align-items-center">
                    <h6 className="text-danger">
                      The transacting branch has been deactivated. Please
                      contact Admin for further details.
                    </h6>
                  </div>
                )}
              </div>
            </Collapse>
          </div>
        </ModalBody>
        {showAction === true && isBranchActive === true && (
          <ModalFooter>
            {sendTransactionDetails != null &&
              sendTransactionDetails.status === 2 && (
                <>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-primary"
                    onClick={() => openCommentPopup(8)}
                  >
                    Acknowledge
                  </Button>
                  <Button
                    type="button"
                    color="warning"
                    className="btn-primary"
                    onClick={() => openCommentPopup(3)}
                  >
                    Review
                  </Button>
                </>
              )}

            {sendTransactionDetails && sendTransactionDetails.status === 4 && (
              <Button
                type="button"
                color="danger"
                className="btn-primary"
                disabled={calculateDateDifference() === 0 ? false : true}
                onClick={() => openCommentPopup(9)}
              >
                Decline
              </Button>
            )}

            {sendTransactionDetails != null &&
              (sendTransactionDetails.status === 1 ||
                sendTransactionDetails.status === 2 ||
                sendTransactionDetails.status === 3 ||
                sendTransactionDetails.status === 8) && (
                <>
                  <Button
                    type="button"
                    color="danger"
                    className="btn-primary"
                    onClick={() => openCommentPopup(6)}
                  >
                    Decline
                  </Button>
                </>
              )}

            {sendTransactionDetails != null &&
              sendTransactionDetails.status === 10 && (
                <>
                  <Button
                    type="button"
                    color="danger"
                    className="btn-primary"
                    onClick={() => openCommentPopup(9)}
                  >
                    Decline
                  </Button>
                </>
              )}
          </ModalFooter>
        )}
      </Modal>

      {/*Comment Modal*/}
      <Modal isOpen={isCommentOpen} toggle={closeCommentPopup}>
        <ModalHeader toggle={closeCommentPopup}>Add Comment</ModalHeader>
        <ModalBody>
          <textarea
            className="form-control"
            rows="5"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            placeholder="Write your comment here..."
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCommentSubmit}>
            Submit
          </Button>
          <Button color="secondary" onClick={closeCommentPopup}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ViewLatestTransactionModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  sendTransactionDetails: PropTypes.object,
  commentData: PropTypes.array,
};

export default ViewLatestTransactionModel;
