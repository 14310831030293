import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BranchAddress, MainBranch, BranchName } from "./BranchCol";
import TableContainer from "../../components/Common/TableContainer";
import withRouter from "../../components/Common/withRouter";
import { useFormik } from "formik";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import axios from "axios";
import * as Yup from "yup";
import { getApiData, postApiData, putApiData } from "../../helpers/axiosHelper";
import OperationsData from "./OperationsData";
import classnames from "classnames";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faEye,
  faPenSquare,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useAuthUser } from "../../common/data/commonFunction";
import { isAgentBranchAdmin } from "../../common/data/commonFunction";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import Swal from "sweetalert2";
import Help from "../../components/Common/Help";
import config from "../../../config";

const Branch = (props) => {
  document.title = "Branch | Crescent Exchange";

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal_addBranch, setmodal_addBranch] = useState(false);
  const [branchData, setBranchData] = useState(null);
  const [viewBranchData, setViewBranchData] = useState([]);
  const [branchDetailsforTable, setBranchDetailsforTable] = useState([]);
  const [viewflag, setViewFlag] = useState(false);
  const [viewopData, setViewOpData] = useState([]);
  const [isMainBranch, setIsMainBranch] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isOPValid, setIsOPValid] = useState(false);
  const { isAgentSuperAdmin } = useAuthUser();
  const { branchId } = useLatestTransactions();

  //to save data from custom component
  const [operationTimeData, setOperationTimeData] = useState([]);
  const selectedOperationTimes = [];

  const onDataChange = (data) => {
    console.log(data);
    setOperationTimeData(data);
  };

  function tog_addBranch() {
    setmodal_addBranch(!modal_addBranch);
    removeBodyCss();
    setactiveTab(1);
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }
  function handleTabChange(e) {
    e.preventDefault();
    if (activeTab === 1) {
      //validate the branch details and toggle only if valid
      if (createvalidation.isValid) {
        toggleTab(activeTab + 1);
      } else {
        createvalidation.setTouched({
          branchName: true,
          street: true,
          city: true,
          state: true,
          country: true,
          postalCode: true,
        });
      }
    } else if (activeTab === 2) {
      setSubmitting(true);
      createvalidation.handleSubmit();
    }
  }

  function validateOPTime() {
    return operationTimeData
      .filter((td) => td.selected)
      .every((item) => {
        // if (item.selected) {
        if (item.startTime > "00:00" && item.endTime > item.startTime) {
          selectedOperationTimes.push({
            day: item.day,
            start:
              item.startTime.length < 8
                ? item.startTime + ":00"
                : item.startTime,
            end: item.endTime.length < 8 ? item.endTime + ":00" : item.endTime,
          });
          return true;
        } else return false;
      });
  }

  const createvalidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      branchId: viewBranchData?.branch?.branchId || null,
      branchName: viewBranchData?.branch?.branchName || "",
      isMainBranch: viewBranchData?.branch?.isMainBranch || false,
      street: viewBranchData?.branch?.street || "",
      city: viewBranchData?.branch?.city || "",
      state: viewBranchData?.branch?.state || "",
      country: viewBranchData?.branch?.country || "",
      postalCode: viewBranchData?.branch?.postalCode || "",
      operationalTime: viewBranchData?.operationalTime || [],
    },
    validationSchema: Yup.object({
      branchName: Yup.string().required("Please Enter Branch Name"),
      street: Yup.string().required("Please Enter Your Street"),
      city: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "City can contain only letters")
        .required("Please Enter Your City"),
      state: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "State can contain only letters")
        .required("Please Enter Your State"),
      country: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Country can contain only letters")
        .required("Please Enter Your Country"),
      postalCode: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .required("Please Enter Your Postal Code"),
    }),

    onSubmit: async (values, { resetForm }) => {
      // check if the operation time data has atleast one selection
      if (operationTimeData.some((td) => td.selected)) {
        // create an array of the selected operating days
        const validateOP = validateOPTime();
        if (validateOP) {
          values.isMainBranch = isMainBranch;
          values.operationalTime = selectedOperationTimes;
          try {
            // check for not a view mode
            if (!viewflag) {
              // Update if branchID is available
              if (values.branchId > 0 && viewBranchData.branch.branchId > 0) {
                //validate Tab1 for specific keys and update hasChanges
                const keysToCheck = [
                  "branchName",
                  "isMainBranch",
                  "street",
                  "city",
                  "state",
                  "country",
                  "postalCode",
                ];
                let hasChanges = false;
                let haveEqualSlots = false;
                for (const key of keysToCheck) {
                  if (values[key] !== createvalidation.initialValues[key]) {
                    hasChanges = true;
                    break; // Stop iterating if a change is found
                  }
                }
                //validate op time to see for any additions / removal
                if (
                  values.operationalTime.length !==
                  createvalidation.initialValues["operationalTime"].length
                ) {
                  hasChanges = true;
                }
                const combinedValues = {
                  ...values,
                  branchId: viewBranchData.branch.branchId,
                };
                //if Tab1 has no changes, validate Tab2 for changes
                if (!hasChanges) {
                  haveEqualSlots = arraysHaveEqualTimeSlots(
                    values.operationalTime,
                    createvalidation.initialValues.operationalTime
                  );
                }
                //update only if either BranchDetails or OperationalComp has changes
                if (hasChanges || !haveEqualSlots) {
                  const response = await putApiData(
                    "api/BranchMaster/UpdateBranch",
                    JSON.stringify(combinedValues)
                  );
                  tog_addBranch();
                  if (response.success === true) {
                    toast.success("Branch Successfully Updated", {
                      position: "top-right",
                      autoClose: 2000,
                    });
                    fetchBranchDetails();
                    setSubmitting(false);
                  } else {
                    toast.error(
                      "Error while updating Branch id  " + values.branchId,
                      {
                        position: "top-right",
                        autoClose: 2000,
                      }
                    );
                  }
                } else {
                  toast.error("No changes to update", {
                    position: "top-right",
                    autoClose: 2000,
                  });
                  tog_addBranch();
                }
              }
              //create branch if branchID is not available
              else {
                const response = await postApiData(
                  "api/BranchMaster/CreateBranch",
                  JSON.stringify(values)
                );
                tog_addBranch();
                if (response.success === true) {
                  toast.success("Branch Successfully Created", {
                    position: "top-right",
                    autoClose: 2000,
                  });
                  resetForm();
                  fetchBranchDetails();
                } else {
                  toast.error("Error while creating Branch", {
                    position: "top-right",
                    autoClose: 2000,
                  });
                }
              }
            }
          } catch (error) {
            toast.error("Error: " + error, {
              position: "top-right",
              autoClose: 3000,
            });
          } finally {
            setSubmitting(false);
          }
        } else {
          toast.error("Please select valid operating times", {
            position: "top-right",
            autoClose: 2000,
          });
          setSubmitting(false);
        }
      } else {
        toast.error("select atleast one operating day", {
          position: "top-right",
          autoClose: 1000,
        });
        setSubmitting(false);
      }
    },
  });

  function compareTimeSlots(slot1, slot2) {
    return (
      slot1.day === slot2.day &&
      slot1.start === slot2.startTime &&
      slot1.end === slot2.endTime
    );
  }
  function arraysHaveEqualTimeSlots(arr1, arr2) {
    for (const slot1 of arr1) {
      const hasMatch = arr2.some((slot2) => compareTimeSlots(slot1, slot2));
      if (!hasMatch) {
        return false;
      }
    }
    return true;
  }

  const fetchBranchDetails = async () => {
    try {
      console.log("Logged Agent branch ID " + branchId);
      setIsLoading(true);
      const response = await getApiData(`api/BranchMaster/GetAllAgentBranch`);
      setIsLoading(false);
      if (response.success === true) {
        const branchDetails = response.data.map((item, key) => ({
          index: key + 1,
          branchId: item.branchId,
          branchName: item.branchName,
          boolMainBranch: item.isMainBranch,
          branchAddress: `${item.street}, ${item.city},${item.state}, ${item.country}, ${item.postalCode}`,
          active: item.active,
        }));
        setBranchDetailsforTable(branchDetails);
        setBranchData(response.data);
      }
    } catch (error) {
      console.error("Error fetching Branch Details:", error);
    }
  };

  const viewBranchDetails = async (branchId) => {
    try {
      if (branchId != null) {
        const response = await getApiData(
          `api/BranchMaster/GetAgentBranch?branchId=${branchId}`
        );
        console.log(response);
        let branchData = response.data;
        setIsMainBranch(branchData.branch.isMainBranch || false);
        setViewBranchData(branchData);
        setViewOpData(branchData.operationalTime);
        console.log("API");
        console.log(viewopData);
        tog_addBranch();
      }
    } catch (error) {
      alert(error);
    }
  };

  // useEffect(() => {
  //   console.log("current role " + currentRole)
  // } , [])

  useEffect(() => {
    createvalidation.setValues({
      branchId: viewBranchData?.branch?.branchId || null,
      branchName: viewBranchData?.branch?.branchName || "",
      street: viewBranchData?.branch?.street || "",
      city: viewBranchData?.branch?.city || "",
      state: viewBranchData?.branch?.state || "",
      country: viewBranchData?.branch?.country || "",
      postalCode: viewBranchData?.branch?.postalCode || "",
      operationalTime: viewBranchData?.operationalTime || [],
      isMainBranch: isMainBranch,
    });
  }, [viewBranchData]);

  //deleting a branch with branchID
  const softDeleteBranchData = async (branchId, isActive) => {
    try {
      if (branchId != null) {
        const response = await putApiData(
          `api/BranchMaster/Act_DeaBranch?branchId=${branchId}&isActive=${isActive}`
        );
        // console.log(response);
        if (response) {
          if (response === true && isActive === false) {
            toast.success("Branch Successfully deactivated", {
              position: "top-right",
              autoClose: 2000,
            });
            fetchBranchDetails();
          } else if (response === true && isActive === true) {
            toast.success("Branch Successfully activated", {
              position: "top-right",
              autoClose: 2000,
            });
            fetchBranchDetails();
          } else {
            toast.error("Error , Contact Admin", {
              position: "top-right",
              autoClose: 3000,
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBranchDetails();
  }, []);

  const ViewButton = ({ branchId }) => (
    <Button
      type="button"
      color="primary"
      className="btn-sm btn-rounded"
      style={{ marginRight: "5px", marginBottom: "5px" }}
      onClick={() => {
        setViewFlag(true);
        viewBranchDetails(branchId);
      }}
    >
      <FontAwesomeIcon icon={faEye} />
    </Button>
  );

  const EditButton = ({ branchId }) => (
    <Button
      type="button"
      color="success"
      className="btn-sm btn-rounded"
      style={{ marginRight: "5px", marginBottom: "5px" }}
      onClick={() => {
        setViewFlag(false);
        viewBranchDetails(branchId);
      }}
    >
      <FontAwesomeIcon icon={faPenSquare} />
    </Button>
  );

  const ActivateButton = ({ branchId }) => (
    <Button
      type="button"
      color="success"
      className="btn-sm btn-rounded me-2 ms-3"
      onClick={(e) => {
        e.preventDefault();
        softDeleteBranchData(branchId, true);
      }}
    >
      Activate <FontAwesomeIcon className="ms-2" icon={faCheck} />
    </Button>
  );

  const DeleteButton = ({ branchId }) => (
    <Button
      type="button"
      color="danger"
      className="btn-sm btn-rounded"
      style={{ marginRight: "5px", marginBottom: "5px" }}
      onClick={() => {
        Swal.fire({
          text: "Are you sure you want to delete this branch? The transactions and the staff under this branch will not be listed anymore.Would you like to proceed?",
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#556ee6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            softDeleteBranchData(branchId, false);
          }
        });
      }}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  );

  //grid to load all the branches -getAllBranch
  const columns = useMemo(
    () => [
      {
        Header: "Branch Name",
        accessor: "branchName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { branchName, boolMainBranch } = cellProps.row.original;
          return (
            <div>
              {branchName}
              {boolMainBranch === true && (
                <span className="badge bg-success ms-2">Main</span>
              )}
            </div>
          );
        },
        // Cell: (cellProps) => {
        //   return <BranchName {...cellProps} />;
      },

      {
        Header: "Branch Address",
        accessor: "branchAddress",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <BranchAddress {...cellProps} />;
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => {
          const branchId = cellProps.row.original.branchId;
          const active = cellProps.row.original.active;
          return (
            <>
              {isAgentSuperAdmin && active === 0 ? (
                <ActivateButton branchId={branchId} />
              ) : (
                <>
                  <ViewButton branchId={branchId} />
                  <EditButton branchId={branchId} />
                  {isAgentSuperAdmin && <DeleteButton branchId={branchId} />}
                </>
              )}
            </>
          );
        },
      },
    ],
    [isAgentSuperAdmin]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ToastContainer closeButton={false} limit={1} />
          {/*Mangai code start for add branch*/}
          <Modal
            size="lg"
            isOpen={modal_addBranch}
            toggle={() => {
              tog_addBranch();
            }}
            name="Add Branch"
            onClosed={() => createvalidation.resetForm()}
          >
            <div className="modal-header d-flex justify-content-between">
              <h4 className="modal-title" id="myModalLabel">
                Branch Details{" "}
              </h4>
              {/* <Help to={"Help_Branch.pdf"}></Help> */}
            </div>
            {/* <div className="d-flex align-items-center"> */}
            <button
              type="button"
              onClick={() => {
                setmodal_addBranch(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {/* </div> */}

            <div className="modal-body">
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({
                                current: activeTab === 1,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                                onClick={() => {
                                  setactiveTab(1);
                                }}
                                disabled={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">1.</span> Branch
                                Details
                              </NavLink>
                            </NavItem>

                            <NavItem
                              className={classnames({
                                current: activeTab === 2,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 2,
                                })}
                                onClick={(e) => {
                                  handleTabChange(e);
                                  // setactiveTab(2)
                                }}
                                //disabled={!(passedSteps || []).includes(2)}
                              >
                                <span className="number">2.</span> Operational
                                Time
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix">
                          <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                              <Form
                                className="needs-validation"
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  createvalidation.handleSubmit(e);
                                  return false;
                                }}
                              >
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="branchName">
                                        Branch Name
                                      </Label>
                                      <RequiredAsterisk />
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="branchName"
                                        placeholder="Enter Branch Name"
                                        disabled={viewflag}
                                        onChange={createvalidation.handleChange}
                                        onBlur={createvalidation.handleBlur}
                                        value={
                                          createvalidation.values.branchName ||
                                          ""
                                        }
                                        invalid={
                                          createvalidation.touched.branchName &&
                                          createvalidation.errors.branchName
                                            ? true
                                            : false
                                        }
                                      />
                                      {createvalidation.touched.branchName &&
                                      createvalidation.errors.branchName ? (
                                        <FormFeedback type="invalid">
                                          {createvalidation.errors.branchName}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      {/* <FormGroup check style={{ display: 'flex', alignItems: 'left' }}> */}
                                      <Label
                                        check
                                        style={{
                                          marginLeft: "10px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        {" "}
                                        {/* Move label to right */}
                                        <Input
                                          type="checkbox"
                                          id="isMainBranch"
                                          name="isMainBranch"
                                          disabled={viewflag}
                                          onChange={(e) => {
                                            setIsMainBranch(!isMainBranch);
                                          }}
                                          //value={createvalidation.values.isMainBranch}
                                          defaultChecked={isMainBranch}
                                        />
                                        Main Branch
                                      </Label>
                                      {/* </FormGroup> */}
                                      {createvalidation.touched.isMainBranch &&
                                      createvalidation.errors.isMainBranch ? (
                                        <FormFeedback type="invalid">
                                          {createvalidation.errors.isMainBranch}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="street">Street</Label>
                                      <RequiredAsterisk />
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="street"
                                        placeholder="Enter street"
                                        disabled={viewflag}
                                        onChange={createvalidation.handleChange}
                                        onBlur={createvalidation.handleBlur}
                                        value={
                                          createvalidation.values.street || ""
                                        }
                                        invalid={
                                          createvalidation.touched.street &&
                                          createvalidation.errors.street
                                            ? true
                                            : false
                                        }
                                      />
                                      {createvalidation.touched.street &&
                                      createvalidation.errors.street ? (
                                        <FormFeedback type="invalid">
                                          {createvalidation.errors.street}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="city">City</Label>
                                      <RequiredAsterisk />
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        placeholder="Enter City"
                                        disabled={viewflag}
                                        onChange={createvalidation.handleChange}
                                        onBlur={createvalidation.handleBlur}
                                        value={
                                          createvalidation.values.city || ""
                                        }
                                        invalid={
                                          createvalidation.touched.city &&
                                          createvalidation.errors.city
                                            ? true
                                            : false
                                        }
                                      />
                                      {createvalidation.touched.city &&
                                      createvalidation.errors.city ? (
                                        <FormFeedback type="invalid">
                                          {createvalidation.errors.city}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="state">State</Label>
                                      <RequiredAsterisk />
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="state"
                                        placeholder="Enter State"
                                        disabled={viewflag}
                                        onChange={createvalidation.handleChange}
                                        onBlur={createvalidation.handleBlur}
                                        value={
                                          createvalidation.values.state || ""
                                        }
                                        invalid={
                                          createvalidation.touched.state &&
                                          createvalidation.errors.state
                                            ? true
                                            : false
                                        }
                                      />
                                      {createvalidation.touched.state &&
                                      createvalidation.errors.state ? (
                                        <FormFeedback type="invalid">
                                          {createvalidation.errors.state}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="country">Country</Label>
                                      <RequiredAsterisk />
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="country"
                                        placeholder="Enter Country"
                                        disabled={viewflag}
                                        onChange={createvalidation.handleChange}
                                        onBlur={createvalidation.handleBlur}
                                        value={
                                          createvalidation.values.country || ""
                                        }
                                        invalid={
                                          createvalidation.touched.country &&
                                          createvalidation.errors.country
                                            ? true
                                            : false
                                        }
                                      />
                                      {createvalidation.touched.country &&
                                      createvalidation.errors.country ? (
                                        <FormFeedback type="invalid">
                                          {createvalidation.errors.country}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="postalCode">
                                        Postal Code
                                      </Label>
                                      <RequiredAsterisk />
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="postalCode"
                                        disabled={viewflag}
                                        placeholder="Enter Postal Code"
                                        onChange={createvalidation.handleChange}
                                        onBlur={createvalidation.handleBlur}
                                        value={
                                          createvalidation.values.postalCode ||
                                          ""
                                        }
                                        invalid={
                                          createvalidation.touched.postalCode &&
                                          createvalidation.errors.postalCode
                                            ? true
                                            : false
                                        }
                                      />
                                      {createvalidation.touched.postalCode &&
                                      createvalidation.errors.postalCode ? (
                                        <FormFeedback type="invalid">
                                          {createvalidation.errors.postalCode}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </TabPane>
                            <TabPane tabId={2}>
                              <Form
                                className="needs-validation"
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  createvalidation.handleSubmit(e);
                                  return false;
                                }}
                              />
                              <div>
                                <OperationsData
                                  onChange={createvalidation.handleChange}
                                  onBlur={createvalidation.handleBlur}
                                  value={
                                    createvalidation.values.operationalTime ||
                                    ""
                                  }
                                  viewflag={viewflag}
                                  viewopData={viewopData}
                                  onDataChange={onDataChange}
                                  // ref={childRef}
                                ></OperationsData>
                              </div>
                              {/* </Form> */}
                            </TabPane>
                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li className="closeforview">
                              <Link
                                to="#"
                                hidden={!viewflag}
                                onClick={() => {
                                  setmodal_addBranch(false);
                                }}
                              >
                                Close
                              </Link>
                            </li>

                            {/* Previous button */}
                            <li
                              className={
                                activeTab === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => toggleTab(activeTab - 1)}
                                disabled={activeTab === 1}
                                hidden={viewflag}
                              >
                                Previous
                              </Link>
                            </li>

                            {/* Next/Save button (conditional rendering) */}
                            {activeTab < 2 && (
                              <li className="next">
                                <Link
                                  to="#"
                                  onClick={handleTabChange}
                                  hidden={viewflag}
                                >
                                  Save & Next
                                </Link>
                              </li>
                            )}
                            {activeTab === 2 && (
                              <li className="next">
                                <Button
                                  color="primary"
                                  onClick={handleTabChange}
                                  hidden={viewflag}
                                  disabled={submitting}
                                >
                                  Save
                                </Button>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Modal>
          {/*end of add branch-Mangai*/}

          <Card>
            <CardBody>
              <div className="mb-4 card-title d-flex justify-content-between">
                <h5>Branch Details</h5>
                <Help to={"Help_Branch.pdf"} />
              </div>
              {isAgentSuperAdmin ? (
                <div className="text-sm-end">
                  <button
                    type="button"
                    onClick={() => {
                      setIsMainBranch(false);
                      setViewFlag(false);
                      setViewBranchData({});
                      setViewOpData([]);
                      tog_addBranch();
                    }}
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    Add New Branch
                  </button>
                </div>
              ) : null}
              {isLoading ? (
                <h6>Loading...</h6>
              ) : (
                <TableContainer
                  columns={columns}
                  data={branchDetailsforTable}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  customPageSize={10}
                  isPageSelect={false}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Branch);
